import React from "react";
import './event-list.scss';
import { getImageSource } from "../../utility/image-source";
import { ImageConstants } from "../../constants/constants";
import Path from "../routes/routes-path";
import SharedProfile from "../../../shared-storage/profile";
import SharedStaticText from "../../../shared-storage/static-text-store";
import EventApi from "../../apis/event";
import Loading from "../common/loading/loading";
import OneStepEvent from "./one-step-event/one-step-event";
import EventHelp from "./event-help/event-help";
import ScholarshipEventKnowMore from "./event-know-more/event-know-more";
import PaidEventPayScreen from "./paid-event-pay-screen/paid-event-pay-screen";
import PaidEventLandingScreen from "./paid-event-landing-screen/paid-event-landing-screen";
import ScollarShipStartScreen from "./one-step-event-start-screen/one-step-event-start-screen";
import AssessmentEngine from "../common/assessment-engine/assessment-engine";
import ScholarshipFeedback from "./scholarship-feedback/scholarship-feedback";
import OneStepEventAssessmentEndScreen from './one-step-event-assessment-end-screen/one-step-event-assessment-end-screen';
import MyAssessmentResult from "../my-assessment-result/my-assessment-result";
import LayoutShared from "../common/layout-shared/layout-shared";

class EventList extends React.Component {

    constructor(props) {
        super(props);
        this.userInfo = SharedProfile.getProfile()

        this.staticText = SharedStaticText.getStaticText('eventListScreen') || {}
        this.layoutShared = LayoutShared.getLayout()
    }

    componentDidMount() {
        // this.addEventListener('didFocus', (data) => {
        this.getEvents()
        // this.landingScreenInstance = LandingScreenShared.getLandingScreenInstance()

        // if (this.landingScreenInstance && this.landingScreenInstance.state && this.landingScreenInstance.state.showBlur) {
        //     this.setState({ showBlur: true })
        // } else {
        //     this.setState({ showBlur: false })
        // }
        // })
        this.layoutShared.toggleSidebar(true)
    }

    state = {
        events: [],
        showBlur: false,
        loading: true,
        showKnowMoreScreens: false,
        showOneStepEventAssessmentEndScreen: false,
        // tabIndex : 2
    }

    getEvents = () => {
        let payload = {}
        this.setState({ loading: true })
        EventApi.getMultipleTwoStepEventStatus(payload).then((events) => {

            //console.log(events)
            this.setState({ events, loading: false })
        }).catch(err => {
            if (err) {
                this.setState({ loading: false })
                return;
            }
        })
    }

    onPressCard = (course) => {
        this.props.navigation.navigate("CoursesDetails", { meta: { course } })
    }

    showHelp = (helpContent) => {
        this.setState({ showHelp: true, helpContent: helpContent.help })
    }

    backFromHelp = () => {
        this.setState({ showHelp: false })
    }

    showKnowMoreScreens = (knowMoreContent) => {
        // //console.log(knowMoreContent.meta)
        this.setState({ showKnowMoreScreens: true, knowMore: knowMoreContent.meta })
    }

    hideKnowMoreScreens() {
        this.setState({ showKnowMoreScreens: false })
    }

    showPaidEventPayScreen(event) {
        this.hideKnowMoreScreens()
        this.setState({ showPaidEventPayScreen: true, eventInfo: event.event })
    }

    hidePaidEventPayScreen() {

        if (this.state.showPaidEventLandingScreen) {
            this.hidePaidEventLandingScreen()
        }

        this.setState({ showPaidEventPayScreen: false })
    }

    showPaidEventLandingScreen(event) {
        // //console.log(event)
        this.setState({ showPaidEventLandingScreen: true, paidEventLandingScreenInfo: event.event })
    }

    hidePaidEventLandingScreen() {
        this.setState({ showPaidEventLandingScreen: false })
    }

    showAssessmentStartScreen(assessmentStartInfo) {
        // //console.log(assessmentStartInfo)
        this.setState({ showAssessmentStartScreen: true, assessmentStartInfo: assessmentStartInfo.meta })
    }

    hideAssessmentStartScreen() {
        this.setState({ showAssessmentStartScreen: false })
    }

    showAssessmentEngine(assessmentInfo) {
        // //console.log(assessmentInfo)
        // this.hideAssessmentStartScreen()
        this.layoutShared.toggleSidebar(true, this.props.history.location.pathname)
        this.setState({ showAssessmentStartScreen: false }, () => {
            this.setState({ showAssessmentEngine: true, assessmentMeta: assessmentInfo.meta })
        })
    }

    showScholarshipFeedback(feedbackInfo) {
        //console.log(feedbackInfo)
        this.setState({ showScholarshipFeedback: true, showAssessmentEngine: false, feedbackMeta: feedbackInfo.meta })
    }

    hideScholarshipFeedback() {
        this.setState({ showScholarshipFeedback: false })
    }

    showOneStepEventAssessmentEndScreen(assessmentEndScreenInfo) {
        //console.log(assessmentEndScreenInfo)
        this.hideScholarshipFeedback()
        this.setState({ showOneStepEventAssessmentEndScreen: true, assessmentEndScreenInfo: assessmentEndScreenInfo.meta })
    }

    hideAssessmentEndScreen() {
        this.setState({ showOneStepEventAssessmentEndScreen: false })
        this.getEvents()
    }

    showViewAnswersScreen(viewAnswerScreenInfo) {
        this.hideAssessmentEndScreen()
        this.setState({ showViewAnswersScreen: true, viewAnswerScreenInfo: viewAnswerScreenInfo.meta })
    }

    hideViewAnswersScreen(value) {
        if (value) {
            this.setState({ showViewAnswersScreen: false, showOneStepEventAssessmentEndScreen: value })
        } else {
            this.setState({ showViewAnswersScreen: false, showOneStepEventAssessmentEndScreen: value })
            this.getEvents()
        }
    }




    renderEvents = () => {

        if (!this.state.events.length) {
            return (
                <div className="mt-5">
                    <div className="text-center">
                        <img className="no-event-found-image" src={getImageSource(ImageConstants.NO_CLASSWORK_IMAGE)}></img>
                    </div>
                    <div className="no-event-text text-center">{this.staticText.noResultFoundText ? this.staticText.noResultFoundText : "No Events found"}</div>
                </div>
            )
        }


        let events = []
        for (let i = 0; i < this.state.events.length; i++) {
            if (this.state.events[i].isSingleStepEvent)
                events.push(
                    <div className="event mt-4">
                        <OneStepEvent
                            event={this.state.events[i]} index={i}
                            showHelp={(helpContent) => this.showHelp(helpContent)}
                            delegate={this} ></OneStepEvent>
                    </div>
                )
            // else
            //     events.push(<TwoStepEvent navigation={this.props.navigation} event={this.state.events[i]} index={i}></TwoStepEvent>)
        }

        return events
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }






    goToHomeScreen() {
        return this.props.history.replace({ pathname: Path.HOME })
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 pl-2'>{`Events`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }





    render() {


        if (this.state.showKnowMoreScreens) {
            return (
                <ScholarshipEventKnowMore delegate={this} knowMore={this.state.knowMore} />
            )
        }

        if (this.state.showPaidEventPayScreen) {
            return (
                <PaidEventPayScreen delegate={this} event={this.state.eventInfo} />
            )
        }

        if (this.state.showPaidEventLandingScreen) {
            return (
                <PaidEventLandingScreen event={this.state.paidEventLandingScreenInfo} delegate={this} />
            )
        }

        if (this.state.showAssessmentStartScreen) {
            return (
                <ScollarShipStartScreen delegate={this} assessmentStartInfo={this.state.assessmentStartInfo} />
            )
        }

        if (this.state.showAssessmentEngine) {
            return (<AssessmentEngine meta={this.state.assessmentMeta} delegate={this} />)
        }

        if (this.state.showScholarshipFeedback) {
            return (
                <ScholarshipFeedback feedbackMeta={this.state.feedbackMeta} delegate={this} />
            )
        }

        if (this.state.showOneStepEventAssessmentEndScreen) {
            return (
                <OneStepEventAssessmentEndScreen meta={this.state.assessmentEndScreenInfo} delegate={this} />
            )
        }

        if (this.state.showViewAnswersScreen) {
            return (
                <MyAssessmentResult meta={this.state.viewAnswerScreenInfo} delegate={this} />
            )
        }

        if (this.state.showHelp) {
            return (
                <EventHelp delegate = {this} helpContent={this.state.helpContent} back={this.backFromHelp} />
            )
        }




        return (
            <div className="event-list">
                <div className="event-list-header mb-3">
                    <div className="text-right">
                        <img className="image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                    </div>
                    <div className="header-title-margin-top">
                        {this.renderBreadcrumbForChapterList()}
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="title">Events</div>
                        </div>

                        <div>
                            <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                        </div>
                    </div>
                </div>

                {
                    !this.state.loading ?

                        this.renderEvents()

                        :

                        <Loading />
                }




            </div>
        )
    }

}

export default EventList;