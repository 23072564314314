import React from "react";
import './question.scss';
import { Constants } from "../../../constants/constants";
import SingleSelect from "./single-select/single-select";
import MultiSelect from "./multi-select/multi-select";
import FillInTheBlank from "./fill-in-the-blank/fill-in-the-blank";
import Sequencing from "./sequencing/sequencing";
import Matching from "./matching/matching";
import Grouping from "./grouping/grouping";
import { MyAssessmentContext } from "../assessment-engine/assessment-engine";
import CorrectIncorrectAnswerModel from "../correct-incorrect-answer-modal/correct-incorrect-answer-modal";
import CorrectAnswer from "../correct-answer/correct-answer";
import InvalidAnswerModal from "../invalid-answer-modal/invalid-answer-modal";
import MultiStep from "../multi-step-questions/multi-step-questions";
import CorrectIncorrectAnswerScreen from "../correct-incorrect-answer-screen/correct-incorrect-answer-screen";
import Binary from "./binary/binary";

class Question extends React.Component {

    state = {
        content: {},
        showSolvedExamples: false
    };



    constructor(props) {
        super(props)
        //console.log(this.props.content)
    }


    componentWillMount() {

        if (this.props.content) {

            this.setState({ content: JSON.parse(JSON.stringify(this.props.content)) }, () => {
                if (this.props.answer)
                    this.updateAnswer(this.props.content.content.type, this.props.answer);
            })

        } else {
            const { navigation } = this.props;
            this.state.content = JSON.parse(JSON.stringify(navigation.getParam('content')));

            if (this.props.answer)
                this.updateAnswer(this.props.content.content.type, this.props.answer);
        }
    }

    getAnswer = () => {

        let keys = Object.keys(this.state.content);

        if (!keys.length)
            return null;

        let content = this.state.content.content;


        if (content.isMultiStep) {
            //console.log(content)
            let payload = this.multiStepComponent.getAnswer();
            return payload;
        }

        let payload;

        if (content.type == Constants.SIGNLE_SELECT)
            payload = this.singleSelectComponent.getAnswer();
        else if (content.type == Constants.MULTI_SELECT)
            payload = this.multiSelectComponent.getAnswer();
        else if (content.type == Constants.FILL_IN_THE_BLANK)
            payload = this.fillIntheBlankComponent.getAnswer();
        else if (content.type == Constants.MATCHING)
            payload = this.mathcingComponent.getAnswer();
        else if (content.type == Constants.SEQUENCE)
            payload = this.sequencingComponent.getAnswer()
        else if (content.type == Constants.GROUPING)
            payload = this.groupingComponent.getAnswer();
        // else if (content.type == Constants.LABELING)
        //     payload = this.labelingComponent.getAnswer();
        else if (content.type == Constants.BINARY)
            payload = this.binaryComponent.getAnswer();
        // else if (content.type == Constants.GEOMETRY_QUESTION)
        //     payload = this.geoMentryComponent.getAnswer();

        //console.log(payload)
        if (!payload || payload.payload.length == 0)
            return null;

        if (this.props.isMulti) {

            let answer = this.props.answer ? this.props.answer : {};

            if (!answer.hasOwnProperty('steps')) {
                answer['id'] = this.props.content._id;
                answer['steps'] = [{ answer: payload.payload, timeTakenToAnswer: payload.timeTakenToAnswer }];
            } else {
                answer.steps[this.props.stepIndexs] = { answer: payload.payload, timeTakenToAnswer: payload.timeTakenToAnswer };
            }
            return answer;
        }

        return {
            id: this.state.content._id,
            answer: payload.payload,
            timeTakenToAnswer: payload.timeTakenToAnswer
        }

    }

    updateAnswer = (type, payload) => {

        if (this.props.isMulti) {
            if (!payload.steps)
                return;
            else
                payload = payload.steps[this.props.stepIndexs];
        }

        let content = this.state.content

        if (type == Constants.GEOMETRY_QUESTION && content.content.canvas.type == 'CANVAS_CONSTRUCTIVE') {
            this.geoMentryComponent && this.geoMentryComponent.updateAnswer(payload);
            return
        }

        if (!payload || !payload.answer || !payload.answer.length || !type)
            return;

        if (type == Constants.SIGNLE_SELECT)
            this.singleSelectComponent.updateAnswer(payload);

        else if (type == Constants.MULTI_SELECT)
            this.multiSelectComponent.updateAnswer(payload);

        else if (type == Constants.FILL_IN_THE_BLANK)
            this.fillIntheBlankComponent.updateAnswer(payload);

        else if (type == Constants.LABELING)
            this.labelingComponent.updateAnswer(payload);

        else if (type == Constants.BINARY)
            this.binaryComponent.updateAnswer(payload);

        else if (type == Constants.GROUPING)
            this.groupingComponent.updateAnswer(payload);

        else if (type == Constants.MATCHING)
            this.mathcingComponent.updateAnswer(payload);

        else if (type == Constants.SEQUENCE)
            this.sequencingComponent.updateAnswer(payload);

    }



    getOption(type, content) {

        let delegate = this.props.delegate ? this.props.delegate : null;

        let quesNo = this.props.stepIndexs || this.props.stepIndexs == 0 ? this.props.stepIndexs + 1 : null;

        let isCheckPoint = this.props.isCheckPoint ? true : false;

        let answerForGeometry = this.props.answer

        if (this.props.isMulti && answerForGeometry && answerForGeometry.steps) {
            answerForGeometry = answerForGeometry.steps[this.props.stepIndexs];
        }

        if (this.props.content.content.isMultiStep) {
            //console.log(this.props.stepIndex, 'multi step')
            return (
                <MultiStep
                    content={JSON.parse(JSON.stringify(this.props.content))}
                    delegate={this.props.delegate ? this.props.delegate : null}
                    answer={this.props.answer ? this.props.answer : null}
                    stepIndex={!this.props.isReadOnly ? this.props.stepIndex : this.props.content.content.steps.length - 1}
                    ref={Ref => this.multiStepComponent = Ref}
                    totalSteps={this.props.content.content.steps.length}
                    showHint={this.props.showHint}
                    showSolvedExamplesModal={this.props.showSolvedExamplesModal}
                    isReadOnly={this.props.isReadOnly}
                    categoryThemeId={this.props.categoryThemeId}
                    isFromMyAssessment={this.props.isFromMyAssessment}
                    attachments={this.props.attachments}
                    isFromMyHomework={this.props.isFromMyHomework}
                    isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer}
                    userAnswerStatus={this.props.userAnswerStatus}
                    userAnswer={this.props.userAnswer}
                    isFromPracticeCaseStudy = {this.props.isFromPracticeCaseStudy}
                    practiceId = {this.props.practiceId}
                />);
        }

        switch (type) {

            case Constants.SIGNLE_SELECT:
                return <SingleSelect isReadOnly={this.props.isReadOnly} content={content} delegate={delegate} ref={Ref => this.singleSelectComponent = Ref} quesNo={quesNo} totalSteps={this.props.totalSteps} isCheckPoint={isCheckPoint} isMultiStep={this.props.isMulti} mulipStepQuestion={this.props.mulipStepQuestion} key={this.props.isMulti ? content._id + content.unique : content._id} categoryThemeId={this.props.categoryThemeId} isAssessment={this.props.isAssessment} isFromMyAssessment={this.props.isFromMyAssessment} attachments={this.props.attachments} isFromMyHomework={this.props.isFromMyHomework} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} userAnswerStatus={this.props.userAnswerStatus} userAnswer={this.props.userAnswer} />

            case Constants.MULTI_SELECT:
                return <MultiSelect isReadOnly={this.props.isReadOnly} content={content} delegate={delegate} ref={Ref => this.multiSelectComponent = Ref} quesNo={quesNo} totalSteps={this.props.totalSteps} isCheckPoint={isCheckPoint} isMultiStep={this.props.isMulti} mulipStepQuestion={this.props.mulipStepQuestion} key={this.props.isMulti ? content._id + content.unique : content._id} categoryThemeId={this.props.categoryThemeId} isFromMyAssessment={this.props.isFromMyAssessment} attachments={this.props.attachments} isFromMyHomework={this.props.isFromMyHomework} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} userAnswerStatus={this.props.userAnswerStatus} userAnswer={this.props.userAnswer} />

            case Constants.FILL_IN_THE_BLANK:
                return <FillInTheBlank isReadOnly={this.props.isReadOnly} content={content} delegate={delegate} ref={Ref => this.fillIntheBlankComponent = Ref} quesNo={quesNo} totalSteps={this.props.totalSteps} isCheckPoint={isCheckPoint} isMultiStep={this.props.isMulti} mulipStepQuestion={this.props.mulipStepQuestion} key={this.props.isMulti ? content._id + content.unique : content._id} categoryThemeId={this.props.categoryThemeId} isFromMyAssessment={this.props.isFromMyAssessment} attachments={this.props.attachments} isFromMyHomework={this.props.isFromMyHomework} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} userAnswerStatus={this.props.userAnswerStatus} userAnswer={this.props.userAnswer} />

            case Constants.MATCHING:
                return <Matching isReadOnly={this.props.isReadOnly} content={content} ref={Ref => this.mathcingComponent = Ref} delegate={delegate} quesNo={quesNo} totalSteps={this.props.totalSteps} isCheckPoint={isCheckPoint} isMultiStep={this.props.isMulti} mulipStepQuestion={this.props.mulipStepQuestion} key={this.props.isMulti ? content._id + content.unique : content._id} categoryThemeId={this.props.categoryThemeId} isFromMyAssessment={this.props.isFromMyAssessment} attachments={this.props.attachments} isFromMyHomework={this.props.isFromMyHomework} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} userAnswerStatus={this.props.userAnswerStatus} userAnswer={this.props.userAnswer} />

            case Constants.SEQUENCE:
                return <Sequencing isReadOnly={this.props.isReadOnly} content={content} delegate={delegate} ref={Ref => this.sequencingComponent = Ref} quesNo={quesNo} totalSteps={this.props.totalSteps} isCheckPoint={isCheckPoint} isMultiStep={this.props.isMulti} mulipStepQuestion={this.props.mulipStepQuestion} key={this.props.isMulti ? content._id + content.unique : content._id} categoryThemeId={this.props.categoryThemeId} isFromMyAssessment={this.props.isFromMyAssessment} attachments={this.props.attachments} isFromMyHomework={this.props.isFromMyHomework} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} userAnswerStatus={this.props.userAnswerStatus} userAnswer={this.props.userAnswer} />

            case Constants.GROUPING:
                return <Grouping isReadOnly={this.props.isReadOnly} content={content} delegate={delegate} ref={Ref => this.groupingComponent = Ref} quesNo={quesNo} totalSteps={this.props.totalSteps} isCheckPoint={isCheckPoint} isMultiStep={this.props.isMulti} mulipStepQuestion={this.props.mulipStepQuestion} key={this.props.isMulti ? content._id + content.unique : content._id} categoryThemeId={this.props.categoryThemeId} isFromMyAssessment={this.props.isFromMyAssessment} attachments={this.props.attachments} isFromMyHomework={this.props.isFromMyHomework} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer} userAnswerStatus={this.props.userAnswerStatus} userAnswer={this.props.userAnswer} />

            // case Constants.LABELING:
            //     return <Labelling isReadOnly={this.props.isReadOnly} content={content} delegate={delegate} ref={Ref => this.labelingComponent = Ref} quesNo={quesNo} totalSteps={this.props.totalSteps} isCheckPoint={isCheckPoint} isMultiStep={this.props.isMulti} mulipStepQuestion={this.props.mulipStepQuestion} key={this.props.isMulti ? content._id + content.unique : content._id} categoryThemeId={this.props.categoryThemeId} isFromMyAssessment={this.props.isFromMyAssessment} attachments={this.props.attachments} isFromMyHomework={this.props.isFromMyHomework}/>

            case Constants.BINARY:
                return <Binary isReadOnly={this.props.isReadOnly} content={content} delegate={delegate} ref={Ref => this.binaryComponent = Ref} quesNo={quesNo} totalSteps={this.props.totalSteps} isCheckPoint={isCheckPoint} isMultiStep={this.props.isMulti} mulipStepQuestion={this.props.mulipStepQuestion} key={this.props.isMulti ? content._id + content.unique : content._id} categoryThemeId={this.props.categoryThemeId} isFromMyAssessment={this.props.isFromMyAssessment} attachments={this.props.attachments}isFromMyHomework={this.props.isFromMyHomework} />

            // case Constants.GEOMETRY_QUESTION:
            //     if (content.content.canvas.type == 'CANVAS_CONSTRUCTIVE')
            //         return <GeometryQuestionContructive isReadOnly={this.props.isReadOnly} content={content} delegate={delegate} ref={Ref => this.geoMentryComponent = Ref} quesNo={quesNo} totalSteps={this.props.totalSteps} isCheckPoint={isCheckPoint} isMultiStep={this.props.isMulti} mulipStepQuestion={this.props.mulipStepQuestion} key={this.props.isMulti ? content._id + content.unique : content._id} categoryThemeId={this.props.categoryThemeId} isFromMyAssessment={this.props.isFromMyAssessment} answer={answerForGeometry} attachments={this.props.attachments} isFromMyHomework={this.props.isFromMyHomework}/>
            //     else
            //         return <GeometryQuestion isReadOnly={this.props.isReadOnly} content={content} delegate={delegate} optionRef={Ref => this.geoMentryComponent = Ref} ref={Ref => this.geometryQuestionComponentRef = Ref} quesNo={quesNo} totalSteps={this.props.totalSteps} isCheckPoint={isCheckPoint} isMultiStep={this.props.isMulti} mulipStepQuestion={this.props.mulipStepQuestion} key={this.props.isMulti ? content._id + content.unique : content._id} categoryThemeId={this.props.categoryThemeId} isFromMyAssessment={this.props.isFromMyAssessment} answer={answerForGeometry}  attachments={this.props.attachments} isFromMyHomework={this.props.isFromMyHomework}/>

            default:
                return <></>
        }

    }

    showCorrectAnswer() {
        this.props.delegate && this.props.delegate.hideCorrectIncorrectModal && this.props.delegate.hideCorrectIncorrectModal();
        this.setState({ showCorrectAnswer: true });
    }


    next() {

        if (this.props.showCorrectIncorrectModal) {
            //console.log('inside next question from question component')
            this.props.delegate && this.props.delegate.nextQuestionOnCorrectInCorrectAnswer && this.props.delegate.nextQuestionOnCorrectInCorrectAnswer();
        }
        else {
            //console.log("showSolvedExamples");
            this.setState({ showSolvedExamples: false }, () => {
                this.props.delegate && this.props.delegate.next && this.props.delegate.next();
            });
        }
    }

    nextFromCorrectAnswer() {
        this.setState({ showCorrectAnswer: false }, () => {
            this.props.delegate && this.props.delegate.nextQuestionOnCorrectInCorrectAnswer && this.props.delegate.nextQuestionOnCorrectInCorrectAnswer();
        });
    }



    render() {

        let content = this.state.content

        // //console.log(content)

        // //console.log(this.props.showCorrectIncorrectModal)

        return (
            <div className="">

                {
                    !this.props.showCorrectIncorrectModal ?
                        <>
                            {
                                <>
                                    {
                                        !this.props.isCheckPoint ?
                                            <>
                                                {
                                                    this.state.showSolvedExamples ? '' :
                                                        this.state.showCorrectAnswer ? <CorrectAnswer
                                                            isCorrect={this.props.isAnswerCorrect}
                                                            userAnswerForReadOnly={this.props.userAnswerForReadOnly}
                                                            content={content} delegate={this}
                                                            categoryThemeId={this.props.categoryThemeId} />
                                                            :
                                                            <>
                                                                {this.getOption(content.content.type, content)}
                                                            </>

                                                }
                                            </>
                                            :
                                            <>
                                                {!this.state.showSolvedExamples ?
                                                    <>
                                                        {this.getOption(content.content.type, content)}

                                                        {/* {!this.props.content.content.isMultiStep && this.props.showHint && <AssessmentHint showHint={this.props.showHint} delegate={this.props.delegate ? this.props.delegate : null} hint={content.hint ? content.hint : ''} content={content} />} */}

                                                        {/* {!this.props.content.content.isMultiStep && this.props.showSolvedExamplesModal && <SolvedExamplesAssessmentModal delegate={this} showModal={this.props.showSolvedExamplesModal} />} */}
                                                    </>

                                                    :
                                                    // <SolvedExamplesAssessment content={content} delegate={this} categoryThemeId={this.props.categoryThemeId} />
                                                    <></>
                                                }
                                            </>


                                    }
                                </>
                            }
                            {this.props.showInvalidAnswerModal && <InvalidAnswerModal modal={this.props.showInvalidAnswerModal} delegate={this.props.delegate ? this.props.delegate : null} categoryThemeId={this.props.categoryThemeId} />}
                        </>

                        :
                        <>
                            {/* {this.props.showCorrectIncorrectModal && <CorrectIncorrectAnswerScreen isCorrect={this.props.isAnswerCorrect} content={content} delegate={this} categoryThemeId={this.props.categoryThemeId} />} */}
                            {this.props.showCorrectIncorrectModal && <CorrectIncorrectAnswerModel isCorrect={this.props.isAnswerCorrect} content={content} delegate={this} categoryThemeId={this.props.categoryThemeId} />}
                        </>

                }

                {/* {this.props.showCorrectIncorrectModal && <CorrectIncorrectAnswerModel isCorrect={this.props.isAnswerCorrect} content={content} delegate={this} categoryThemeId={this.props.categoryThemeId} />} */}




            </div>
        )
    }
}






export default Question;

