const ROOT = '/student';
const Path = {

    HOME: ROOT + "/home",
    PRACTICE_QUESTIONS: ROOT + '/practice_questions',
    CLASS_SPECIFIC_PRACTICE: ROOT + '/class_practice_questions',
    MY_CERTIFICATE: ROOT + '/my_certificates',
    MY_ASSESSMENTS: ROOT + '/my_assessments',
    MY_CLASSWORK: ROOT + '/my_classwork',
    MY_HOMEWORK: ROOT + '/my_homework',
    EVENT_LIST : ROOT + '/event_list',
    MY_PROFILE : ROOT + '/my-profile',
    LEARNING_CONTENT : ROOT + '/learning-content',
    REFRESHER_COURSE : ROOT + '/refresher-course',
    CLASS_DIAGNOSTIC_ASSESSMENT : ROOT + '/class-diagnostic-assessment',
    DIAGNOSTIC_ASSESSMENT_LIST : ROOT + '/diagnostic-assessment-list',
    VIDEO_LIBRARY: ROOT + '/video_library',
    ALL_ELOS: ROOT + '/allexpectedlearning',
    BROWSE_CHAPTER :  ROOT + '/browsechapter',
    VIDEO_CHECKPOINT_LEARNING : ROOT + '/videocheckpointlearning',
    CHANGE_PASSCODE : ROOT + '/changepasscode',
    WORKSHEET_LIST : ROOT + '/worksheetlist',
    CLASS_MOCK_TEST : ROOT + '/classmocktest',
    EXAM_PREP_KIT : ROOT + '/examprepkit',

    CHAPTERS: ROOT + '/chapters',
    UPDATE_PASSWORD: ROOT + '/update-password',
    FORGOT_PASSWORD: ROOT + '/forgot-password',
    LOGIN: ROOT + '/login',
    ADMIN: ROOT + '/admin',
    CONTACTS: ROOT + '/contacts',
    TEACHERS: ROOT + '/teachers',
    CLASSES: ROOT + '/classes',
    PROFILE: ROOT + '/profile',
    CONFIRM_PROFILE: ROOT + '/confirm-profile',
    HOMEWORK: ROOT + '/homework',
    QUICK_VIEW: ROOT + '/quick/view',
    STANDARD_HOMEWORK: ROOT + '/standard/homework',


    HOMEWORK_COMPLETION_REPORT: ROOT + '/standard/homework/completionreport/',
    HOMEWORK_EVALUATION_REPORT: ROOT + '/standard/homework/homeworkevaluationreport/',
    MOST_WRONG_REPORT : ROOT + '/standard/homework/mostwrongreport/',
    ELO_LEVEL_REPORT : ROOT + '/standard/homework/elolevelreport/',

    


    PERSONALISED_HOMEWORK: ROOT + '/personalised/homework/adaptive',
    TEST: ROOT + '/test',

    ASSESSMENT_RESULTS_REPORT : ROOT + '/test/assessmentresults/',
    TEST_COMPLETION_REPORT : ROOT + '/test/testcompletionreport/',
    TEST_WRONG_ANSWER_REPORT : ROOT + '/test/testwronganswerreport/',
    TEST_ALL_QUESTIONS_REPORT : ROOT + '/test/testallquestionsreport/',


    ASSESSMENT: ROOT + '/assessment',
    CLASS_SPECIFIC_ASSESSMENT: ROOT + '/classassessment',
    SIGNUP: ROOT + '/signup',
    USERS_SIGNUP: ROOT + '/users/signup',
    GULF_SIGNUP: ROOT + '/signup/gulf',
    RMS_SIGNUP: ROOT + '/signup/rms',
    MPL_SIGNUP: ROOT + '/signup/mpl',
    FLC_SIGNUP: ROOT + '/signup/flc',
    MSF_SIGNUP: ROOT + '/signup/msf',
    //CMO_SIGNUP: ROOT + '/signup/cmosignup',
    CMO_SIGNUP: '/cmosignup',
    PERFORMANCE: ROOT + '/performance',
    STUDENTS: ROOT + '/students',
    ONBOARD: ROOT + '/onboarding',
    USERS: ROOT + '/users',
    EVENTS: ROOT + '/events',
    SCHOOLS: ROOT + '/schools',
    PAT_NOTIFICATION: ROOT + '/pat/notification/:code/:id/:classes',
    PARENT_PAT_NOTIFICATION: ROOT + '/pat/notification/:parentId/all',
    HOMEWORK_NOTIFICATION: ROOT + '/homework/notification/:homeworkId',
    HOMEWORK_ASSIGN: ROOT + '/students/homework/assign/:code/:eventId',
    HOMEWORK_ASSIGN_V2: ROOT + '/students/homework/assign',
    CLAIM_FORM: ROOT + '/students/claim/form/:id',
    QUESTION_BANK: ROOT + '/question-bank',
    LEARNING_RESOURCES: ROOT + '/learning-resources',
    MMS_CLAIM_FORM: ROOT + '/students/may2022/claim/form/:id',
    MPL_2022_CLAIM_FORM: ROOT + '/students/mpl2022/claim/form/:id',
    MSF_2022_CLAIM_FORM :ROOT + '/students/msf2022/claim/form/:id',
    MMS_JUNE_22_CLAIM_FORM: ROOT + '/students/june2022/claim/form/:id',
    FLC_CLAIM_FORM: ROOT + '/students/flc/claim/form/:id',
    HOMEWORK_V2: ROOT + '/homework/v2',
    INVITATION_SUCCESS: ROOT + '/invitation/success',

    WATCH_VIDEO : ROOT + '/watchvideo',
};

export default Path;