import React from "react";
import './completed-assessments-list.scss';
import { ImageConstants, Constants } from "../../../../constants/constants";
import { getImageSource, getThemeImageSource } from "../../../../utility/image-source";
import SharedThemeCategory from "../../../../../shared-storage/category-theme";
import CommonUtils from "../../../../utility/common-utilities";
import ReactTooltip from "react-tooltip";
import ChapterListPopup from "../../../my-classwork-V1/chapter-list-popup/chapter-list-popup";
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import Path from "../../../routes/routes-path";

class CompletedAssessmentsList extends React.Component {


    state = {
        content: this.props.content
    }

    constructor(props) {
        super(props)
        this.staticText = SharedStaticText.getStaticText('homeworkCard');
    }



    getButtonTitle = (status) => {
        // if (status == 'ASSIGNED')
        //     return this.staticText.startButtonTitle

        // if (status == 'COMPLETED')
        //     return this.staticText.learnButtonTitle

        if (status == 'IN_PROGRESS')
            return 'Resume Mock Test'
    }



    showChaptersPopup = (item) => {
        this.chapterTitles = item.chapters
        this.setState({ showChaptersPopup: true })
    }


    hideChapters = () => {
        this.setState({ showChaptersPopup: false })
    }


    getHeaderForAssessment = (item) => {

        let isRefresherCourse = item.type == Constants.ASSESSMENT_TYPE_REFRESHER_COURSE ? true : false
        let isMultipleChaptersAssessment = item.type == Constants.ASSESSMENT_TYPE_MULTIPLE_CHAPTERS ? true : false
        let status = item.status

        return (
            <div className="d-flex flex-column cursor-pointer" onMouseEnter={ReactTooltip.rebuild}>
                <div>
                    {
                        isRefresherCourse ?
                            <>
                                {
                                    status == 'COMPLETED' ?
                                        <span className="chapter-title cursor-pointer">Refresher Course Diagnostic Assessment</span>
                                        :
                                        <span className="chapter-title cursor-pointer"> Refresher Course Diagnostic Assessment</span>
                                }
                            </>
                            :
                            <>
                                {
                                    isMultipleChaptersAssessment ?
                                        <div className="cursor-pointer" onClick={this.showChaptersPopup}>
                                            <span className="chapter-title">{`Multiple Chapters Assessment`}</span>
                                            <img className="arrow-size-1  ml-2" src={getImageSource(ImageConstants.INFO)}
                                                data-tip={"View Assignment Information"} />
                                        </div>
                                        :
                                        <>
                                            <span className="chapter-title">{item.chapterTitle}</span>
                                        </>
                                }
                            </>
                    }

                </div>
            </div>
        )
    }

    onClickCard(item) {
        this.props.delegate && this.props.delegate.onClickCard && this.props.delegate.onClickCard(item)
    }

    showSingleChapterPerformanceReport(item) {
        this.props.delegate && this.props.delegate.showSingleChapterPerformanceReport && this.props.delegate.showSingleChapterPerformanceReport(item)
    }



    renderStats(item) {

        let categoryId = item.categoryId
        let content = this.state.content
        let status = item.status

        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryId)
        let backgroundColor = this.theme[`secondaryColors`][`chapterCardColor`]

        let lgButton = `linear-gradient(#BDFFF5,#BDFFF5)`
        return (
            <div className="stats-card mt-4">
                <div className="chapter-background" style={{ backgroundColor: backgroundColor }}>
                    <div className="row  mr-0 ml-0  align-items-center">
                        <div className="col-3 d-flex align-items-center">
                            {/* {
                                this.props.item.chapterImageUrl ? <img className="chapter-image-size" src={this.props.item.chapterImageUrl} />
                                    :
                                    <img className="chapter-image-size" src={getImageSource(ImageConstants.CHAPTER_ICN)} />
                            } */}


                            {this.getHeaderForAssessment(item)}

                        </div>



                        <div className="col text-center">
                            <div>
                                <img className="icon-size" src={getImageSource(ImageConstants.DATE_ICON)} />
                                <span className="status-text">Assigned</span>
                            </div>
                            <div className="created-date">{CommonUtils.getDate(item.startTime)}</div>
                        </div>
                        <div className="col text-center">
                            <div>
                                <img className="icon-size" src={getImageSource(ImageConstants.QUESTION_MARK_ICON)} />
                                <span className="status-text">Questions</span>
                            </div>
                            <div className="created-date">{item.questionIds.length}</div>
                        </div>
                        <div className="col text-center">
                            <img className="icon-size" src={getImageSource(ImageConstants.CLOCK_ICON_GRAY)} />
                            <span className="status-text">Minutes</span>
                            <div className="created-date">{item.duration}</div>
                        </div>
                        {/* {
                            this.props.item.status != 'COMPLETED' ?
                                <div className="col text-center">
                                    <div>
                                        <img className="icon-size" src={getImageSource(ImageConstants.DATE_ICON)} />
                                        <span className="status-text">{this.staticText.dueDateText}</span>
                                    </div>
                                    <div className={`created-date ${this.props.item.isDueDatePassed ? 'due-date-passed-date-color' : ''} `}>{CommonUtils.getDate(this.props.item.endTime, true)}</div>
                                </div> : <></>
                        } */}
                        {item.finishTime ?
                            <div className="col text-center">
                                <div>
                                    <img className="icon-size" src={getImageSource(ImageConstants.DATE_ICON)} />
                                    <span className="status-text">{this.staticText.submitDateText}</span>
                                </div>
                                <div className="created-date">{` ${CommonUtils.getDate(item.finishTime)}`}</div>
                            </div>
                            :
                            <></>
                        }
                    </div>
                </div>
                {
                    item.status == 'COMPLETED' ?
                        <div className="assessmentCardDetails mt-3 d-flex justify-content-center align-items-center ">
                            <div className="completedMarkContainer d-flex justify-content-center align-items-center flex-column mr-5">
                                <div className="assessmentScoreDetails">
                                    <img className="exam-pad-size pb-1 mr-1" src={getImageSource(ImageConstants.EXAM_PAD_ICON_GRAY)} />

                                    <span className="assessmentCardText">{this.staticText.marksScoredText}</span>
                                </div>
                                <div className="boldText">{item.markScored}/{item.totalMark}</div>
                            </div>

                            <div className="correctInCorrectContainer d-flex flex-row ">
                                <div style={{ backgroundImage: lgButton }} className="mr-5 correct-button" >
                                    <span className="buttonText">Correct: <span className="answerCountText">{item.correct}</span></span>
                                </div>
                                <div className="incorrect-button mr-5">
                                    <span className="buttonText">Incorrect: <span className="answerCountText">{item.inCorrect}</span></span>
                                </div>
                                <div className="not-answered-button mr-5">
                                    <span className="buttonText">Not Answered: <span className="answerCountText">{item.notAttempted}</span></span>
                                </div>
                            </div>
                            <div className="completedMarkContainer d-flex justify-content-center align-items-center flex-column mr-5">
                                <div className="assessmentScoreDetails">
                                    <img className="exam-pad-size pb-1 mr-1" src={getImageSource(ImageConstants.CLOCK_ICON_GRAY)} />

                                    <span className="assessmentCardText">{`Time Taken`}</span>
                                </div>
                                <div className="boldText">{`${item.timeTaken ? item.timeTaken : 0} Mins`}</div>
                            </div>

                        </div>
                        :

                        <></>
                }

                <div className="d-flex justify-content-center button-gap">

                    {item.status == 'COMPLETED' ?
                        <div className="d-flex justify-content-center completed-button-padding">
                            <div onClick={() => this.showSingleChapterPerformanceReport(item)} className="view-answer-wrapper cursor-pointer" >
                                <div className="text-center view-answer-button">
                                    {'Insights'}
                                </div>
                            </div>
                            <div onClick={() => this.onClickCard(item)} className="view-answer-wrapper cursor-pointer  ml-5"  style={{background : "#6B52C8", border : 'none'}}>
                                <div className="text-center view-answer-button" style={{color : 'white'}}>
                                    {'View Answers'}
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                    }

                    {
                        item.status == 'COMPLETED' ?
                            <></>
                            :
                            <div className={`d-flex justify-content-center button-padding ${item.status == 'COMPLETED' ? "completed-button-padding" : ''}`}>
                                <div className={`button-wrapper ${item.status == 'COMPLETED' ? "completed-left" : ''} `} >
                                    <div className="text-center start-button cursor-pointer"
                                        onClick={() => this.onClickCard(item)}>
                                        {this.getButtonTitle(item.status)}
                                    </div>
                                </div>
                            </div>

                    }
                </div>
                <img className="homework-card-footer" src={getThemeImageSource(ImageConstants.HOMEWORK_CARD_FOOTER, this.props.categoryId)} />
            </div>
        )
    }

    goToHomeScreen() {
        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }

    goBack() {
        this.props.delegate && this.props.delegate.hideCompletedAssessments && this.props.delegate.hideCompletedAssessments()
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home`} {`> `}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goBack()} >{`Mock Test`} {`> `}</span>
                <span className='title-1 pl-2' >{`Mock Test Completed List`}</span>
            </div>
        )
    }


    render() {
        return (
            <div className="completed-assessments-list mb-5">
                <div className="text-right">
                    <img className="header-image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                </div>
                <div className="header-title-margin-top">
                    {this.renderBreadcrumbForChapterList()}
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="title">Mock Test</div>
                    </div>
                    <div>
                        <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                    </div>
                </div>
                {
                    this.state.content && this.state.content.length ?
                        <div>
                            {
                                this.state.content.map(item => (
                                    <div>
                                        {this.renderStats(item)}
                                    </div>
                                ))
                            }
                        </div>
                        : <div className="text-center mt-3">No Completed Mock Test</div>
                }
                {this.state.showChaptersPopup && <ChapterListPopup modal={true} close={this.hideChapters} delegate={this} chapters={this.chapterTitles} categoryThemeId={this.chapterTitles && this.chapterTitles.length && this.chapterTitles[0].categoryThemeId ? this.chapterTitles[0].categoryThemeId : "1"} />}
            </div>
        )
    }
}

export default CompletedAssessmentsList;
