import React from "react";
import './exam-prep-kit.scss';
import SharedProfile from "../../../../shared-storage/profile";
import ExamPrepKitIntroScreen from "../exam-prep-kit-intro-screen/exam-prep-kit-intro-screen";
import ChapaterList from "../chapter-list/chapter-list";
import TableOfContent from "../table-of-contents/table-of-contents";
import Path from "../../routes/routes-path";
import ChapterLockModal from "../../chapter-list-v1/chapter-lock-modal/chapter-lock-modal";

class ExamPrepKit extends React.Component {

    state = {
        showIntroScreen: false
    }

    constructor(props) {
        super(props)
        this.userInfo = SharedProfile.getProfile()
    }

    componentDidMount() {
        this.handleExamPrepKit()
    }


    handleExamPrepKit = () => {

        let isTrial = this.userInfo.isPrepkitFreeUser ? this.userInfo.isPrepkitFreeUser : false

        if (!this.userInfo.isExamPrepkitIntroShowed) {
            this.showIntroScreen()
            // this.props.navigation.navigate('ExamPrepKitIntroScreen', { meta: { from: "LANDING_SCREEN", showGuarantee: isTrial ? true : false, guaranteedScore: 10, percentage: 0 } });
            return
        }
        this.showPrepKitChapterList()
        // this.props.navigation.navigate('PrepKitChapterList', { meta: {} });
        return
    }


    showIntroScreen() {
        this.setState({ showIntroScreen: true })
    }

    showPrepKitChapterList() {
        this.setState({ showIntroScreen: false, showPrepkitChapterList: true })
    }

    showChapterTableOfContents(data) {
        if (data.isLocked) {
            this.setState({
                showModal: true,
                chapterTitle: data.chapterTitle,
                imageUrl: data.imageUrl
            })
        } else {
            this.setState({ showPrepkitChapterList: false, showChapterTableOfContents: true, selectedChapter: data })
        }
    }

    closeModal = () => {
        this.setState({
            showModal: false
        })
    }

    goToHomeScreen() {
        return this.props.history.replace({ pathname: Path.HOME })
    }

    hideTableOfContents() {
        this.setState({ showPrepkitChapterList: true, showChapterTableOfContents: false, selectedChapter: '' })
    }



    render() {
        return (
            <div>
                {
                    this.state.showIntroScreen ? <ExamPrepKitIntroScreen delegate={this} /> : <></>
                }
                {
                    this.state.showPrepkitChapterList ? <ChapaterList delegate={this} /> : <></>
                }
                {
                    this.state.showChapterTableOfContents ? <TableOfContent
                        delegate={this} metaInfo={this.state.selectedChapter} showPrepKitChapterList={() => this.showPrepKitChapterList()} /> : <></>
                }
                {
                    this.state.showModal && <ChapterLockModal delegate={this} modal={this.state.showModal} chapterTitle={this.state.chapterTitle} imageUrl={this.state.imageUrl} />
                }
            </div>
        )
    }

}

export default ExamPrepKit;