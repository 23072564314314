import React from "react";
import './my-assessments.scss';
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { Constants, ImageConstants } from "../../../constants/constants";
import ChapterList from "../../common/chapter-list/chapter-list";
import SharedProfile from "../../../../shared-storage/profile";
import CommonApi from "../../../apis/common";
import StartAssessment from "../../common/start-assessment/start-assessment";
import AssessmentEngine from "../../common/assessment-engine/assessment-engine";
import SharedThemeCategory from "../../../../shared-storage/category-theme";
import AssessmentEndScreen from "./assessment-end-screen/assessment-end-screen";
import MyAssessmentResult from "../../my-assessment-result/my-assessment-result";
import AssessmentApi from "../../../apis/assessment";
import Path from "../../routes/routes-path";
import CompletedAssessmentsList from "./completed-assessments-list/completed-assessments-list";
import ClassMockTestPerformanceReport from "../../class-mock-test-performance-report/class-mock-test-performance-report";
import { Modal, ModalBody } from "reactstrap";


class MyAssessments extends React.Component {


    state = {
        searchText: '',
        showStartAssessment: false,
        showAssessmentEngine: false,
        showAssessmentEndScreen: false,
        showViewAnswersScreen: false,
        mockTestList: [],
        selectedAssessmentType: ''
    }


    constructor(props) {
        super(props)


    }

    questionSetHash = {}

    componentDidMount() {
        this.getAssessmentMeta()
        this.getUserAssessmentActivity()

        let chapterInfo = this.props.history.location.state?.data
        if (chapterInfo && chapterInfo.calledFrom === 'EXAM_PREP_KIT') {

            console.log(chapterInfo)
            let chapter = {
                chapterKey: chapterInfo.chapterKey,
                chapterTitle: chapterInfo.chapterTitle,
                categoryId: chapterInfo.categoryThemeId,

            }
            this.onStart(chapter)
        }
    }






    getAssessmentMeta = () => {

        CommonApi.getMiscellaneousList({ type: 'ASSESSMENT_META' }).then((data) => {
            if (data) {
                this.setState({ assessmentMeta: data.response[0] })
            }
        }).catch(err => {
            if (err) {

                return
            }
        })
    }

    getUserAssessmentActivity() {
        let payload = {}
        AssessmentApi.getUserAssessmentActivity(payload).then(res => {
            console.log(res)

            let selectedChapterMockTest = []

            selectedChapterMockTest = res.contents.filter(assessment => assessment.type === 'SELECTED_CHAPTER_MOCK_TEST')

            let filteredContents = res.contents.filter(assessment => assessment.type !== 'SELECTED_CHAPTER_MOCK_TEST')

            if (filteredContents.length > 0) {
                this.setState({ content: filteredContents })
            }

            if (selectedChapterMockTest.length) {
                selectedChapterMockTest.forEach(item => this.questionSetHash[item.questionSetId] = item)
                this.setState({ selectedChapterMockTest })
            }

            this.getMockTestList()

        }).catch(err => {
            console.log(err)
        })
    }


    getMockTestList() {
        let payload = {
            type: 'CLASS_MOCK_TEST'
        }
        AssessmentApi.getMockTestList(payload).then(res => {
            console.log(res)

            let mockTestList = []

            res.questionSetList.forEach(item => {

                if (this.questionSetHash[item._id]) {
                    console.log('inside......')
                    mockTestList.push(this.questionSetHash[item._id])
                } else {
                    item.questionSetId = item._id
                    item.status = 'START'
                    mockTestList.push(item)
                }
            })


            this.setState({ mockTestList: mockTestList })
        }).catch(err => {
            console.log(err)
        })
    }


    setSearchText(event, key) {
        this.setState({ [key]: event.target.value }, () => {
            if (!this.state.searchText) {
                this.getSearchedChapterList()
            }
        })
    }


    getSearchedChapterList() {
        this.setState({ reload: true }, () => {
            this.setState({ reload: false })
        })
    }

    goToHomeScreen() {
        return this.props.history.replace({ pathname: Path.HOME })
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                <span className='title-1 pl-2' >{`Mock Test`}</span>
            </div>
        )
    }

    onStart = (data) => {
        console.log(data)
        this.setState({
            showStartAssessment: true,
            chapterKey: data.chapterKey, chapterTitle: data.chapterTitle,
            categoryThemeId: data.categoryId,
            from: data.from ? data.from : '',
            questionSetId: data.questionSetId ? data.questionSetId : null,
        })
        // this.props.navigation.navigate('StartAssessment', { meta: { chapterKey: data.chapterKey, chapterTitle: data.chapterTitle, categoryThemeId: data.categoryId } })
    }

    hideStartAssessmentScreen() {
        this.setState({ showStartAssessment: false })
    }

    showAssessmentEngine(data) {
        //console.log(data)
        this.setState({ showAssessmentEngine: true, showStartAssessment: false, ...data })
    }

    showAssessmentEndScreen(assessmentCompletedInfo) {
        console.log(assessmentCompletedInfo)
        this.setState({ showAssessmentEngine: false, showAssessmentEndScreen: true, ...assessmentCompletedInfo })
    }

    hideAssessmentEndScreen() {
        this.setState({ showAssessmentEndScreen: false }, () => {
            this.getUserAssessmentActivity()
        })
    }

    showViewAnswersScreen(assessmentEndScreenInfo) {
        this.setState({ showViewAnswersScreen: true, showAssessmentEndScreen: false, assessmentEndScreenInfo: assessmentEndScreenInfo.meta })
    }

    hideViewAnswersScreen() {
        console.log('here....')
        this.setState({ showViewAnswersScreen: false, showAssessmentEndScreen: false })
    }

    showCompletedAssessments() {
        this.hideViewAnswersScreen()
        this.hidePerformanceReport()
        this.setState({ showCompletedAssessments: true }, () => {
            this.getUserAssessmentActivity()
        })
    }

    hideCompletedAssessments() {
        this.setState({ showCompletedAssessments: false })
    }

    onClickCard = (item) => {
        this.hideCompletedAssessments()
        this.hideStartAssessmentScreen()

        if (item.status == 'IN_PROGRESS') {

            let chapters = []


            if (item.type == Constants.REFRESHER_COURSE) {

                this.showAssessmentEngine({
                    chapterTitle: "Diagnostic Assessment",
                    isResume: true,
                    assessmentActivityId: item._id,
                    categoryThemeId: "1",
                    assessmentId: item.assessmentId,
                    from: Constants.REFRESHER_COURSE
                })
                // this.props.navigation.navigate('AssessmentEngine', {
                //     meta: {
                //         chapterTitle: "Diagnostic Assessment",
                //         isResume: true,
                //         assessmentActivityId: item._id,
                //         categoryThemeId: "1",
                //         assessmentId: item.assessmentId,
                //         from: Constants.REFRESHER_COURSE
                //     }
                // })
                return
            }
            if (item.chapterInfo) {
                console.log(item.chapterInfo)
                for (let i = 0; i < item.chapterInfo.length; i++) {
                    chapters.push(item.chapterInfo[i].title)
                }
            }
            // moengage.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, Status: 'Resume', Chapters: item.chapterInfo ? chapters.toString() : item.chapterTitle, 'Starting Point': 'Chapter Assessment' })
            // FireBaseEvent.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, Status: 'Resume', Chapters: item.chapterInfo ? chapters.toString() : item.chapterTitle, 'Starting Point': 'Chapter Assessment' })
            // Facebook.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, Status: 'Resume', Chapters: item.chapterInfo ? chapters.toString() : item.chapterTitle, 'Starting Point': 'Chapter Assessment' })
            // BranchIO.logCustomEvent('Assessment', { 'User Id': this.userInfo.userId, Status: 'Resume', Chapters: item.chapterInfo ? chapters.toString() : item.chapterTitle, 'Starting Point': 'Chapter Assessment' })

            this.showAssessmentEngine({
                chapterTitle: item.chapterTitle,
                isResume: true, assessmentActivityId: item._id,
                categoryThemeId: item.categoryId,
                selectedChapters: item.chapterInfo, assessmentType: item.type
            })

            // this.props.navigation.navigate('AssessmentEngine', { meta: { chapterTitle: item.chapterTitle, isResume: true, assessmentActivityId: item._id, categoryThemeId: item.categoryId, selectedChapters: item.chapterInfo } })
        } else {
            this.showViewAnswersScreen({ meta: { categoryThemeId: item.categoryId, chapterTitle: item.chapterTitle, chapterKey: item.chapterKey, assessmentActivityId: item._id } })
            // this.props.navigation.replace('MyAssessmentResult', { meta: { categoryThemeId: item.categoryId, chapterTitle: item.chapterTitle, chapterKey: item.chapterKey, assessmentActivityId: item._id } })
        }

    }


    navigate(item) {
        this.onStart({
            chapterKey: null, chapterTitle: 'Mock Test', categoryId: 1, from: 'SELECTED_CHAPTER_MOCK_TEST',
            questionSetId: item.questionSetId
        })
    }

    showPerformanceReport = (item, index) => {
        console.log(item)
        this.setState({
            showPerformanceReport: true, assessmentActivityId: item._id, assessmentType: item.type,
            selectedMockTestTitle: `Mock Test ${index + 1}`
        })
    }

    showSingleChapterPerformanceReport(item) {
        this.hideCompletedAssessments()
        this.setState({
            showPerformanceReport: true, assessmentActivityId: item._id, assessmentType: item.type,
            selectedMockTestTitle: item.chapterTitle
        })
    }

    hidePerformanceReport = () => {
        this.setState({ showPerformanceReport: false })
    }

    onSelectType(type) {
        this.setState({ selectedAssessmentType: type })
    }


    getButton(item, index) {
        console.log(item)

        item.chapterTitle = 'Mock Test'

        if (item.status === 'START') {
            return (
                <div className="start-button text-center cursor-pointer" onClick={() => this.navigate(item)}>
                    Start
                </div>
            )
        } else if (item.status === 'IN_PROGRESS') {
            return (
                <div className="start-button text-center cursor-pointer" onClick={() => this.onClickCard(item)}>
                    Resume
                </div>
            )
        } else if (item.status === 'COMPLETED') {
            return (
                <div className="d-flex align-items-center" >
                    <div className="insights-button text-center cursor-pointer" onClick={() => this.showPerformanceReport(item, index)}>
                        Insights
                    </div>
                    {/* <div className="learning-gap-button text-center ml-4">
                        Bridge learning Gaps
                    </div> */}
                </div>
            )
        }
    }


    renderMockTestList() {
        return (
            <div className="mt-3">
                {
                    this.state.mockTestList.map((item, index) => (
                        <div className={`question-set row mr-0 ml-0 mb-4 pb-4 align-items-center ${index != this.state.mockTestList.length - 1 ? 'question-border-bottom' : ''}`}>
                            <div className="col pl-0">
                                <div className="d-flex align-items-center">
                                    <span className="set-text">Mock Test {index + 1}</span>
                                </div>
                            </div>
                            <div className="col-3 pr-0 d-flex justify-content-end">
                                {this.getButton(item, index)}
                            </div>
                        </div>
                    ))
                }
                <img className="divider-image-width mb-5" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, 1)} />
            </div>
        )
    }


    renderChapterList() {
        return (
            <div className="chapter-list">
                <div className="text-right">
                    <img className="header-image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                </div>
                <div className="header-title-margin-top">
                    {this.renderBreadcrumbForChapterList()}
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="title">Mock Test</div>
                        {/* <div className="text-right mr-5 ">
                            <img className="search-icon-width"
                                src={getImageSource(ImageConstants.CHAPTER_SEARCH_ICON)}
                                onClick={() => this.getSearchedChapterList()}></img>
                            <input
                                className="input-box"
                                type="text"
                                value={this.state.searchText}
                                onChange={e => this.setSearchText(e, 'searchText')}
                                placeholder='Search Chapter'>
                            </input>

                        </div> */}
                        <div className="mr-5">
                            <div className="insights-button cursor-pointer" onClick={() => this.showCompletedAssessments()}>My Mock Test</div>
                        </div>
                    </div>
                    <div>
                        <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                    </div>
                </div>
                {
                    this.state.mockTestList && this.state.mockTestList.length ? this.renderMockTestList() : <></>
                }
                {
                    !this.state.reload ?
                        <ChapterList
                            type="MY_ASSESSMENTS"
                            assessmentMeta={this.state.assessmentMeta}
                            searchedChapterName={this.state.searchText}
                            onSelectChapter={(chapter) => this.selectedChapter(chapter)}
                            delegate={this} />
                        : ''
                }
            </div>
        )
    }

    renderAssessmentTypeSelectionPopup() {
        return (
            <Modal className="cw-common-custom-modal-center-for-assessment-type-selection" isOpen={true} size={'lg'}  >
                <div className="pt-4 my-auto text-right">
                    <img className="challenge-header" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_HEADER)}></img>
                    {this.props.showTrialFeatures ? <img className='badge-image' src={getImageSource(ImageConstants.BADGE_IMAGE)} /> : ''}
                    {/* <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.COUNTRY_CLOSE_ICON)} onClick={() => this.onClickClose()} /> */}
                </div>
                <ModalBody>
                    <div className="pratice-container">
                        <div className="text-center header-text">Select Type Of Assessment</div>
                        <div className=' mt-4 mb-4 d-flex align-items-center flex-column'>
                            <div className="d-flex align-items-center justify-content-around mt-2">
                                {/* <div className="continue-button cursor-pointer text-center" onClick={() => this.onClickClose()}>
                                        {`OK`}
                                    </div> */}
                                <div className="take-challenge cursor-pointer  text-center" onClick={() => this.onSelectType('SIGNLE_CHAPTER')}>
                                    {`Single Chapter`}
                                </div>
                            </div>
                            {/* <div><img className='chapter-lock-image-size' src={getImageSource(ImageConstants.CHAPTER_LOCK)} /></div>
                            <div className='ml-2'><span className='header-text'>{this.props.chapterTitle}</span><span className='sub-text'> will be available soon.</span></div> */}
                        </div>
                        <div className=' mb-2 d-flex align-items-center flex-column'>
                            <div className="d-flex align-items-center justify-content-around mt-2">
                                <div className="take-challenge cursor-pointer  text-center" onClick={() => this.onSelectType('SELECTED_CHAPTER_MOCK_TEST')}>
                                    {`Multiple Chapters`}
                                </div>
                            </div>
                        </div>
                        <div className="position">
                            <img className="challenge-footer" src={getImageSource(ImageConstants.CONFIRMATION_MODAL_FOOTER)}></img>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }



    render() {

        this.theme = SharedThemeCategory.getThemeCategory(this.state.categoryThemeId)
        //console.log(this.theme)


        if (this.state.showStartAssessment) {
            return (
                <StartAssessment delegate={this}
                    meta={{
                        chapterKey: this.state.chapterKey,
                        chapterTitle: this.state.chapterTitle,
                        categoryThemeId: this.state.categoryThemeId,
                        from: this.state.from ? this.state.from : '',
                        questionSetId: this.state.questionSetId ? this.state.questionSetId : null,
                        showQuestionSetSelection: true
                    }}
                />
            )
        }

        if (this.state.showAssessmentEngine) {
            return (
                <AssessmentEngine meta={{
                    chapterKey: this.state.chapterKey,
                    chapterTitle: this.state.chapterTitle,
                    categoryThemeId: this.state.categoryThemeId,
                    questionSetId: this.state.questionSetId,
                    assessmentActivityId: this.state.assessmentActivityId ? this.state.assessmentActivityId : null,
                    assessmentId: this.state.assessmentId ? this.state.assessmentId : null,
                    isResume: this.state.isResume ? this.state.isResume : null,
                    selectedChapters: this.state.selectedChapters ? this.state.selectedChapters : null,
                    from: this.state.from ? this.state.from : '',
                    type: this.state.assessmentType ? this.state.assessmentType : ''
                }}
                    delegate={this} />
            )
        }

        if (this.state.showAssessmentEndScreen) {
            return (
                <AssessmentEndScreen
                    metaInfo={this.state}
                    delegate={this}
                    showViewAnswersScreen={(fromAssess) => this.showViewAnswersScreen(fromAssess)}
                />
            )
        }

        if (this.state.showViewAnswersScreen) {
            return (
                <MyAssessmentResult
                    meta={this.state.assessmentEndScreenInfo}
                    delegate={this}
                    hideViewAnswersScreen={() => this.hideViewAnswersScreen()} />
            )
        }

        if (this.state.showCompletedAssessments) {
            return (
                <CompletedAssessmentsList content={this.state.content} delegate={this} />
            )
        }

        if (this.state.showPerformanceReport) {
            return (
                <ClassMockTestPerformanceReport
                    meta={
                        {
                            assessmentActivityId: this.state.assessmentActivityId,
                            from: this.state.assessmentType,
                            selectedMockTestTitle: this.state.selectedMockTestTitle
                        }
                    }
                    delegate={this} />
            )
        }


        return (
            <div className="class-specific-my-assessments">
                {
                    this.renderChapterList()
                }
            </div>
        )
    }

}

export default MyAssessments;