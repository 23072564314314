import Axios from 'axios';
import HttpClient from './http-client';
import URL from './url';

export default class EventApi {


    static getMultipleTwoStepEventStatus(payload) {

        let url = URL.BASE_URL + URL.MULTIPLE_TWO_STEP_EVENT_STATUS;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static saveScholarshipFeedback(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SCHOLARSHIP_FEEDBACK, null, payload);
    }

    static getScholarshipAssessmentReport(payload) {

        let url = URL.BASE_URL + URL.SCHOLARSHIP_ASSESSMENT_REPORT;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static updateStudentScollarShipEligible(payload) {
        return HttpClient.put(URL.BASE_URL + URL.SCOLLARSHIP, null, payload);
    }

    


}


