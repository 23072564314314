import React from "react";
import './paid-event-landing-screen.scss';
import SharedProfile from "../../../../shared-storage/profile";
import { Constants } from "../../../constants/constants";
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import Path from "../../routes/routes-path";
import EventApi from "../../../apis/event";
import CommonApi from "../../../apis/common";

export default class PaidEventLandingScreen extends React.Component {
    constructor(props) {
        super(props);

        this.staticText = {
            headerText: "Recognizing Ramanujan Competetion. 2022",
            description: "This competition is designed to identify students from grades 6 - 9 who have the potential to be like the great mathematician Srinivas Ramanujan. The event is conducted by Mathematical Sciences Foundation - an internationally recognised registered society that has been founded by some of India's leading citizens, corporate leaders, civil servants, mathematicians, and scientists.",
            commingSoonText: "5–7 PM IST, 15th Oct 2022",
            subHeader: "Pay to Register for the event",
            startButton: "PAY TO REGISTER",
            inviteText: "Invite Friends",
            inviteMessage: "Hey! There is a new Challenge coming up on this Maths learning App, Participate....",
            url: "https://www.countingwell.com",
            paidText: "Your payment is received. You can take the quiz on the scheduled date",
            prepareButtonText: "PREPARE FOR EVENT",
            mockTestButtonText: "TAKE A MOCK TEST"
        }

        this.userInfo = SharedProfile.getProfile();

        this.event = this.props.event

        //console.log(this.event)

        if (this.event) {

            if (this.event.staticText && this.event.staticText.paidEventLandingScreen) {
                this.staticText = this.event.staticText.paidEventLandingScreen
            }

            if (this.event.chapters) {
                this.state.chapters = this.event.chapters
            }
        }

        // this.props.navigation.addListener("didBlur", () => {

        if (this.mplEventTimer)
            clearInterval(this.mplEventTimer)
        // });
    }

    videoPlayerRefs = {};

    state = {
        chapters: [
        ],
        showTimer: false,
        time: '00:00:00',
        remainingTime: 0,
        activeIndex: 0,
    }


    componentDidMount() {

        // this.props.navigation.addListener("didFocus", () => {

        //     BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });

        // this.props.navigation.addListener("didBlur", () => {

        //     BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonClick);
        // });
    }

    handleBackButtonClick = () => {
        this.props.navigation.navigate('LandingScreen')
        return true
    }

    handleNavigation = () => {
    }

    onPressInvite = () => {
        // FireBaseEvent.logCustomEvent("EVENT_CAMPAIGN_INVITE_FRIEND", { event: this.event.eventTitle, name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // Facebook.logCustomEvent("EVENT_CAMPAIGN_INVITE_FRIEND", { event: this.event.eventTitle, name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // BranchIO.logCustomEvent("EVENT_CAMPAIGN_INVITE_FRIEND", { event: this.event.eventTitle, name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // moengage.logCustomEvent('EVENT_CAMPAIGN_INVITE_FRIEND', { 'User Id': this.userInfo.userId, event: this.event.eventTitle, name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN })
        this.share()
    }

    share = async () => {
        // try {
        //     const result = await Share.share({
        //         title: 'Mpl event',
        //         message: this.staticText.inviteMessage,
        //     });
        // } catch (error) {
        // }

    };

    getServerTime = (payload = {}) => {

        CommonApi.getServerTime(payload).then((data) => {



            if (data && data.time) {
                let remainingTime = this.getRemaningTime(new Date(this.event.campaignDate), new Date(data.time))

                if (remainingTime > 0) {
                    this.state.remainingTime = remainingTime
                    this.getTimeReducerForMplEvent()
                }
            }

            this.setState({ loading: false })
        }).catch(err => {
            if (err) {
                this.setState({ loading: false })
                return
            }
        })
    }

    close = () => {
        this.props.navigation.navigate("LandingScreen")
    }

    toggleLoading(value) {
        this.setState({ loading: value })
    }

    toggleLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu')
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    getButtonText = (status) => {

        if (status == 'START')
            return this.staticText.startButton

        if (status == 'IN_PROGRESS')
            return this.staticText.resumeButton

        if (status == 'COMPLETED')
            return this.staticText.completedButton
    }

    // renderChapters = () => {

    //     let elements = []

    //     for (let i = 0; i < this.state.chapters.length; i++) {

    //         let chapter = this.state.chapters[i]

    //         elements.push(<View key={i} style={Styles.chapterContainer}>
    //             <View style={Styles.chapterLeft}>
    //                 <Text style={Styles.chapterTitle}>{chapter.title}</Text>
    //             </View>
    //             <View style={Styles.chapterRight}>
    //                 <TouchableOpacity style={Styles.smallButton} onPress={() => this.navigate(chapter)}>
    //                     <Text style={Styles.smallButtonText}>{this.getButtonText(chapter.status)}</Text>
    //                 </TouchableOpacity>
    //             </View>
    //         </View>)
    //     }

    //     return elements
    // }

    navigate = (chapter) => {

        let key = chapter.key
        let categoryThemeId = chapter.categoryThemeId
        let title = chapter.title
        let isChapterNotCompleted = chapter.status == 'IN_PROGRESS' && !chapter.isChapterNotCompleted ? false : true
        let masterDataId = chapter.masterDataId

        this.props.navigation.navigate('EloList', {
            meta: {
                chapterKey: key, title, isChapterNotCompleted, categoryThemeId, masterDataId
            }
        })
    }

    getTimeReducerForMplEvent = () => {
        this.mplEventTimer = setInterval(() => {

            if (this.state.remainingTime == 0) {
                clearInterval(this.mplEventTimer)
                return
            }

            this.setState({ remainingTime: this.state.remainingTime - 1 })

        }, 1000)
    }

    getDisplayTimeForEvent = (seconds) => {

        let d = this.state.remaningTimeForCurrentStep ? this.state.remaningTimeForCurrentStep : ""

        if (seconds) {
            d = seconds
        }

        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        let hDisplay = h > 9 ? h : "0" + h
        let mDisplay = m > 9 ? m : "0" + m
        let sDisplay = s > 9 ? s : "0" + s
        return hDisplay + ':' + mDisplay + ':' + sDisplay;
    }

    getRemaningTime = (date1, date2) => {

        date1 = new Date(date1).getTime(); // Time stamp in milliseconds;
        date2 = new Date().getTime(); // Current time in milliseconds;

        let timeDiffInSeconds = Math.floor(((date1 - date2) / 1000)) // Milliseconds to seconds

        return timeDiffInSeconds
    }

    onScrollEnd = (e) => {

        let contentOffset = e.nativeEvent.contentOffset;

        let viewSize = e.nativeEvent.layoutMeasurement;

        let pageNum = Math.floor(contentOffset.x / viewSize.width);

        this.setState({ activeIndex: pageNum })
    }

    onPressStart = () => {
        this.props.delegate.showPaidEventPayScreen && this.props.delegate.showPaidEventPayScreen({ event: this.event })
        // this.props.navigation.navigate('PaidEventPaymentScreen', {
        //     event: this.event
        // })
    }

    onClickPrepare = () => {

        let workflowId = this.event && this.event.preprationId

        if (!workflowId) {
            return;
        }

        this.props.navigation.navigate('NewEventWorkflow', {
            meta: {
                eventId: this.event.eventId,
                from: Constants.MOCK_TEST,
                refresherCourseId: workflowId,
                eventPayload: this.event,
            }
        })

    }

    onClickMockTest = () => {

    }

    getMockTestTitle = (statickText, status) => {

        if (status == "START") {
            return statickText.mockTestTitle || "TAKE A MOCK TEST"
        } else if (status == "IN_PROGRESS") {
            return statickText.mockTestResume || "RESUME MOCK TEST"
        } else if (status == "COMPLETED") {
            return statickText.mockTestResult || "MOCK TEST RESULT"
        }
    }

    handleMockTest = () => {

        let assessmentId = this.event && this.event.mockTestAssessmentId;
        let mockTest = this.event && this.event.mockTestStatus;
        if (!assessmentId || !mockTest)
            return;


        if (mockTest.status == "IN_PROGRESS") {

            this.props.navigation.navigate('AssessmentEngine', {
                meta: {
                    chapterTitle: this.event && this.event.mockTestTitle || "Mock Test",
                    isResume: true,
                    assessmentActivityId: mockTest.activityId,
                    categoryThemeId: "1",
                    assessmentId: assessmentId,
                    from: Constants.MOCK_TEST,
                    type: Constants.MOCK_TEST,
                    eventId: this.event.eventId,
                    viewAnswerTime: this.event && this.event.mockTestViewAnswerTime || null,
                    viewAnswerTimeScreenStaticText: this.event.staticText && this.event.staticText.viewAnswerTimeScreen ? this.event.staticText.viewAnswerTimeScreen : null,
                    eventPayload: this.event
                }
            })
            return
        }

        if (mockTest.status == "COMPLETED") {

            this.props.navigation.navigate('MockTestEndScreen', {
                meta: {
                    data: mockTest.activity,
                    chapterTitle: this.event && this.event.mockTestTitle || "Mock Test",
                    categoryThemeId: "1",
                    assessmentActivityId: mockTest.activityId,
                    chapterKey: "",
                    type: Constants.MOCK_TEST,
                    from: Constants.MOCK_TEST,
                    selectedChapters: [],
                    isResultAnnounced: false,
                    eventId: this.event.eventId,
                    viewAnswerTime: this.event && this.event.mockTestViewAnswerTime || null,
                    eventPayload: this.event
                }
            })

            return
        }
        this.props.navigation.navigate('MockTestStartScreen', {
            meta: {
                event: this.event,
                assessmentId,
                eventId: this.event.eventId,
                title: this.event && this.event.mockTestTitle || "Mock Test",
                isStarted: true
            }
        })
    }


    goToHomeScreen() {
        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }

    goToEventList() {
        this.props.delegate && this.props.delegate.hidePaidEventLandingScreen && this.props.delegate.hidePaidEventLandingScreen()
    }


    renderBreadcrumbForKnowMore = () => {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToEventList()} >{`Events >`}</span>
                <span className='title-1 pl-2'>{`${this.event.title}`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }



    render() {

        return (
            <div className="paid-event-landing-screen">
                <img className="header-image-size" src={getImageSource(ImageConstants.KNOW_MORE_HEADER)}></img>
                {this.renderBreadcrumbForKnowMore()}
                <div className="title">{this.staticText.headerText}</div>
                <div className="mt-1">
                    <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.state.categoryId)}></img>
                </div>
                <div className="description-text">
                    {this.staticText.description}
                </div>
                <div className="d-flex justify-content-center mt-5">
                    {!this.state.showTimer ?
                        <div className="comming-soon-text">
                            {this.staticText.commingSoonText}
                        </div> :
                        <div>
                            <span>{this.staticText.eventStartsInText}</span>
                            <div >
                                {this.state.loading ? <></> :
                                    <div >{this.getDisplayTimeForEvent(this.state.remainingTime)}</div>}
                            </div>
                        </div>
                    }

                </div>
                <div className="text-center">
                    <img className="divider-size mt-5 mb-5" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, 1)} />
                </div>

                {this.event && this.event.isPaid ?
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="paid-card d-flex justify-content-center align-items-center">
                            <div className="">
                                {this.event && this.event.secondaryLogo ? <img className="paid-logo" src={this.event.secondaryLogo}></img> :
                                    <img className="tick-icon mt-2" src={getImageSource(ImageConstants.TICK_BUTTON)}></img>}
                            </div>
                            <div className="">
                                {this.staticText.paidText}
                            </div>
                        </div>
                    </div>

                    : <>
                        <div className="sub-header-text text-center mb-4">
                            {this.staticText.subHeader}
                        </div>

                        {/* <View style={Styles.chapterWrapper}>
                        {this.renderChapters()}
                    </View> */}
                        <div className="d-flex justify-content-center align-items-center" >
                            <div className="pay-button cursor-pointer" onClick={this.onPressStart}>{this.staticText.startButton}</div>
                        </div>
                    </>}
                <img className="footer-size" src={getImageSource(ImageConstants.PAID_LANDING_SCREEN_FOOTER)} />



            </div>
        )
    }
}