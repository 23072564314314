import React from "react";
import './class-diagnostic-assessment-list.scss';
import Path from "../../routes/routes-path";
import { ImageConstants } from "../../../constants/constants";
import { getImageSource } from "../../../utility/image-source";

class ClassDiagnosticAssessmentList extends React.Component {

    state = {
        showChapterActivityList: false
    }



    goToHomeScreen() {

        return this.props.history.replace({ pathname: Path.HOME })
    }

    getClassMockTestStatus(type) {

        // this.setState({ showAssessmentStartScreen: true, type: 'CLASS_MOCK_TEST' })
        this.props.history.push({ pathname: Path.CLASS_DIAGNOSTIC_ASSESSMENT, state: { from: type } })

    }

    renderBreadcrumb() {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='pl-2 title-1'>{`Diagnostic Assessment`}</span>
            </div>
        )
    }

    render() {
        return (
            <div className="diagnostic-assessment-list">
                <div className="text-right">
                    <img className="image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                </div>
                <div className="header-title-margin-top">
                    {this.renderBreadcrumb()}
                    <div className="d-flex justify-content-between">
                        <div className="title">Diagnostic Assessment</div>
                    </div>
                    <div>
                        <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                    </div>
                </div>

                <div className="exam-prep-card mt-4" style={{ background: '#ebffdb' }}>
                    {/* <img className="case-study-bg" src={getImageSource(ImageConstants.ACTIVITY_BG)} /> */}
                    <div className="d-flex justify-content-between align-items-center cursor-pointer p-3" onClick={() => this.getClassMockTestStatus('CLASS_MOCK_TEST')}>
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                <img className="book-with-apple" src={getImageSource(ImageConstants.MOCK_TEST)} />
                            </div>
                            <div className="heading">Maths Diagnostic Assessment</div>
                        </div>
                        <div>
                            <img className="arrow-size-for-case-study" src={getImageSource(this.state.showChapterActivityList ? ImageConstants.BLUE_ARROW_ICON : ImageConstants.BLUE_ARROW_ICON)} />
                        </div>
                    </div>
                </div>
                <div className="exam-prep-card mt-4" style={{ background: '#FFF8DD' }}>

                    <div className="d-flex justify-content-between align-items-center cursor-pointer p-3" onClick={() => this.getClassMockTestStatus('SCIENCE_CLASS_MOCK_TEST')}>
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                <img className="book-with-apple" src={getImageSource(ImageConstants.MOCK_TEST)} />
                            </div>
                            <div className="heading">Science Diagnostic Assessment</div>
                        </div>
                        <div>
                            <img className="arrow-size-for-case-study" src={getImageSource(this.state.showChapterActivityList ? ImageConstants.BLUE_ARROW_ICON : ImageConstants.BLUE_ARROW_ICON)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default ClassDiagnosticAssessmentList;