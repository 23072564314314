import Axios from 'axios';
import HttpClient from './http-client';
import URL from './url';

export default class AssessmentApi {


    static getMyAssessmentInfo(payload) {

        let url = URL.BASE_URL + URL.MY_ASSESSMENT_INFO;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getMyAssessmentContent(payload) {

        let url = URL.BASE_URL + URL.MY_ASSESSMENT;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getContentList(payload) {
        let url = URL.BASE_URL + URL.CONTENT_LIST;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }


    static updateMyAsssemetActicity(payload) {
        return HttpClient.put(URL.BASE_URL + URL.MY_ASSESSMENT, null, payload);
    }


    static assessMyAssessment(payload) {
        return HttpClient.post(URL.BASE_URL + URL.MY_ASSESSMENT, null, payload);
    }

    static getMyAssessmentResult(payload) {
        let url = URL.BASE_URL + URL.MY_ASSESSMENT_ANSWER;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getLookupList(payload) {
        let url = URL.BASE_URL + URL.LOOKUP_LIST;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getDiagnosticAssessmentReport(payload) {
        let url = URL.BASE_URL + URL.DIAGNOSTIC_ASSESSMENT_REPORT;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getUserAssessmentActivity(payload) {
        let url = URL.BASE_URL + URL.USER_ASSESSMENT_ACTIVITY;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getMockTestList(payload) {
        let url = URL.BASE_URL + URL.GET_MOCK_TEST_LIST;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }



    static getChapterAssessmentQuestionSetList(payload) {

        let url = URL.BASE_URL + URL.MY_ASSESSMENT_QUESTION_SET_INFO;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }



}
