import React, { Component } from 'react';
import './table-of-contents.scss';
import { getImageSource } from '../../../utility/image-source';
import { ImageConstants, Constants } from '../../../constants/constants';
import SharedProfile from '../../../../shared-storage/profile';
import SharedStaticText from '../../../../shared-storage/static-text-store';
import ExamPrepKitApi from '../../../apis/exam-prep-kit';
import AsyncStore from '../../../../shared-storage/async-store';
import Loading from '../../common/loading/loading';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import BrowseChapterList from '../../home-component/chapter-list/chapter-list';
import Path from '../../routes/routes-path';
import PDFViewer from '../pdf-viewer/pdf-viewer';
import CheatSheet from '../cheat-sheet/cheat-sheet';
import ThingsToRemember from '../things-to-remember/things-to-remember';
import ApproachToProblemSolving from '../exam-prep-kit/approach-to-problem-solving/approach-to-problem-solving';
import PrepKitSolvedExample from '../exam-prep-kit/prep-kit-solved-example/prep-kit-solved-example';
import CommonErrorExperience from '../exam-prep-kit/common-error-experience/common-error-experience';
import PrepKitTipsAndTricksExperience from '../exam-prep-kit/tips-and-trics-experience/tips-and-trics-experience';



export default class TableOfContent extends Component {
    constructor(props) {
        super(props);
        // const { navigation } = this.props;
        // this.meta = navigation.getParam('meta');
        this.meta = this.props.metaInfo
        this.userInfo = SharedProfile.getProfile();
        // PrekitInstance.setPrepkitInstance(this)
        this.staticText = SharedStaticText.getStaticText('prepkitTableOfContents');

        // this.landingScreenInstance = LandingScreenShared.getLandingScreenInstance()
        // this.props.navigation.addListener('didFocus', (data) => {
        this.getPrepKitTableOfContentProgress()
        // });

        if (this.meta && this.meta.from && this.meta.from == 'CHAPTERS_LIST') {
            this.getUserTime()
        }
        this.state = {
            tableOfContent: [
                {
                    title: "Learn Concepts",
                    icon: getImageSource(ImageConstants.CHEAT_SHEET_ICON),
                    percentage: 0,
                    type: "LEARN_CONCEPTS"
                },
                {
                    title: "Cheat Sheet",
                    icon: getImageSource(ImageConstants.CHEAT_SHEET_ICON),
                    percentage: 0,
                    type: "CHEAT_SHEET",
                    Url: 'https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/2e255e9c-b7b0-46ae-bb68-2cb39468bdd0_Cheat%20sheet%20class%2010%20Real%20Number.improve.pdf'
                },
                {
                    title: "Things to remember",
                    icon: getImageSource(ImageConstants.THINGS_TO_REMEMBER_ICON),
                    percentage: 20,
                    type: "THINGS_TO_REMEMBER"
                },
                // {
                //     title: "Approach to problem solving",
                //     icon: getImageSource(ImageConstants.APPROACH_TO_PROBLEM_SOLVING),
                //     percentage: 30,
                //     type: "APPROACH_TO_PROBLEM_SOLVING"
                // },
                // {
                //     title: "Solved examples",
                //     icon: getImageSource(ImageConstants.SOLVED_EXAMPLES_ICON),
                //     percentage: 20,
                //     type: "SOLVED_EXAMPLES"
                // },
                {
                    title: "Common Errors",
                    icon: getImageSource(ImageConstants.COMMON_ERRORS),
                    percentage: 50,
                    type: "COMMON_ERRORS",
                    Url: 'https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/17cd2454-6227-4cbe-a170-8e7a7881cfe1_CBSE_Class%20X_Ch%201%20Real%20Numbers_Common%20Errors.pdf'
                },
                {
                    title: "Tips and Tricks",
                    icon: getImageSource(ImageConstants.TIPS_AND_TRICKS_ICON),
                    percentage: 20,
                    type: "TIPS_AND_TRICKS",
                    Url: 'https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/ef1a4cd5-9d72-4579-8e8c-59e2119bb876_CBSE_Class%20X_Ch%201_%20Real%20Numbers_Tips%20and%20Tricks.pdf'
                },
                {
                    title: "Practice Questions",
                    icon: getImageSource(ImageConstants.PRACTICE_QUESTION_ICON),
                    percentage: 20,
                    type: "PRACTICE_QUESTIONS"
                },
                {
                    title: "Mock Test",
                    icon: getImageSource(ImageConstants.MOCK_TEST_ICON),
                    percentage: 0,
                    type: "MOCK_TEST",
                    // disabled: false,
                    // disabledIcon: getImageSource(ImageConstants.MOCK_TEST_ICON_DISABLED),
                },
            ],
            overallProgress: 0,
            loading: true
        };

        if (!this.userInfo.isSubscribed) {

            // this.props.navigation.addListener("didFocus", () => {
            //     AppState.addEventListener('change', this._handleAppStateChange);
            // });

            // this.props.navigation.addListener("didBlur", () => {
            //     AppState.removeEventListener('change', this._handleAppStateChange);
            // });

        }

    }

    _handleAppStateChange = (state) => {

        // if (state == constants.APP_STATE_ACTIVE) {
        //     this.getUserTime()
        // } else if ((state == constants.APP_STATE_BACKGROUND && Platform.OS == 'android') || (state == constants.APP_STATE_IN_ACTIVE && Platform.OS == 'ios')) {
        //     this.stopTimer()
        //     clearInterval(this.timerInterval)
        // }
    }

    pauseTimer = () => {
        if (this.timerInterval) {
            this.stopTimer()
            clearInterval(this.timerInterval)
        }
    }

    resumeTimer = () => {
        this.getUserTime()

    }

    stopTimer = (type) => {

        // console.log(type,this.tick)

        let payload = {
            time: this.tick
        }

        ExamPrepKitApi.updateUserPrepkitTime(payload, (err, res) => {
            if (err) {
                return
            }

        })

    }

    getRemainingTime = () => {

        if (this.userInfo.isSubscribed) {
            return
        }

        if (this.landingScreenInstance) {

            this.remainingTime = this.landingScreenInstance.getRemainingAppTime()

            if (this.remainingTime > this.allowedPrepkitTime) {
                return 0
            }

            return this.remainingTime ? Math.ceil(this.remainingTime / 60) : 0
        }

        return 0
    }


    onClickLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu', { meta: { from: 'PREPKIT' } })
    }

    getUserTime = () => {

        let payload = {}

        // ExamPrepKitApi.getUserPrepkitTime(payload, (err, res) => {
        //     if (err) {
        //         return
        //     }
        //     console.log(res.prepkitTime, "******")

        //     this.setState({ timeRes: res })

        //     this.tick = res.prepkitTime.time
        //     this.trialUser = res.noAccessToPrepKit

        //     this.allowedPrepkitTime = res.prepkitAllowedTime

        //     let allowedTime = res.prepkitAllowedTime

        //     if (this.trialUser && this.tick == 0) {
        //         this.showTimerPopup()
        //         return
        //     }
        //     if (this.trialUser && this.tick >= allowedTime) {
        //         this.showTimeExhausted()
        //         // this.setState({showSubscriptionPage:true})
        //     } else {
        //         this.StartTimer(this.tick, allowedTime, res)
        //     }

        // })
    }

    showTimeExhausted = async () => {

        let doc = await AsyncStore.getExamPrepkitLimitInfo()

        if (doc) {
            doc = JSON.parse(doc)

            if (!doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_PREPKIT_TIME_LIMIT`]) {

                doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_PREPKIT_TIME_LIMIT`] = true

                AsyncStore.setExamPrepkitLimitInfo(doc)

                this.showTimeExhaustedPopup()
            } else {
                this.setState({ showSubscriptionPage: true })
            }
        } else {
            AsyncStore.setExamPrepkitLimitInfo({ [`${this.userInfo.mobileNumber}_${this.userInfo.userName}_PREPKIT_TIME_LIMIT`]: true })
            this.showTimeExhaustedPopup()
        }


    }

    StartTimer = (tick, allowedTime, timeRes) => {


        if (this.timerInterval) {
            clearInterval(this.timerInterval)
        }
        this.timerInterval = setInterval(() => {

            // if (this.trialUser && LandingScreen && LandingScreen.showTimeLimitPopUp()) {
            //     this.stopTimer()
            //     clearInterval(this.timerInterval)
            //     return
            // }
            if (this.trialUser && this.tick == timeRes.showPrepkitWarningMessageTime) {
                this.setState({ showToaster: true })
            }

            if (this.trialUser && this.tick >= allowedTime) {
                // FireBaseEvent.logCustomEvent(FirebaseEvents.EXAM_PREP_KIT_TRIAL_EXHAUSTED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                // Facebook.logCustomEvent(FirebaseEvents.EXAM_PREP_KIT_TRIAL_EXHAUSTED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                // BranchIO.logCustomEvent(FirebaseEvents.EXAM_PREP_KIT_TRIAL_EXHAUSTED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
                // moengage.logCustomEvent('Exam Pre Kit', { 'User Id': this.userInfo.userId, 'Trial Initiated': 'Yes', 'Trial Exhausted': 'Yes' })

                this.showTimeExhaustedPopup()
                this.stopTimer()
                clearInterval(this.timerInterval)
            } else {
                this.tick = this.tick + 1
            }

        }, 1000)
    }

    showTimerPopup = () => {


        this.setState({ showTimerPopup: true })
    }

    showTimeExhaustedPopup = () => {
        this.setState({ showTimerPopup: true, timeExhausted: true })
    }

    hideTimeExhaustedPopup = () => {
        this.setState({ showTimerPopup: false }, () => {

            // if (Platform.OS == 'ios') {
            //     setTimeout(() => {
            //         this.setState({ showSubscriptionPage: true })
            //     }, 500);
            // } else {
            //     this.setState({ showSubscriptionPage: true })
            // }

        })
    }

    hideTimerPopup = () => {

        // FireBaseEvent.logCustomEvent(FirebaseEvents.EXAM_PREP_KIT_TRIAL_INITIATED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // Facebook.logCustomEvent(FirebaseEvents.EXAM_PREP_KIT_TRIAL_INITIATED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // BranchIO.logCustomEvent(FirebaseEvents.EXAM_PREP_KIT_TRIAL_INITIATED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // moengage.logCustomEvent('Exam Pre Kit', { 'User Id': this.userInfo.userId, 'Trial Initiated': 'Yes', 'Trial Exhausted': 'No' })


        this.setState({ showTimerPopup: false }, () => {
            this.StartTimer(this.tick, this.state.timeRes.prepkitAllowedTime, this.state.timeRes)
        })
    }

    hideToaster = () => {
        this.setState({ showToaster: false })
    }

    showToaster = () => {
        this.setState({ showToaster: true })
    }

    hideSubscriptionPopUp = (noNavigate) => {

        if (this.showSubscriptionToaster)
            this.showSubscriptionToaster = false

        this.setState({ showSubscriptionPage: false }, () => {
            this.props.navigation.navigate('PrepKitChapterList', { meta: { title: "Circles" } });
        })
    }


    onPressAction = (item) => {

        if (item.disabled)
            return;

        if (item.type == 'LEARN_CONCEPTS') {
            this.onClickChapter({
                key: this.meta.chapterKey, title: this.meta.chapterTitle, categoryId: this.meta.categoryThemeId,
                chapterImage: this.meta.imageUrl, masterDataId: this.meta.masterDataId
            }, 0, null)
            // this.props.navigation.navigate("CheatSheet", { meta: { title: this.meta.chapterTitle, chapterKey: this.meta.chapterKey, categoryThemeId: this.meta.categoryThemeId } })
        }

        if (item.type == 'CHEAT_SHEET') {
            // this.openPdf(item.Url)
            this.showCheatSheet({ title: this.meta.chapterTitle, chapterKey: this.meta.chapterKey, categoryThemeId: this.meta.categoryThemeId })
            // this.props.navigation.navigate("CheatSheet", { meta: { title: this.meta.chapterTitle, chapterKey: this.meta.chapterKey, categoryThemeId: this.meta.categoryThemeId } })
        }

        if (item.type == 'THINGS_TO_REMEMBER') {
            this.showThingsToRemember({ title: this.meta.chapterTitle, chapterKey: this.meta.chapterKey, categoryThemeId: this.meta.categoryThemeId })
            // this.props.navigation.navigate("ThingsToRemember", { meta: { title: this.meta.chapterTitle, chapterKey: this.meta.chapterKey, categoryThemeId: this.meta.categoryThemeId } })
        }

        if (item.type == 'APPROACH_TO_PROBLEM_SOLVING') {
            this.showApprochToProblemSolving({ title: this.meta.chapterTitle, chapterKey: this.meta.chapterKey, categoryThemeId: this.meta.categoryThemeId })
            // this.props.navigation.navigate("ApproachToProblemSolving", { meta: { title: this.meta.chapterTitle, chapterKey: this.meta.chapterKey, categoryThemeId: this.meta.categoryThemeId } })
        }

        if (item.type == 'PRACTICE_QUESTIONS') {
            this.goToPractice()
            // this.props.navigation.navigate('PracticeMoreQuestions', { meta: { chapterKey: this.meta.chapterKey, title: this.meta.chapterTitle, showPracticedCheckBox: true, categoryThemeId: this.meta.categoryThemeId } })
        }

        if (item.type == 'COMMON_ERRORS') {
            // this.openPdf(item.Url)
            this.showCommonErrorExperience({
                title: this.meta.chapterTitle,
                chapterKey: this.meta.chapterKey,
                headerIcon: getImageSource(ImageConstants.COMMON_ERRORS),
                categoryThemeId: this.meta.categoryThemeId,
                resumeIndex: this.progressResponse && this.progressResponse.commonErrorResumeId && item.percentage != 100 ? this.progressResponse.commonErrorResumeId : 0,
                isCommonErrorCompleted: item.percentage == 100 ? true : false
            })
            // this.props.navigation.navigate("CommonErrorExperience", {
            //     meta: {
            //         title: this.meta.chapterTitle,
            //         chapterKey: this.meta.chapterKey,
            //         headerIcon: getImageSource(ImageConstants.COMMON_ERRORS),
            //         categoryThemeId: this.meta.categoryThemeId,
            //         resumeIndex: this.progressResponse && this.progressResponse.commonErrorResumeId && item.percentage != 100 ? this.progressResponse.commonErrorResumeId : 0,
            //         isCommonErrorCompleted: item.percentage == 100 ? true : false
            //     }
            // })
        }

        if (item.type == 'SOLVED_EXAMPLES') {
            this.showPrepKitSolvedExample({
                title: this.meta.chapterTitle,
                chapterKey: this.meta.chapterKey,
                headerIcon: getImageSource(ImageConstants.SOLVED_EXAMPLES_ICON),
                categoryThemeId: this.meta.categoryThemeId,
                resumeIndex: this.progressResponse && this.progressResponse.solvedExampleResumeId && item.percentage != 100 ? this.progressResponse.solvedExampleResumeId : 0,
                isSolvedExampleCompleted: item.percentage == 100 ? true : false
            })
            // this.props.navigation.navigate("PrepKitSolvedExample", {
            //     meta: {
            //         title: this.meta.chapterTitle,
            //         chapterKey: this.meta.chapterKey,
            //         headerIcon: getImageSource(ImageConstants.COMMON_ERRORS),
            //         categoryThemeId: this.meta.categoryThemeId,
            //         resumeIndex: this.progressResponse && this.progressResponse.solvedExampleResumeId && item.percentage != 100 ? this.progressResponse.solvedExampleResumeId : 0,
            //         isSolvedExampleCompleted: item.percentage == 100 ? true : false
            //     }
            // })
        }

        if (item.type == 'TIPS_AND_TRICKS') {
            // this.openPdf(item.Url)
            this.showTipsAndTricksExperience({
                title: this.meta.chapterTitle,
                chapterKey: this.meta.chapterKey,
                headerIcon: getImageSource(ImageConstants.TIPS_AND_TRICKS_ICON),
                categoryThemeId: this.meta.categoryThemeId,
                resumeIndex: this.progressResponse && this.progressResponse.tipsAndTricksResumeId && item.percentage != 100 ? this.progressResponse.tipsAndTricksResumeId : 0,
                isTipsAndTricsCompleted: item.percentage == 100 ? true : false
            })
            // this.props.navigation.navigate("TipsAndTricsExperience", {
            //     meta: {
            //         title: this.meta.chapterTitle,
            //         chapterKey: this.meta.chapterKey,
            //         headerIcon: getImageSource(ImageConstants.TIPS_AND_TRICKS),
            //         categoryThemeId: this.meta.categoryThemeId,
            //         resumeIndex: this.progressResponse && this.progressResponse.tipsAndTricksResumeId && item.percentage != 100 ? this.progressResponse.tipsAndTricksResumeId : 0,
            //         isTipsAndTricsCompleted: item.percentage == 100 ? true : false
            //     }
            // })
        }

        if (item.type == 'MOCK_TEST') {
            // this.props.navigation.navigate('StartAssessment', { meta: { chapterKey: this.meta.chapterKey, chapterTitle: this.meta.chapterTitle, categoryThemeId: this.meta.categoryThemeId, isExamPrepKit: true } })
            this.goToMockTest()
        }
    }

    onClickChapter = (chapter, index, logEvent) => {
        console.log(chapter)

        let categoryThemeId = chapter.categoryId

        if (chapter.isFinished) {
            // moengage.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": CommonUtils.getCategoryTitle(categoryThemeId), "Completed in Class": CommonUtils.getBooleanValue(true), "Chapter Status": 'Completed' })
            // FireBaseEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": chapter.title, "Chapter Category": CommonUtils.getCategoryTitle(categoryThemeId), "Completed in Class": CommonUtils.getBooleanValue(true), "Chapter Status": 'Completed' })
            // Facebook.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Completed' })
            // BranchIO.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Completed' })
        }
        else if (!logEvent) {
            // moengage.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': title, "Chapter Status": 'In Progress', "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted) })
            // FireBaseEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': chapter.title, "Chapter Status": 'In Progress', "Chapter Category": CommonUtils.getCategoryTitle(categoryThemeId), "Completed in Class": CommonUtils.getBooleanValue(true) })
            // Facebook.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': title, "Chapter Status": 'In Progress', "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted) })
            // BranchIO.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': title, "Chapter Status": 'In Progress', "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted) })
        } else {
            // moengage.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Start' })
            // FireBaseEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": chapter.title, "Chapter Category": CommonUtils.getCategoryTitle(categoryThemeId), "Completed in Class": CommonUtils.getBooleanValue(true), "Chapter Status": 'Start' })
            // Facebook.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Start' })
            // BranchIO.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Start' })
        }

        let eloListMeta = {
            chapterKey: chapter.key,
            title: chapter.title,
            isChapterNotCompleted: true,
            categoryThemeId: chapter.categoryId,
            masterDataId: this.state.masterDataId,
            lockedForTrial: chapter.lockedForTrial,
            trialChapters: this.chaptersOpenForTrial,
            chapterImageUrl: chapter.chapterImage,
            calledFrom: 'HOME_COMPONENT',
            selectedChapterIndex: index,
        }


        this.props.delegate && this.props.delegate.props.history.push(Path.BROWSE_CHAPTER, { data: eloListMeta })

        // this.setState({
        //     showEloList: true,
        //     selectedChapterIndex: index,
        //     eloListMeta: {
        //         chapterKey: chapter.key,
        //         title: chapter.title,
        //         isChapterNotCompleted: true,
        //         categoryThemeId: chapter.categoryId,
        //         masterDataId: this.state.masterDataId,
        //         lockedForTrial: chapter.lockedForTrial,
        //         trialChapters: this.chaptersOpenForTrial,
        //         chapterImageUrl: chapter.chapterImage
        //     }
        // })
        // this.props.navigation.navigate('EloList', { meta: { chapterKey: chapter.key, title: chapter.title, isChapterNotCompleted : true, categoryThemeId: chapter.categoryId, masterDataId: this.state.masterDataId, lockedForTrial: chapter.lockedForTrial, trialChapters: this.chaptersOpenForTrial } })
        // this.props.navigation.navigate('ChapterNavigation', { meta: { chapter: chapter } })
    }

    showThingsToRemember(data) {
        this.setState({ showThingsToRemember: true, ...data })
    }

    hideThingsToRemember() {
        this.setState({ showThingsToRemember: false }, () => {
            this.getPrepKitTableOfContentProgress()
        })

    }

    showApprochToProblemSolving(data) {
        this.setState({ showApprochToProblemSolving: true, ...data })
    }

    hideApprochToProblemSolving() {
        this.setState({ showApprochToProblemSolving: false }, () => {
            this.getPrepKitTableOfContentProgress()
        })
    }

    showPrepKitSolvedExample(data) {
        this.setState({ showPrepKitSolvedExample: true, ...data })
    }

    hidePrepKitSolvedExample() {
        this.setState({ showPrepKitSolvedExample: false }, () => {
            this.getPrepKitTableOfContentProgress()
        })
    }

    showCommonErrorExperience(data) {
        this.setState({ showCommonErrorExperience: true, ...data })
    }

    hideCommonErrorExperience() {
        this.setState({ showCommonErrorExperience: false }, () => {
            this.getPrepKitTableOfContentProgress()
        })
    }

    showTipsAndTricksExperience(data) {
        this.setState({ showTipsAndTricksExperience: true, ...data })
    }

    hideTipsAndTricksExperience() {
        this.setState({ showTipsAndTricksExperience: false }, () => {
            this.getPrepKitTableOfContentProgress()
        })
    }

    showCheatSheet(data) {
        this.setState({ showCheatSheet: true, ...data })
    }

    hideCheatSheet() {
        this.setState({ showCheatSheet: false }, () => {
            this.getPrepKitTableOfContentProgress()
        })
    }


    goToMockTest() {
        let chapterInfo = {
            chapterKey: this.meta.chapterKey,
            chapterTitle: this.meta.chapterTitle,
            calledFrom: 'EXAM_PREP_KIT'
        }
        this.props.delegate && this.props.delegate.props.history.push(Path.CLASS_SPECIFIC_ASSESSMENT, { data: chapterInfo })
    }

    goToPractice() {
        let chapterInfo = {
            chapterKey: this.meta.chapterKey,
            chapterTitle: this.meta.chapterTitle,
            calledFrom: 'EXAM_PREP_KIT',
            imageUrl: this.meta.imageUrl,
        }
        this.props.delegate && this.props.delegate.props.history.push(Path.CLASS_SPECIFIC_PRACTICE, { data: chapterInfo })
    }

    openPdf(url) {

        window.open(url)
        // window.open(eventCertificate.url, '_blank').focus();

        //this.setState({ showCertificate: true, eventCertificateDetail: eventCertificate, selectedIndex: index })
        // var a = document.createElement('A');
        // var filePath = eventCertificate.url;
        // a.href = filePath;
        // a.download = filePath.substr(filePath.lastIndexOf('/') + 1);
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);

    }

    goBack = () => {
        this.stopTimer()
        clearInterval(this.timerInterval)
        this.props.navigation.goBack();
    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    getPrepKitTableOfContentProgress = () => {

        let payload = {
            chapterKey: this.meta.chapterKey
        }

        this.setState({ loading: true })
        ExamPrepKitApi.getPrepKitTableOfContentProgress(payload).then((response) => {
            this.progressResponse = response;
            if (response.overallPercentage) {
                this.state.overallProgress = response.overallPercentage
            }

            let tableOfContent = this.state.tableOfContent;

            for (let i = 0; i < tableOfContent.length; i++) {

                let item = tableOfContent[i]

                if (item.type == 'CHEAT_SHEET') {
                    item.percentage = response.cheatSheetPercentage
                }

                if (item.type == 'COMMON_ERRORS') {
                    item.percentage = response.commonErrorsPercentage
                }

                if (item.type == 'SOLVED_EXAMPLES') {
                    item.percentage = response.solvedExamplesPercentage
                }

                if (item.type == 'MOCK_TEST') {
                    item.percentage = response.mockTestPercentage
                }

                if (item.type == 'PRACTICE_QUESTIONS') {
                    item.percentage = response.practiceQuestionsPercentage
                }

                if (item.type == 'TIPS_AND_TRICKS') {
                    item.percentage = response.tipsAndTricsPercentage
                }

                if (item.type == 'THINGS_TO_REMEMBER') {
                    item.percentage = response.thingsToRememberPercentage
                }

                if (item.type == 'APPROACH_TO_PROBLEM_SOLVING') {
                    item.percentage = response.approachToProblemSolvingPerentage
                }

            }

            this.setState({ tableOfContent, loading: false })
        }).catch(err => {
            console.log(err)
            if (err) {
                this.setState({ loading: false })
                return
            }

        })
    }



    renderTableOfContent = () => {

        let elements = []

        for (let i = 0; i < this.state.tableOfContent.length; i++) {

            let item = this.state.tableOfContent[i]

            elements.push(
                <div onClick={() => this.onPressAction(item)}
                    className='d-flex align-items-center justify-content-between chapter-card pl-0 pb-3 pt-3 cursor-pointer'>
                    <div className='d-flex align-items-center'>
                        {/* <div className='mr-2' style={{ width: '30px', height: '30px' }}>
                            <CircularProgressbar value={item.percentage && item.percentage > 0 ? item.percentage : 0}
                                strokeWidth={15}
                            />
                        </div> */}
                        <div className='d-flex align-items-center'>
                            <div className='mr-2'>
                                {
                                    item.icon ? <img className='chapter-icon' src={item.icon}></img> :
                                        <img className='chapter-icon' src={getImageSource(ImageConstants.CHAPTER_ICN)} />
                                }
                            </div>
                            <div>
                                <div className='table-of-content-title'>{item.title}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img className='arrow-image-size' src={getImageSource(ImageConstants.BLUE_ARROW_ICON)}></img>
                    </div>
                </div>
            )
        }

        return elements
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    goToExamPrepKit() {
        this.hideContents()
        this.props.delegate && this.props.delegate.hideTableOfContents && this.props.delegate.hideTableOfContents()
    }

    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToExamPrepKit()} >{`Prepare`}{` > `}</span>
                <span className='title-1 pl-2' >{`${this.meta.chapterTitle}`}</span>
            </div>
        )
    }

    showPrepKitChapterList() {
        this.hideContents()
        this.props.showPrepKitChapterList && this.props.showPrepKitChapterList()
    }

    hideContents() {
        this.hideThingsToRemember()
        this.hideApprochToProblemSolving()
        this.hidePrepKitSolvedExample()
        this.hideCommonErrorExperience()
        this.hideTipsAndTricksExperience()
        this.hideCheatSheet()
    }

    render() {


        if (this.state.showCheatSheet) {
            return <CheatSheet delegate={this}
                metaInfo={{ title: this.state.title, chapterKey: this.state.chapterKey, categoryThemeId: this.state.categoryThemeId }}
                showPrepKitChapterList={() => this.showPrepKitChapterList()} />
        }

        if (this.state.showThingsToRemember) {
            return <ThingsToRemember delegate={this}
                metaInfo={{ title: this.state.title, chapterKey: this.state.chapterKey, categoryThemeId: this.state.categoryThemeId }}
                showPrepKitChapterList={() => this.showPrepKitChapterList()} />
        }

        if (this.state.showApprochToProblemSolving) {
            return <ApproachToProblemSolving delegate={this}
                metaInfo={{ title: this.state.title, chapterKey: this.state.chapterKey, categoryThemeId: this.state.categoryThemeId }}
                showPrepKitChapterList={() => this.showPrepKitChapterList()} />
        }

        if (this.state.showPrepKitSolvedExample) {
            return <PrepKitSolvedExample delegate={this}
                metaInfo={{
                    title: this.state.title,
                    chapterKey: this.state.chapterKey,
                    headerIcon: this.state.headerIcon,
                    categoryThemeId: this.state.categoryThemeId,
                    resumeIndex: this.state.resumeIndex,
                    isSolvedExampleCompleted: this.state.isSolvedExampleCompleted
                }}
                showPrepKitChapterList={() => this.showPrepKitChapterList()} />
        }

        if (this.state.showCommonErrorExperience) {
            return <CommonErrorExperience delegate={this}
                metaInfo={{
                    title: this.state.title,
                    chapterKey: this.state.chapterKey,
                    headerIcon: this.state.headerIcon,
                    categoryThemeId: this.state.categoryThemeId,
                    resumeIndex: this.state.resumeIndex,
                    isCommonErrorCompleted: this.state.isCommonErrorCompleted
                }}
                showPrepKitChapterList={() => this.showPrepKitChapterList()} />
        }

        if (this.state.showTipsAndTricksExperience) {
            return <PrepKitTipsAndTricksExperience delegate={this}
                metaInfo={{
                    title: this.state.title,
                    chapterKey: this.state.chapterKey,
                    headerIcon: this.state.headerIcon,
                    categoryThemeId: this.state.categoryThemeId,
                    resumeIndex: this.state.resumeIndex,
                    isTipsAndTricsCompleted: this.state.isTipsAndTricsCompleted
                }}
                showPrepKitChapterList={() => this.showPrepKitChapterList()} />
        }






        return (
            <>
                {
                    !this.state.loading ?
                        <div className='prep-kit-table-of-contents mb-5'>
                            <div className="text-right">
                                <img className="header-image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                            </div>
                            {
                                this.renderBreadcrumbForChapterList()
                            }
                            <div className='d-flex align-items-center '>
                                <div className='title'>{this.meta.chapterTitle}</div>
                                <div className='pl-3'>Prepare</div>
                            </div>
                            <div className='mt-2'>
                                <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                            </div>
                            <div>
                                <div className='d-flex mt-3 align-items-center'>
                                    <div className='chapter-progress-bar'>
                                        <div className='chapter-inner-progress' style={{ width: `${this.state.overallProgress}%` }}>
                                        </div>
                                    </div>
                                    <div className='ml-2 progress-value'>{`${this.state.overallProgress}%`}</div>
                                </div>
                            </div>

                            <div className='mt-3'>{this.staticText.tableOfContentsText ? this.staticText.tableOfContentsText : "Table of Content"}</div>

                            <div className=''>
                                {this.renderTableOfContent()}
                            </div>

                            {/* <div className='notes-wrapper mt-4 mb-5'>
                                
                                <div>
                                    <div className='notes-text'>
                                        {this.staticText.noteText ? this.staticText.noteText : "You need to complete all the section to mark this step completed"}
                                    </div>
                                </div>
                            </div> */}

                        </div>

                        :
                        <Loading />
                }

                {/* {this.state.showTimerPopup && <Timerpopup delegate={this} timeExhausted={this.state.timeExhausted} appTime={this.getRemainingTime()} />}

                {this.state.showSubscriptionPage ? <SubscriptionPopUp
                    navigation={this.props.navigation}
                    showPopup={true} delegate={this}
                    nextDayStartsIn={this.state.nextDayStartsIn}
                    showTimer={this.state.showTimerInDailyLimit}
                    close={this.hideSubscriptionPopUp}
                    showHighlightedPlan={true}
                    meta={{ showBlur: this.state.showBlur, showToaster: this.showSubscriptionToaster }}
                /> : <></>
                }

                {this.state.showToaster ? <TimeToaster showModal={this.state.showToaster} delegate={this} /> : <></>} */}

            </>

        )
    }

}


