import React from "react";
import './view-answer-timer.scss';
import SharedProfile from "../../../../shared-storage/profile";
import SharedStaticText from "../../../../shared-storage/static-text-store";
import { getImageSource } from "../../../utility/image-source";
import { Constants, ImageConstants } from "../../../constants/constants";
import CommonUtils from "../../../utility/common-utilities";
import CommonApi from "../../../apis/common";
import AsyncStore from "../../../../shared-storage/async-store";
import Path from "../../routes/routes-path";

class ViewAnswerTimer extends React.Component {

    state = {
    };

    constructor(props) {
        super(props);

        this.userInfo = SharedProfile.getProfile();

        this.crossLinkStaticText = SharedStaticText.getStaticText('crossLinking');

        this.getCrossLinkingContents()

        // if (this.props.selectedType === 'ScoreBoard') {

        //     this.getUserExamPrepkitData()

        // }
    }

    // getUserExamPrepkitData = () => {

    //     let payload = {}

    //     APIS.getUserExamPrepkitData(payload, (err, res) => {

    //         if (err) {
    //             this.setState({ totalUsersStartedPrepkit: 10 })
    //             return;
    //         }

    //         if (res && res.data && res.data.length) {
    //             let userExamPrepkitData = res.data[0]

    //             this.setState({ userExamPrepkitData, totalUsersStartedPrepkit: res.totalUsers ? res.totalUsers : 10 })

    //         } else {
    //             this.setState({ totalUsersStartedPrepkit: res.totalUsers ? res.totalUsers : 10 })
    //         }



    //     })


    // }


    getDisplayTime = (time) => {

        if (!time)
            return ''

        let [hours, minutes, seconds] = time.split(':')

        hours = Number(hours)
        minutes = Number(minutes)
        seconds = Number(seconds)

        return `${hours > 12 ? hours % 12 : hours}:${minutes < 10 ? '0' + minutes : minutes} ${hours >= 12 ? 'PM' : 'AM'}`
    }

    getDisplayText = () => {

        if (this.props.viewAnswerTimeScreenStaticText && this.props.viewAnswerTimeScreenStaticText) {
            if (this.props.selectedType === 'ScoreBoard') {
                let stringyFyText = JSON.stringify(this.props.viewAnswerTimeScreenStaticText)

                let text = JSON.parse(CommonUtils.timeParsar(stringyFyText, this.userInfo.timeZone))
                return CommonUtils.simpleTemplateEngine(text.leaderboardText, { time: this.getDisplayTime(this.props.viewAnswerTime) })
            } else {
                return CommonUtils.simpleTemplateEngine(this.props.viewAnswerTimeScreenStaticText.text, { time: this.getDisplayTime(this.props.viewAnswerTime) })
            }
        }

        else if (this.props.selectedType === 'ScoreBoard') {
            return `Scoreboard will be available after ${this.getDisplayTime(this.props.viewAnswerTime)}`
        } else {
            return `Answers will be available after ${this.getDisplayTime(this.props.viewAnswerTime)}`
        }


    }

    getCrossLinkingContents = () => {


        CommonApi.getMiscellaneousList({ type: 'CROSS_LINKING_CONTENTS' }).then((data) => {

            if (data) {
                let res = data.response ? data.response : [];

                let contentList = res.length ? res[0] : {};

                this.setState({ list: contentList.content }, () => {
                    this.getContent();
                })
            }
        }).catch(err => {
            if (err) {
                this.setState({ loading: false })
                return
            };
        })
    }

    async getContent() {
        let doc = await AsyncStore.getLastCrossLinkStored();

        let index = 1

        if (doc) {
            doc = JSON.parse(doc)

            if (!doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${Constants.SCOREBOARD_LIST_SCREEN}`]) {

                doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${Constants.SCOREBOARD_LIST_SCREEN}`] = 2

                AsyncStore.setLastCrossLinkStored(doc)

                index = 1

            } else {

                let lastShowedIndex = doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${Constants.SCOREBOARD_LIST_SCREEN}`]

                if (lastShowedIndex == this.state.list.length) {
                    index = 0
                    doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${Constants.SCOREBOARD_LIST_SCREEN}`] = 1
                } else {
                    index = lastShowedIndex
                    doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${Constants.SCOREBOARD_LIST_SCREEN}`] = lastShowedIndex + 1
                }

                AsyncStore.setLastCrossLinkStored(doc)
            }
        } else {
            AsyncStore.setLastCrossLinkStored({ [`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${Constants.SCOREBOARD_LIST_SCREEN}`]: 2 })
            index = 1
            this.setState({ showBookMarkHelp: true })
        }
        // Hardcoded to show only Practice if you change it to index then it will be dynamic
        //let content = this.state.list[index]
        let content = this.state.list[2]
        this.setState({ content })
    }

    navigate = (navigation, type) => {

        switch (type) {

            // case "WORKOUT":
            //     this.getWorkoutStatus(navigation);
            //     break;

            // case 'BROWSE CHAPTER':
            //     this.navigateToChapterList();
            //     break;

            case 'PRACTICE':
                this.onClickPractice();
                break;

        }


    }

    navigateToChapterList = () => {

        // FireBaseEvent.logCustomEvent(FirebaseEvents.CROSS_BROWSE_CHAPTER, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // Facebook.logCustomEvent(FirebaseEvents.CROSS_BROWSE_CHAPTER, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // BranchIO.logCustomEvent(FirebaseEvents.CROSS_BROWSE_CHAPTER, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // moengage.logCustomEvent('CROSS_BROWSE_CHAPTER', { 'User Id': this.userInfo.userId, 'Source': 'View Answers' })
        this.props.close();

        this.props.navigation.navigate('Chapters');

    }

    onClickPractice = () => {

        // FireBaseEvent.logCustomEvent(FirebaseEvents.CROSS_PRACTICE, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // Facebook.logCustomEvent(FirebaseEvents.CROSS_PRACTICE, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // BranchIO.logCustomEvent(FirebaseEvents.CROSS_PRACTICE, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // moengage.logCustomEvent('CROSS_PRACTICE', { 'User Id': this.userInfo.userId, 'Source': 'View Answers' })
        this.setState({ showSelectPracticeChapter: true }, () => {

        })
        this.props.close(true);
    }

    hideSelectPracticeChapter = () => {
        this.setState({ showSelectPracticeChapter: false })
    }

    getWorkoutStatus = (navigation, callback) => {

        // FireBaseEvent.logCustomEvent(FirebaseEvents.CROSS_WORKOUT_STARTED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // Facebook.logCustomEvent(FirebaseEvents.CROSS_WORKOUT_STARTED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // BranchIO.logCustomEvent(FirebaseEvents.CROSS_WORKOUT_STARTED, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // moengage.logCustomEvent('CROSS_WORKOUT', { 'User Id': this.userInfo.userId, 'Source': 'View Answers' })

        // API.getSubscribedWorkoutStatus({}, (err, response) => {

        //     if (err) {
        //         callback && callback()
        //         return
        //     }

        //     callback && callback()

        //     let status = response.status

        //     this.props.close();

        //     if (status == 'COMPLETED') {
        //         this.props.navigation.navigate('LandingScreen')
        //         return
        //     }

        //     if (status == 'SETUP') {
        //         navigation.navigate('WorkoutSetupScreen');
        //     }

        //     if (status == 'START' || status == 'COMPLETED') {
        //         navigation.navigate('WorkoutStartScreenSubscribed');
        //     }

        //     if (status == 'RESUME') {
        //         let workout = response

        //         let nodes = workout.nodes.map(node => ({ ...node, id: node._id }))

        //         let resumeNodeStatus = ''

        //         for (let i = 0; i < workout.nodes.length; i++) {
        //             if (workout.nodes[i]._id == workout.resumeNodeId) {
        //                 resumeNodeStatus = workout.nodes[i].status
        //                 break;
        //             }
        //         }

        //         navigation.navigate('WorkoutExperience', {
        //             params: {
        //                 nodeId: workout.resumeNodeId, eloIds: [], nodes: nodes, title: workout.chapterTitle, chapterKey: workout.chapterKey, categoryThemeId: workout.workoutThemeId,
        //                 isChapterNotCompleted: workout.isChapterNotCompletedFlow, conceptListMeta: {}, masterDataId: null, workoutId: workout.id, totalDuration: workout.totalDuration ? workout.totalDuration : 0,
        //                 isSubscribedFlow: true, status: resumeNodeStatus ? resumeNodeStatus : ''
        //             }
        //         });
        //     }
        // });
    }

    getCrossLinkImage = (type) => {

        switch (type) {

            case 'WORKOUT':
                return getImageSource(ImageConstants.PRACTICE_CROSS_LINK)

            case 'BROWSE CHAPTER':
                return getImageSource(ImageConstants.PRACTICE_CROSS_LINK)

            case 'PRACTICE':
                return getImageSource(ImageConstants.PRACTICE_CROSS_LINK)

            default:
                return getImageSource(ImageConstants.PRACTICE_CROSS_LINK)
        }
    }

    navigateToPrepkit = () => {

        this.props.close();

        let event = this.state.event


        let percentage = 0
        let predictedScore = 0

        if (event && event.event.status == 'COMPLETED') {

            percentage = event.event.activity && event.event.activity.percentage ? event.event.activity.percentage : 5
            predictedScore = event.event.activity && event.event.activity.predictedScore ? event.event.activity.predictedScore : 5

        }

        if (this.userInfo && this.userInfo.prepkitGuaranteeAssessmentActivity && this.userInfo.prepkitGuaranteeAssessmentActivity.percentage) {
            percentage = this.userInfo.prepkitGuaranteeAssessmentActivity.percentage ? this.userInfo.prepkitGuaranteeAssessmentActivity.percentage : 5
        }

        if (this.state.userExamPrepkitData && this.state.userExamPrepkitData.chapters && this.state.userExamPrepkitData.chapters.length) {
            this.props.navigation.navigate('PrepKitChapterList', { meta: { title: "Circles" } });

        } else if (this.state.userExamPrepkitData && (!this.state.userExamPrepkitData.chapters || !this.state.userExamPrepkitData.chapters.length)) {
            this.props.navigation.navigate('ExamPrepKitChapterSelectionScreen')

        }
        // else if (this.state.userExamPrepkitData && this.state.userExamPrepkitData.chapters && this.state.userExamPrepkitData.chapters.length && !this.state.userExamPrepkitData.examDate) {
        //     this.props.navigation.navigate('ExamDateCaptureScreen')
        // }
        else {
            this.props.navigation.navigate('ExamPrepKitIntroScreen', { meta: { from: "LANDING_SCREEN", percentage: percentage } });
        }

    }

    goToHomeScreen() {
        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }

    goToEventList() {
        this.props.goToEventList && this.props.goToEventList()
    }

    onClose = () => {
        
            this.props.hideViewAnswerTimer && this.props.hideViewAnswerTimer()

    }



    renderBreadcrumbForKnowMore = () => {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToEventList()} >{`Events >`}</span>
                {/* <span className='title-1 pl-2'>{`${this.props.chapterTitle}`}</span> */}
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.onClose()}>{`Assessment Completed`} {`>`}</span>
                <span className='active pl-2'>{`View Answers`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }





    render() {

        //console.log(this.state.content)

        return (
            <div className="view-answer-timer">
                <img className="header-image-size" src={getImageSource(ImageConstants.KNOW_MORE_HEADER)}></img>
                {this.renderBreadcrumbForKnowMore()}
                <div className="text-center">
                    <img className="notes-image" src={getImageSource(ImageConstants.NOTES_IMAGE)} />
                </div>
                <div className="timer-text text-center">
                    {this.getDisplayText()}
                </div>

                {
                    this.state.content ?
                        <div className="gray-divider mt-4"></div>
                        :
                        <></>
                }



                {this.state.content ?
                    <div className="">
                        <div className="mt-3 d-flex align-items-center">
                            <img className="bulb-image mr-3" src={getImageSource(ImageConstants.YELLOW_BULB_IMAGE)} />
                            <span className="did-you-know-text d-flex align-items-center">{this.crossLinkStaticText.didYouKnowText ? this.crossLinkStaticText.didYouKnowText : "Did you know ?"}</span>
                        </div>

                        <div className="row m-0 p-0 mt-4 cross-detail-wrapper align-items-center" onPress={() => this.navigate(this.props.navigation, this.state.content.type)} style={{ backgroundColor: this.state.content.backgroundColor ? this.state.content.backgroundColor : '#F7F5FF' }}>
                            <div className="col-2">
                                <img className="cross-linking-image" src={this.getCrossLinkImage(this.state.content.type)}></img>
                            </div>
                            <div className="col">
                                <div className="description-text">{this.state.content.description}</div>
                                <div className="mt-3 practice-button d-flex justify-content-center" style={{ background: this.state.content.buttonColor ? this.state.content.buttonColor : '#6B52C8' }}>{this.state.content.buttonTitle}</div>
                            </div>

                        </div>
                    </div>
                    :
                    <></>
                }

            </div>
        )

    }



}


export default ViewAnswerTimer;