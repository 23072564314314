import React, { Component } from 'react';
import Question from '../question/question';

class CorrectAnswer extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        content: {},
        stepIndex: 0,
        reloadQuestion: true
    };

    componentWillMount() {
        //console.log(this.props.isCorrect)
        if (this.props.content) {

        }
    }

    componentWillUpdate(nextProps, nextState) {

    }

    next() {
        // Commented the inside next step going in view correct answer
        // if (this.props.content.content.isMultiStep && this.props.content.content.steps.length - 1 != this.state.stepIndex) {
        //     //console.log('inside the next of multi step')
        //     this.setState({ stepIndex: this.state.stepIndex + 1, reloadQuestion : false}, () => {
        //         this.setState({reloadQuestion : true})
        //     });
        //     return;
        // }
        this.props.delegate && this.props.delegate.nextFromCorrectAnswer && this.props.delegate.nextFromCorrectAnswer()
    }


    render() {

        let content = this.props.content;

        return (
            <div>
                {
                    this.state.reloadQuestion ?
                        <Question delegate={this}
                            content={content}
                            stepIndex={this.state.stepIndex}
                            isReadOnly={true}
                            categoryThemeId={this.props.categoryThemeId}
                            isFromViewCorrectAnswer = {true}
                            userAnswerStatus = {this.props.isCorrect}
                            userAnswer = {this.props.userAnswerForReadOnly} >
                            
                        </Question>
                        :
                        <></>
                }

            </div>
        );
    }
}

export default CorrectAnswer;
