import React from 'react';
import './sideBar.scss'
import SessionCache from '../../home/components/routes/session-cache';
import Services from '../../service/service'
import SharedProfile from '../../shared-storage/profile';
import Path from '../../home/components/routes/routes-path';
import { getImageSource } from '../../home/utility/image-source';
import { ImageConstants } from '../../home/constants/constants';
import LayoutShared from '../../home/components/common/layout-shared/layout-shared';

export default class SideBar extends React.Component {
    state = {
        user: {},
        activeIndex: 0,
        changeSideBarView: this.props.changeSideBarView || false
    }
    constructor(props) {
        super(props)

        //console.log(this.props.routes, this.props.location.pathname)

        if (this.props.location.pathname) {
            let pathIndex = 0

            for (let i = 0; i < this.props.routes.length; i++) {

                if (this.props.location.pathname == this.props.routes[i].path) {
                    pathIndex = i
                    break
                }
            }

            this.state.activeIndex = pathIndex
        }
        Services.setSideMenu(this)
        this.layoutShared = LayoutShared.getLayout()
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            if (this.props.routes && this.props.routes.length) {
                let idx = this.props.routes.findIndex(r => r.path === location.pathname);
                if (idx > -1) this.setState({ activeIndex: idx })
            }
        });
        this.checkUserAccess()
    }

    componentWillUnmount() {
        this.unlisten();
    }

    checkUserAccess() {

        let user = SharedProfile.getProfile()

        //console.log(user)

        // let schoolProfileConfirmed = user.schoolInfo?.isConfirmed
        // let totalSections = user.schoolInfo?.totalSections
        // let enableAll = false
        // if (schoolProfileConfirmed && totalSections) {
        //     enableAll = true
        // }
        this.setState({ user })
    }

    onClickRoutes = (index, route, sIndex) => {
        //console.log(route)
        if (route.path) {
            if (!route.disabled || this.state.enableAll) {
                this.props.history.push(route.path)
                this.setState({ activeIndex: index })
                this.setState({ activeSubMenuIndex: sIndex })
            }
        }
        console.log(route)
        this.setState({ changeSideBarView: true }, () => {
            this.layoutShared.toggleSidebar(true)
        })
    }

    refreshSideMenu = () => {
        this.checkUserAccess()
    }

    toggleSideMenu() {
        this.setState({ changeSideBarView: !this.state.changeSideBarView }, () => {
            this.layoutShared.toggleSidebar(this.state.changeSideBarView)
        })

    }

    render() {
        const pathName = this.props.location?.pathname
        return (
            <div  className='side-bar-v2'>
                <div className='side-bar-change-button d-flex align-items-center justify-content-center cursor-pointer'
                    onClick={() => this.toggleSideMenu()}>
                    <img className='arrow-image-size' src={getImageSource(this.state.changeSideBarView ? ImageConstants.RIGHT_ARROW_ORGANGE : ImageConstants.LEFT_ARROW_ORGANGE)} />
                </div>
                <div id="scroll-1" className='container'>

                    <div className='profile-container'>
                        <div className='profile-image-wrapper'>
                            <div className={` d-flex justify-content-center align-items-center
                                ${this.state.changeSideBarView? 'profile-image-2' : 'profile-image'}`}>
                                <div>
                                    <img onClick={() => this.props.history.push(Path.MY_PROFILE)}
                                        src={`${this.state?.user?.image ? ('https://countingwell-user-profile-pic-prod.s3.ap-south-1.amazonaws.com/' + this.state?.user?.image) : `https://cfr.annauniv.edu/research/images/anna.png`}`}
                                        className={`cursor-pointer ${this.state.changeSideBarView ? 'profile-image-size-2' : 'profile-image-size'}`} />
                                </div>
                                {/* <img src={`https://cfr.annauniv.edu/research/images/anna.png`} className='profile-image-size' /> */}
                            </div>
                        </div>
                        <div className='profile-title pt-2 text-center' >
                            <span className='cursor-pointer' onClick={() => this.props.history.push(Path.MY_PROFILE)}>{this.state.changeSideBarView ? "" : `My Profile`}</span>
                        </div>
                    </div>

                    {this.props.routes && this.props.routes.length ? <div className='routes-container'>

                        {/* <div className={`route-wrapper`}>
                            <div className='route-title-wrapper'><span className='route-title'></span></div>
                        </div> */}

                        {this.props.routes.map((route, index) => (
                            <>
                                {
                                    route.notShowInSideBar ?
                                        <></>
                                        :
                                        <div key={route.title} className={`menu-container`}>
                                            <div className={`cursor-pointer menu-border ${pathName === route.path ? 'active-menu-bg' : ''}`} onClick={() => this.onClickRoutes(index, route)}>
                                                <div className={`route-wrapper
                                    ${pathName === route.path ? 'active-menu' : ''}
                                    ${route.subMenus?.length > 0 ? "has-submenu" : ""}`}
                                                >
                                                    <div className={`d-flex align-items-center ${this.state.changeSideBarView ? "justify-content-center" : ""}`}>
                                                        {
                                                            pathName === route.path ?
                                                                <div><img className={`route-icon ${this.state.changeSideBarView ? "" : "mr-2"}`} src={route.activeIcon} /></div>
                                                                :
                                                                <div><img className={`route-icon ${this.state.changeSideBarView ? "" : "mr-2"}`} src={route.inActiveIcon} /></div>
                                                        }


                                                        <div className='route-title-wrapper'>
                                                            <span className={`route-title mt-1  ${route.disabled && !this.state.enableAll ? "route-title-disabled" : ""}`}>{this.state.changeSideBarView ? "" : route.title}</span>
                                                        </div>
                                                    </div>


                                                    <div className='route-sub-title-wrapper'><span className={`route-sub-title`}>{route.subTitle}</span></div>
                                                </div>
                                            </div>
                                            {route.subMenus && (
                                                <div className='sub-menu'>
                                                    {route.subMenus.map((sRoute, sIndex) => (
                                                        <div
                                                            key={sRoute.title}
                                                            className={`cursor-pointer menu-border ${pathName === sRoute.path ? 'active-menu-bg' : ''}`}
                                                            onClick={() => this.onClickRoutes(index, sRoute, sIndex)}
                                                        >
                                                            <div
                                                                className={`route-wrapper  ${sRoute.disabled && !this.state.enableAll
                                                                    ? 'route-wrapper-disabled'
                                                                    : ''}
                                                                            ${pathName === sRoute.path
                                                                        ? 'active-menu'
                                                                        : ''
                                                                    }
                                                                            ${sIndex == sRoute.subMenus?.length - 1 &&
                                                                        this.state.activeSubMenuIndex !=
                                                                        sRoute.subMenus?.length - 1
                                                                        ? ' padding-bottom-for-last-child'
                                                                        : ''
                                                                    }`}
                                                            >
                                                                <div className='route-title-wrapper'>
                                                                    <span
                                                                        className={`route-title  ${sRoute.disabled && !this.state.enableAll
                                                                            ? 'route-title-disabled'
                                                                            : ''
                                                                            }`}
                                                                    >
                                                                        {sRoute.title}
                                                                    </span>
                                                                </div>
                                                                <div className='route-sub-title-wrapper'>
                                                                    <span
                                                                        className={`route-sub-title`}
                                                                    >
                                                                        {sRoute.subTitle}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                            }


                                        </div>
                                }
                            </>

                        ))}

                        {this.state.activeIndex == this.props.routes.length - 1 ? <div className={`route-wrapper padding-bottom-for-last-child`}>
                            <div className='route-title-wrapper'><span className='route-title'></span></div>
                        </div> : <></>}

                    </div> : ""}

                </div>
            </div >
        )
    }
}