import React from 'react';
import sharedThemecategory from '../../../../shared-storage/category-theme'
import { ImageConstants } from '../../../constants/constants';
import { getImageSource } from '../../../utility/image-source';
import CwMath from '../cw-math-component/cw-math-component';
import CWSteps from '../cw-steps/cw-steps';
import CWGlossary from '../cw-glossary/cw-glossary';
import CustomImage from './custom-image-renderer/custom-image-renderer';

const Entities = require('html-entities').XmlEntities;
const entities = new Entities();


let AppStyles = {
    fontBook20: {
        fontSize: '22px',
        fontWeight: "600",
        marginBottom: '5px'
    },
    fontBook14: {
        fontSize: '14px'
    },
    fontRegular16: {
        fontSize: '16px'
    },
    fontBold20: {
        fontSize: '22px',
        fontWeight: "700"
    },
    fontItalicBook17: {
        fontSize: '17px',
        fontStyle: "italic"
    }

}

let Colors = {
    appTextColor: "#353535"
}


const one = '•';
const two = '◦';

// const screenWidth = Dimensions.get('window').width;


export default class Parser {

    stack = [];
    object = {};
    charCode = 65;
    glossary = null;
    delegate = null;
    isStudentMetrix = false;

    state = {
        currentPoint: 0
    }

    constructor(props) {
        // super(props);
        this.videoRef = React.createRef()
    }


    getNextAlphabetForBlank() {

        var res = String.fromCharCode(this.charCode);
        this.charCode++;
        return res;
    }

    getTag(text) {
        let tags = text.split(" ");

        tags = tags.filter(tag => {
            if (tag.trim().length > 0)
                return tag;
        });

        if (tags.length > 1 && tags[0] === '/')
            return (tags[0] + tags[1]);

        return tags.length > 0 ? tags[0] : null;
    }

    getAttribute(Originaltext, attName) {

        Originaltext = Originaltext.trim();

        let text = this.attributeReplacer(Originaltext)

        let indexs = [];
        let index = text.indexOf("style=");

        if (index != -1)
            indexs.push(index);

        index = text.indexOf("src=");
        if (index != -1)
            indexs.push(index);

        index = text.indexOf("data=");
        if (index != -1)
            indexs.push(index);

        index = text.indexOf("alt=");
        if (index != -1)
            indexs.push(index);

        index = text.indexOf("controls=");
        if (index != -1)
            indexs.push(index);

        index = text.indexOf("controlslist=");
        if (index != -1)
            indexs.push(index);

        index = text.indexOf("height=");
        if (index != -1)
            indexs.push(index);

        index = text.indexOf("preload=");
        if (index != -1)
            indexs.push(index);

        index = text.indexOf("width=");
        if (index != -1)
            indexs.push(index);

        index = text.indexOf("glossary-id=");
        if (index != -1)
            indexs.push(index);

        index = text.indexOf("type=");
        if (index != -1)
            indexs.push(index);

        indexs = indexs.sort((a, b) => a - b);

        index = text.indexOf(attName + "=");
        let i = 0;
        for (i = 0; i < indexs.length; i++) {
            if (index == indexs[i]) {
                break;
            }
        }

        let lastindex = indexs.length - 1 == i ? text.length - 1 : indexs[i + 1] - 2;
        return index != -1 ? Originaltext.substring(indexs[i] + attName.length + 2, lastindex).trim() : "";
    }

    attributeReplacer(text) {

        let isQuoteFound = false;
        let index = 0;

        let modifiedText = ''

        while (text.length > index) {

            if (isQuoteFound)
                modifiedText += '-'
            else
                modifiedText += text[index]

            if (text[index] == '"' && isQuoteFound) {
                modifiedText = modifiedText.slice(0, -1)
                modifiedText += '"'
                isQuoteFound = false;
            }
            else if (text[index] == '"' && !isQuoteFound)
                isQuoteFound = true;

            index++;
        }

        return modifiedText;
    }

    peek(stack) {

        if (stack.length > 0) {
            return stack[this.stack.length - 1]
        }

        return null;
    }



    getNextTag(text, index) {

        let start = -1, end = -1, tag = null, type = '', style = '', data = '', src = '', size = {}, glossaryId = '', glossaryType = '';

        while (index < text.length) {

            if (text[index] === '<') start = index;
            else if (text[index] === '>') end = index;

            if (start != -1 && end != -1) {

                let txt = text.substring(start + 1, end);
                if (txt.endsWith("/")) {
                    txt = txt.substring(0, txt.length - 1).trim();
                    type = 'start-end';
                }
                tag = this.getTag(txt);
                style = this.getAttribute(txt, 'style');
                // size = this.getSize(style);
                //  style = this.parseStyle(style);
                src = this.getAttribute(txt, 'src');
                data = this.getAttribute(txt, 'data');
                glossaryId = this.getAttribute(txt, 'glossary-id');
                glossaryType = this.getAttribute(txt, 'type');
                if (type != 'start-end')
                    type = tag.startsWith('/') ? 'end' : 'start';

                break;
            }
            index++;
        }

        return { start: start, end: end, tag: tag, type: type, src: src, style: style, data: data, size: size, childrens: [], glossaryId: glossaryId, glossaryType: glossaryType };
    }

    parseStyle(sty) {

        let tokens = sty.split(";");
        let style = {};

        for (let i = 0; i < tokens.length; i++) {

            let keyValueTokens = tokens[i].split(":");
            if (keyValueTokens.length == 2) {
                style[keyValueTokens[0].trim()] = this.size(keyValueTokens[1].trim());
            }
        }
    }



    // size(value) {
    //     return (parseInt(value.replace('px', ''), 10));
    // }

    // getSize(style) {

    //     let tokens = style.split(";");
    //     let size = {};

    //     for (let i = 0; i < tokens.length; i++) {

    //         let keyValueTokens = tokens[i].split(":");
    //         if (keyValueTokens.length == 2) {
    //             size[keyValueTokens[0].trim()] = this.size(keyValueTokens[1].trim());
    //         }
    //     }

    //     if (size['width'] && size['height']) {

    //         let widthRatio = screenWidth - wp(14);
    //         let heightRatio = ((widthRatio) / size['width']) * size['height'];

    //         size['widthRatio'] = widthRatio;
    //         size['heightRatio'] = heightRatio;
    //     }

    //     return size;
    // }

    buildJson(text, index, obj) {

        let meta = this.getNextTag(text, index);
        if (meta.tag != null) {

            if (meta.type == 'start-end') {

                let value = text.substring(obj.end + 1, meta.start);
                obj.childrens.push(value);
                obj.childrens.push(meta);
                obj.end = meta.end;
                this.buildJson(text, meta.end + 1, obj);
            }
            else if (meta.type == 'start') {
                if (obj == null) {
                    this.buildJson(text, meta.end + 1, meta);
                }
                else {
                    let value = text.substring(index, meta.start);

                    if (obj.tag != 'cwell' && value.length > 0)
                        obj.childrens.push(value);

                    obj.childrens.push(meta);
                    obj.end = meta.end;
                    this.buildJson(text, meta.end + 1, meta);
                    this.buildJson(text, meta.end + 1, obj);
                }
            }
            else {
                let lastChild = obj.childrens.length > 0 ? obj.childrens[obj.childrens.length - 1] : null;
                if (lastChild == null) {
                    let value = text.substring(obj.end + 1, meta.start);
                    if (value.trim().length > 0)
                        obj.childrens.push(value);
                    obj.end = meta.end;
                }
                else {
                    let value = text.substring(lastChild.end + 1, meta.start);
                    if (value.trim().length > 0)
                        obj.childrens.push(value);
                    obj.end = meta.end;
                }
                return obj;
            }
        }
        return meta;
    }


    decodeCW(text) {

        text = entities.decode(text);
        text = text.replace(/&lt;/g, '<')
        text = text.replace(/&gt;/g, '>')

        return text;
    }

    getBulletColor = (index) => {

        index = index % 7;

        switch (index) {
            case 0:
                return "#FFCA28"

            case 1:
                return "#EF5350"

            case 2:
                return "#80BFFF"

            case 3:
                return "#00AC93"

            case 4:
                return "#6666FF"

            case 5:
                return "#FF9501"

            case 6:
                return "#71D3CD"

            default:
                return "#6666FF"
        }
    };
    getBulletImage = (index) => {

        index = index % 7;
        // index = Math.random()
        switch (index) {
            case 0:
                return getImageSource(ImageConstants.YEELOW_BULLET)

            case 1:
                return getImageSource(ImageConstants.RED_BULLET)

            case 2:
                return getImageSource(ImageConstants.BLUE_BULLET)

            case 3:
                return getImageSource(ImageConstants.GREEN_BULLET)

            case 4:
                return getImageSource(ImageConstants.INDIGO_BULLET)

            case 5:
                return getImageSource(ImageConstants.ORANGE_BULLET)

            case 6:
                return getImageSource(ImageConstants.LIGHT_GREEN_BULLET)

            default:
                return getImageSource(ImageConstants.INDIGO_BULLET)
        }
    };

    getTickMarks = (index) => {

        index = index % 4;

        switch (index) {
            case 0:
                return <img src={getImageSource(ImageConstants.YELLOW_TICK)} style={{ height: "30px", width: "30px" }} ></img>

            case 1:
                return <img source={getImageSource(ImageConstants.RED_TICK)} style={{ height: "30px", width: "30px" }} ></img>

            case 2:
                return <img source={getImageSource(ImageConstants.BLUE_TICK)} style={{ height: "30px", width: "30px" }} ></img>

            case 3:
                return <img source={getImageSource(ImageConstants.GREEN_TICK_V3)} style={{ height: "30px", width: "30px" }} ></img>

        }
    };

    getJSXForList(obj, intentation) {

        let jsx = [];

        if (obj.tag == 'ol') {

            let tempJsx = this.getJSXForOrderedList(obj, intentation + 10, 1);
            jsx = jsx.concat(tempJsx);
        }
        else if (obj.tag == 'ul') {

            for (let i = 0; i < obj.childrens.length; i++) {
                this.state.currentPoint = i;
                let tempJsx = this.getJSXForList(obj.childrens[i], intentation + 10);
                jsx = jsx.concat(tempJsx);
            }
        }
        else if (obj.tag == 'li') {

            //console.log("li tag", obj.tag)

            let index = -1;
            let left = [];
            for (let i = 0; i < obj.childrens.length; i++) {

                let child = obj.childrens[i];
                if (typeof child == 'string' || (typeof child == 'object' && child.tag != 'ul' && child.tag != 'ol')) {
                    left.push(child);
                }
                else {
                    index = i;
                    break;
                }
            }

            let _jsx = [];
            let bullet = ((intentation / 10) % 2) == 0 ? two : one;
            for (let i = 0; i < left.length; i++) {

                if (typeof obj.childrens[i] === 'string' || obj.childrens[i] instanceof String) {

                    if (obj.childrens[i].trim().length > 0) {
                        // return React.createElement(Text, { style: { color: 'red' } }, this.decodeCW(obj.replace(/&nbsp;/g, ' ')))

                        let words = obj.childrens[i].split(" ");
                        let fontSize = !this.isStudentMetrix ? AppStyles.fontBook20 : AppStyles.fontBook14
                        let jsxArr = words.map(jsx => React.createElement("span", { style: { ...fontSize, color: Colors.appTextColor } }, this.decodeCW(jsx.replace(/&nbsp;/g, ' ')) + ' '))
                        //  _jsx.push(<Text >{jsxArr}</Text>)

                        _jsx.push(...jsxArr);
                        // return jsxArr;
                    }


                    // _jsx.push(<Text >{this.decodeCW(obj.childrens[i].replace(/&nbsp;/g, ' '))}</Text>)
                }
                else {

                    let tempArr = this.convertJsonToJsx(left[i], null, null, null, null, true);
                    _jsx.push(tempArr);
                }
            }

            if (intentation > 10) {
                jsx.push(
                    <div style={{ color: Colors.appTextColor, ...AppStyles.fontBook20, ...this.getStyleJsx(obj.style) }}>
                        <span style={{}}>{bullet + ' '}</span>
                        <div style={{}}>{_jsx}</div>
                    </div>);

            }
            else {
                jsx.push(
                    <div className='d-flex align-items-center' style={{ color: Colors.appTextColor, ...AppStyles.fontBook20, ...this.getStyleJsx(obj.style) }}>
                        {!this.isStudentMetrix ? <img style={{
                            display: "inline-block", height: '35px', width: "35px",
                            borderRadius: "2px", marginRight: "20px", marginLeft: "25px",
                            // backgroundColor: this.getBulletColor(this.state.currentPoint)
                        }} src={this.getBulletImage(this.state.currentPoint)}></img>
                            : <div style={{}}>{this.getTickMarks(this.state.currentPoint)}</div>}
                        <span style={{}}>{_jsx}</span>
                    </div>);

            }




            for (let i = index; i < obj.childrens.length; i++) {

                if (typeof obj.childrens[i] == 'object')
                    jsx.push(this.getJSXForList(obj.childrens[i], intentation + 10));
            }
        }

        return jsx;
    }

    getJSXForOrderedList(obj, intentation, ind) {

        let jsx = [];

        if (obj.tag == 'ul') {

            let tempJsx = this.getJSXForList(obj, intentation + 10);
            jsx = jsx.concat(tempJsx);
        }
        else if (obj.tag == 'ol') {

            for (let i = 0; i < obj.childrens.length; i++) {

                let tempJsx = this.getJSXForOrderedList(obj.childrens[i], intentation + 10, i + 1);
                jsx = jsx.concat(tempJsx);
            }
        }
        else if (obj.tag == 'li') {

            let index = -1;
            let left = [];
            for (let i = 0; i < obj.childrens.length; i++) {

                let child = obj.childrens[i];
                if (typeof child == 'string' || (typeof child == 'object' && child.tag != 'ul' && child.tag != 'ol')) {
                    left.push(child);
                }
                else {
                    index = i;
                    break;
                }
            }

            let _jsx = [];
            for (let i = 0; i < left.length; i++) {

                if (typeof obj.childrens[i] === 'string' || obj.childrens[i] instanceof String) {

                    if (obj.childrens[i].trim().length > 0) {
                        // return React.createElement(Text, { style: { color: 'red' } }, this.decodeCW(obj.replace(/&nbsp;/g, ' ')))

                        let words = obj.childrens[i].split(" ");
                        let jsxArr = words.map(jsx => React.createElement("span", { style: { ...AppStyles.fontRegular16, color: Colors.appTextColor } }, this.decodeCW(jsx.replace(/&nbsp;/g, ' ')) + ' '))

                        _jsx.push(...jsxArr);
                        // return jsxArr;
                    }
                    // _jsx.push(<Text >{this.decodeCW(obj.childrens[i].replace(/&nbsp;/g, ' '))}</Text>)
                }
                else {

                    let tempArr = this.convertJsonToJsx(left[i], null, null, null, null, true);
                    _jsx.push(tempArr);
                }
            }

            jsx.push(
                <div style={{ color: Colors.appTextColor, ...AppStyles.fontRegular16, ...this.getStyleJsx(obj.style) }}>
                    <span style={{}}>{ind + '. '}</span>
                    <div style={{}}>{_jsx}</div>
                </div>);
            ind++;
            for (let i = index; i < obj.childrens.length; i++) {

                if (typeof obj.childrens[i] == 'object') {
                    jsx.push(this.getJSXForList(obj.childrens[i], intentation + 10, ind));
                    ind++;
                }
            }
        }

        return jsx;
    }

    errorPlayVideo(e) {
        //console.log(e);
    }

    onPlay = (d, src) => {

        if (this.delegate) {

            if (d == true) {

                let keys = Object.keys(this.delegate.videoPlayerRefs);

                keys.map((item) => {

                    if (item != src)
                        this.delegate.videoPlayerRefs[item].pause();
                });
            }
        }
    }

    // getWidthForVideos = (isGlossaryImage, isNotesImage, isBulletImage, isSummary) => {

    //     if (isGlossaryImage) {
    //         return screenWidth - 160;
    //     }

    //     else if (isNotesImage) {
    //         return screenWidth - wp(18)
    //     }

    //     else if (isBulletImage) {
    //         return screenWidth - wp(20)
    //     }
    //     else if (isSummary) {
    //         return screenWidth - wp(22.5)
    //     }

    //     else {
    //         return screenWidth - wp(12.5)
    //     }
    // }

    // getHeightForVideos = (isGlossaryImage, isNotesImage, isBulletImage, isSummary) => {

    //     if (isGlossaryImage) {
    //         return (screenWidth - 130) * 0.50
    //     }

    //     else if (isNotesImage) {
    //         return (screenWidth - 70) * 0.56
    //     }
    //     else if (isBulletImage) {
    //         return (screenWidth - 70) * 0.56
    //     }
    //     else if (isSummary) {
    //         return (screenWidth - 100) * 0.50
    //     }
    //     else {
    //         return (screenWidth - 65) * 0.56
    //     }
    // }

    // getWidthForImages = (obj, isGlossaryImage, isNotesImage, isBulletImage, isSummary) => {

    //     if (isGlossaryImage) {
    //         return (obj.size.widthRatio - wp(18))
    //     }

    //     else if (isNotesImage) {
    //         return (obj.size.widthRatio - wp(3.75))
    //     }

    //     else if (isBulletImage) {
    //         return (obj.size.widthRatio - wp(7.5))
    //     }
    //     else if (isSummary) {
    //         return (obj.size.widthRatio - wp(8.5))
    //     }

    //     else {
    //         return obj.size.widthRatio
    //     }
    // }

    // getHeigthForImages = (obj, isGlossaryImage, isNotesImage, isBulletImage, isSummary) => {

    //     if (isGlossaryImage) {
    //         return (obj.size.heightRatio - wp(18))
    //     }

    //     else if (isNotesImage) {
    //         return (obj.size.heightRatio - wp(3.75))
    //     }

    //     else if (isBulletImage) {
    //         return (obj.size.heightRatio - wp(7.5))
    //     }

    //     else if (isSummary) {
    //         return (obj.size.heightRatio - wp(2.5))
    //     }

    //     else {
    //         return obj.size.heightRatio
    //     }
    // }


    updateTimePlayed(videoRef) {
    }

    convertJsonToJsx(obj, parent, isNotesImage, isGlossaryImage, categoryId, isBulletImage) {

        if (obj.tag == 'ul') {
            //console.log('unordered list', obj)
            let o = this.getJSXForList(obj, 0);
            //console.log(o);
            return o;
        }

        if (obj.tag == 'ol') {
            let o = this.getJSXForOrderedList(obj, 0, 1);
            //console.log(o);
            return o;
        }

        if (typeof obj === 'string' || obj instanceof String) {

            if (obj.trim().length > 0) {

                // return React.createElement(Text, { style: {...font, ...styles, paddingTop:40, color: Colors.appTextColor} }, this.decodeCW(obj.replace(/&nbsp;/g, ' ')))
                let _style = null;
                if (parent.tag == 'strong')
                    _style = AppStyles.fontBold20;
                else
                    _style = !this.isStudentMetrix ? AppStyles.fontBook20 : AppStyles.fontBook14;

                if (parent.tag == 'em')
                    _style = AppStyles.fontItalicBook17;

                if (parent.tag == 'u')
                    _style['textDecorationLine'] = 'underline';
                else
                    _style['textDecorationLine'] = 'none';

                let styles = this.getStyleJsx(parent.style);

                let words = obj.split(" ");
                //console.log(words)
                let jsxArr = words.map((jsx) => {
                    if (jsx === "") {
                        return React.createElement("span", { style: { ..._style, display: "inline-block", minWidth: "6px", color: Colors.appTextColor } }, this.decodeCW(jsx.replace(/&nbsp;/g, ' ')) + ' ')
                    } else {
                        return React.createElement("span", { style: { ..._style, color: Colors.appTextColor } }, this.decodeCW(jsx.replace(/&nbsp;/g, ' ')) + ' ')
                    }

                })

                // if (styles['textAlign']) {
                //     return <Text style={{ width: "100%", ...styles }}>{jsxArr}</Text>
                // }

                return jsxArr;
            }
            else
                return null;
        }

        let childrens = obj.childrens;
        let jsx = [];
        for (let i = 0; i < childrens.length; i++) {

            let jsxObj = this.convertJsonToJsx(childrens[i], obj, isNotesImage, isGlossaryImage, categoryId, isBulletImage);

            if (jsxObj != null) {

                if (!Array.isArray(jsxObj)) {
                    jsx.push(jsxObj);
                }
                else {
                    jsx.push(jsxObj);
                }
            }
        }

        let styles = this.getStyleJsx(obj.style);

        //console.log(obj.tag)

        switch (obj.tag) {

            case 'cwell':
                return jsx;

            case 'h1':
                return React.createElement(Text, { style: { ...styles, ...AppStyles.fontBold32 } }, jsx);

            case 'h2':
                return React.createElement(Text, { style: { ...styles, ...AppStyles.fontBold24, color: Colors.appTextColor } }, jsx);

            case 'h3':
                return React.createElement(Text, { style: { ...styles, ...AppStyles.fontBold18, color: Colors.appTextColor } }, jsx);

            case 'h4':
                return React.createElement(Text, { style: { ...styles, ...AppStyles.fontBold16, color: Colors.appTextColor } }, jsx);

            case 'h5':
                return React.createElement(Text, { style: { ...styles, ...AppStyles.fontBold13, color: Colors.appTextColor } }, jsx);

            case 'h6':
                return React.createElement(Text, { style: { ...styles, ...AppStyles.fontBold10, color: Colors.appTextColor } }, jsx);

            case 'u':
                return jsx;

            case 'em':
                return jsx;

            case 'strong':
                return jsx;

            case 'p':
                if (jsx.length && jsx[0].displayName == 'Image')
                    return React.createElement("div", { style: { ...styles, color: Colors.appTextColor } }, jsx);
                else {
                    if (obj.style) {
                        if (styles.textAlign)
                            return <div style={{ ...styles }}>{jsx}</div>
                        else
                            return jsx;
                    }
                    else
                        return jsx;
                }

            case 'div':
                return jsx;

            // case 'img':
            //     return <img
            //         style={{
            //             ...styles,
            //             maxHeight: `18rem`,
            //             maxWidth: "100%",
            //             objectFit: 'contain',
            //             //    
            //             // height:obj.size.heightRatio,
            //             // marginTop: wp(7.5),
            //             // marginBottom: wp(7.5),
            //         }}
            //         src={obj.src}
            //         categoryId={this.categoryId}
            //     />
            case 'img':
                return <CustomImage
                    styles={{
                        ...styles,
                        // width: this.getWidthForImages(obj, isGlossaryImage, isNotesImage, isBulletImage, this.isSummary),
                        // height: this.getHeigthForImages(obj, isGlossaryImage, isNotesImage, isBulletImage, this.isSummary),
                        // // height:obj.size.heightRatio,
                        width: "30rem",
                        height: "auto",
                        marginTop: '1rem',
                        marginBottom: '1rem',
                        // maxHeight: `18rem`,
                        // maxWidth: "100%",
                        objectFit: 'contain',
                    }}
                    uri={obj.src}
                    categoryId={this.categoryId}
                />

            case 'video':

                return <div style={{}}>
                    <div style={{
                        overflow: 'hidden', flex: 1, zIndex: 2, alignItems: 'center',
                        // width: this.getWidthForVideos(isGlossaryImage, isNotesImage, isBulletImage, this.isSummary), 
                        // height: this.getHeightForVideos(isGlossaryImage, isNotesImage, this.isSummary), 
                        borderWidth: 1, borderRadius: 10,
                        borderColor: (this.theme && this.theme.themeColor) ? this.theme.themeColor : Colors.appTheme.purple
                    }}>
                        <video
                            className='cursor-pointer'
                            src={obj.src}
                            controls
                            controlsList="nodownload"
                            poster={getImageSource(ImageConstants.VIDEO_POSTER)}
                            // url={obj.src}
                            // delegate={this.delegate}
                            ref={this.videoRef}
                            onTimeUpdate={() => this.updateTimePlayed()}
                            style={{
                                zIndex: 1,
                                borderRadius: '10px',
                                // backgroundColor: (this.theme && this.theme.secondaryColors.groupingDropBoxHeader) ? this.theme.secondaryColors.groupingDropBoxHeader : Colors.appTheme.purple,
                                justifyContent: 'center',
                                width: "500px",
                                height: "246px",
                                objectFit: "fill"
                            }}
                        />
                    </div>

                </div>

            case 'span':
                return React.createElement(Text, { style: { ...styles, color: Colors.appTextColor } }, jsx);

            case 's':
                return React.createElement(Text, { style: { textDecorationLine: 'line-through', ...styles, color: Colors.appTextColor } }, jsx);


            case 'cw-math':
                return <CwMath mathExp={this.decodeCW(obj.data)} style={{ fontSize: '23px', fontFamily: "Mont", fontStyle: 'normal', fontWeight: "300" }} ></CwMath>
                {/* <CWMathView latex={this.decodeCW(obj.data)} style={{ color: Colors.appTextColor, alignSelf: 'center' }}></CWMathView> */ }

                {/* </div> */ }


            case 'cw-glossary':
                return <CWGlossary data={this.glossary[obj.glossaryId] && this.glossary[obj.glossaryId]['description'] ? this.glossary[obj.glossaryId]['description'] : ''} type={obj.glossaryType} delegate={this.delegate} categoryId={this.categoryId}></CWGlossary>

            case 'cw-step':
                return <CWSteps data={obj.childrens} categoryId={this.categoryId} />

            case 'newline':
                // console.log(jsx);
                return <div className=' mb-2' style={{}}>{jsx}</div>

        }
    }

    // getJSXForListWithStyles(obj, intentation, styles) {

    //     let jsx = [];

    //     if (obj.tag == 'ol') {

    //         let tempJsx = this.getJSXForOrderedListWithStyles(obj, intentation + 10, 1, styles);
    //         jsx = jsx.concat(tempJsx);
    //     }
    //     else if (obj.tag == 'ul') {

    //         for (let i = 0; i < obj.childrens.length; i++) {

    //             let tempJsx = this.getJSXForListWithStyles(obj.childrens[i], intentation + 10, styles);
    //             jsx = jsx.concat(tempJsx);
    //         }
    //     }
    //     else if (obj.tag == 'li') {

    //         for (let i = 0; i < obj.childrens.length; i++) {
    //             if (typeof obj.childrens[i] === 'string' || obj.childrens[i] instanceof String) {

    //                 let bullet = ((intentation / 10) % 2) == 0 ? two : one;
    //                 jsx.push(<Text><Text style={{ fontSize: wp(5) }}>{bullet + ' '}</Text><Text style={styles['li']}>{this.decodeCW(obj.childrens[i])}</Text></Text>)
    //             }
    //             else {
    //                 let tempJsx = this.getJSXForListWithStyles(obj.childrens[i], intentation, styles);
    //                 jsx = jsx.concat(tempJsx);
    //             }
    //         }
    //     }

    //     return jsx;
    // }

    getJSXForOrderedListWithStyles(obj, intentation, ind, styles) {

        let jsx = [];

        if (obj.tag == 'ul') {

            let tempJsx = this.getJSXForListWithStyles(obj, intentation + 10, styles);
            jsx = jsx.concat(tempJsx);
        }
        else if (obj.tag == 'ol') {

            for (let i = 0; i < obj.childrens.length; i++) {

                let tempJsx = this.getJSXForOrderedListWithStyles(obj.childrens[i], intentation + 10, i + 1, styles);
                jsx = jsx.concat(tempJsx);
            }
        }
        else if (obj.tag == 'li') {

            for (let i = 0; i < obj.childrens.length; i++) {
                if (typeof obj.childrens[i] === 'string' || obj.childrens[i] instanceof String) {

                    jsx.push(<p style={styles['li']}>{ind + ' ' + this.decodeCW(obj.childrens[i].replace(/&nbsp;/g, ' '))}</p>)
                }
                else {
                    let tempJsx = this.getJSXForOrderedListWithStyles(obj.childrens[i], intentation, ind, styles);
                    jsx = jsx.concat(tempJsx);
                }
                ind++;
            }
        }

        return jsx;
    }

    convertJsonToJsxWithStyles(obj, styles, parent) {

        // if (obj.tag == 'ul') {
        //     let o = this.getJSXForListWithStyles(obj, 0, styles);
        //     return o;
        // }

        // if (obj.tag == 'ol') {
        //     let o = this.getJSXForOrderedListWithStyles(obj, 0, 1, styles);
        //     return o;
        // }


        // //console.log(typeof obj === 'string')

        if (typeof obj === 'string' || obj instanceof String) {

            let _style = null;
            if (parent && parent.tag == 'strong')
                _style = styles['strong'] ? styles['strong'] : '';
            else
                _style = styles['p']

            if (obj.trim().length > 0) {

                let words = obj.split(" ");
                let jsxArr = words.map(jsx => React.createElement("span", { style: { ..._style } }, this.decodeCW(jsx.replace(/&nbsp;/g, ' ')) + ' '))

                return jsxArr;
            }
            else
                return null;
        }

        // //console.log(obj)

        let childrens = obj.childrens;
        let jsx = [];
        for (let i = 0; i < childrens.length; i++) {
            let jsxObj = this.convertJsonToJsxWithStyles(childrens[i], styles, obj);
            if (jsxObj != null)
                jsx.push(jsxObj);
        }




        switch (obj.tag) {


            case 'cwell':
                return <div style={{ flexDirection: "row", flexWrap: 'wrap' }}>{jsx}</div>
            // return jsx;

            case 'h1':
                return React.createElement('span', { style: styles['h1'] }, jsx);

            case 'h2':
                return React.createElement('span', { style: styles['h2'] }, jsx);

            case 'h3':
                return React.createElement('span', { style: styles['h3'] }, jsx);

            case 'h4':
                return React.createElement('span', { style: styles['h4'] }, jsx);

            case 'h5':
                return React.createElement('span', { style: styles['h5'] }, jsx);

            case 'h6':
                return React.createElement('span', { style: styles['h6'] }, jsx);

            case 'u':
                return React.createElement('span', { style: styles['u'] }, jsx);

            case 'em':
                return React.createElement('span', { style: { fontStyle: 'italic', ...styles } }, jsx);

            case 'p':
                if (jsx.length && jsx[0].displayName == 'div') {

                    return jsx;
                }
                else
                    // //console.log(jsx)
                    return jsx;
            // return React.createElement(Text, { style: { ...styles['p']} }, jsx);

            case 'span':
                return React.createElement('span', { style: styles['span'] }, jsx);

            case 's':
                return React.createElement('span', { style: { textDecorationLine: 'line-through', ...styles['s'] } }, jsx);

            case 'strong':
                return React.createElement('span', { style: styles['strong'] }, jsx);

            case 'cw-math':
                //return <CWMathView latex={this.decodeCW(obj.data)} isBold={this.isBold} style={[{ color: Colors.appTextColor }, styles['cw-math'], { marginBottom: wp(3.75) }]}></CWMathView>
                return <CwMath mathExp={this.decodeCW(obj.data)} style={[styles['cw-math']]} ></CwMath>
            case 'newline':
                return <div className=''
                    style={{ flexDirection: 'row', flexWrap: 'wrap', width: "100%", minHeight: styles['p'].fontSize ? styles['p'].fontSize : 22, alignItems: 'center' }}>
                    {jsx}</div>

            // case 'img':
            //     return <CustomImage
            //         styles={{
            //             ...styles,
            //             width: obj.size.widthRatio - 5,
            //             height: obj.size.heightRatio,
            //             marginTop: wp(7.5),
            //             marginBottom: wp(7.5)
            //         }}
            //         uri={obj.src}
            //         categoryId={this.categoryId}
            //     />

            case 'cw-blank':
                return (
                    // <View style={{ justifyContent: "center", alignItems: 'center' }}>
                    //     <View style={[{ borderRadius: wp(5.5), width: wp(5.5), height: wp(5.5), borderWidth: 1.3, borderColor: Colors.appTextColor },
                    //     Platform.OS == 'ios' ? { marginTop: -wp(0.75), marginBottom: -wp(2.25), paddingLeft: wp(0.25), } : { marginBottom: -wp(2.75) }]}>
                    //         <View style={{ flex: 1, justifyContent: "center", alignItems: 'center' }}>
                    //             <Text style={{ color: Colors.appTextColor, ...AppStyles.fontSemiBold14 }}>{this.getNextAlphabetForBlank()}</Text>
                    //         </View>
                    //     </View>
                    //     <View style={{ justifyContent: "center", alignItems: 'center', paddingBottom: wp(3) }}>
                    //         <Text style={{ color: Colors.appTextColor }}>______</Text>
                    //     </View>
                    // </View>
                    <div className='d-inline-flex justify-content-center flex-column align-items-center' >
                        <div style={{ fontWeight: '700' }}>{this.getNextAlphabetForBlank()}</div>
                        <div style={{ marginTop: '-20px' }}>______</div>
                    </div>

                );

            // case 'cw-glossary':
            //     return <CWGlossary data={this.glossary[obj.glossaryId] && this.glossary[obj.glossaryId]['description'] ? this.glossary[obj.glossaryId]['description'] : ''} type={obj.glossaryType} ></CWGlossary>
        }
    }

    getStyleJsx(style) {

        let styleJsx = {};
        let tokens = style.split(";");

        for (let i = 0; i < tokens.length; i++) {

            let keyvalue = tokens[i].split(":");
            if (keyvalue.length > 1) {
                switch (keyvalue[0].trim()) {

                    case 'height':
                        styleJsx['height'] = parseInt(keyvalue[1].replace(/\D/g, ''));
                        break;

                    case 'width':
                        styleJsx['width'] = parseInt(keyvalue[1].replace(/\D/g, ''));
                        break;

                    case 'color':
                        styleJsx['color'] = keyvalue[1];
                        break;

                    case 'backgound-color':
                        styleJsx['backgroundColor'] = keyvalue[1];
                        break;

                    case 'text-align':
                        styleJsx['textAlign'] = this.getTextAlignJsx(keyvalue[1]);
                        // styleJsx['alignItems'] = this.getTextAlignJsx(keyvalue[1]);
                        break;
                }
            }
        }

        return styleJsx;
    }

    getTextAlignJsx(style) {

        let align = style ? style.trim() : 'left';

        switch (align) {

            case 'left':
                return 'left';
            case 'right':
                return 'right';
            case 'center':
                return 'center';
        }
    }

    alterNodes(html) {

        let index = 0;
        let _html = "";
        let position = 0;

        let fillInTheTagsStack = [];

        while (index < html.length) {

            let meta = this.getNextTag(html, index);
            fillInTheTagsStack.push(meta);
            index = meta.end + 1;
        }

        index = 0;
        while (index < fillInTheTagsStack.length) {

            if (fillInTheTagsStack[index].tag == 'cw-blank') {

                _html = _html + html.substring(position, fillInTheTagsStack[index].start) + "</p>";
                position = fillInTheTagsStack[index].start;
            }
            else if (fillInTheTagsStack[index].tag == '/cw-blank') {
                _html = _html + html.substring(position, fillInTheTagsStack[index].end + 1) + "<p>";
                position = fillInTheTagsStack[index].end + 1;
            }

            index++;
        }

        _html = _html + html.substring(position, fillInTheTagsStack[index - 1].end + 1);

        _html = _html.replace(/<p><\/p><cw-blank>/gm, "<cw-blank>");

        return _html;
    }

    alterHtmlForCustomParsing(html) {

        String.prototype.replaceAt = function (index, replacement) {
            return this.substr(0, index) + replacement + this.substr(index + replacement.length);
        };


        let stack = [];
        let pairs = [];
        let index = 0;
        let fillInTheTagsStack = [];

        while (index < html.length) {

            let meta = this.getNextTag(html, index);

            if (meta.tag == 'p') {
                stack.push(meta);
                fillInTheTagsStack.push(meta);
            }
            else if (meta.tag == '/p') {

                if (stack.length == 1) {

                    let obj = stack.pop();
                    let pair = {
                        start: obj,
                        end: meta
                    };
                    pairs.push(pair);
                    fillInTheTagsStack.push(meta);
                }
                else {
                    fillInTheTagsStack.push(meta);
                    stack.pop();
                }
            }
            else if (meta.tag == 'cw-blank' || meta.tag == '/cw-blank') {

                fillInTheTagsStack.push(meta);
            }

            index = meta.end + 1;
        }

        index = 0;
        let _html = "" + html;
        while (index < pairs.length) {

            _html = _html.replaceAt(pairs[index].start.start, '<P');
            _html = _html.replaceAt(pairs[index].end.start, '</P>');
            index++;
        }

        _html = _html.replace(/<P/gm, "<newline><p");
        _html = _html.replace(/<\/P>/gm, "</p></newline>");
        _html = this.alterNodes(_html);
        return _html;
    }


    parseLearningContent(html, delegate, glossary, isNotesImage, isGlossaryImage, categoryId, isStudentMetrix, isSummary) {

        html = html.replace(/<ul/gm, "<p><ul");
        html = html.replace(/<\/ul>/gm, "</ul></p>")

        html = html.replace(/<div/gm, "<p><div");
        html = html.replace(/<\/div>/gm, "</div></p>")

        if (html.trim().length === 0 || (!html.includes("<p") && !html.includes("<cw-"))) {
            return <div></div>
        }

        if (isStudentMetrix)
            this.isStudentMetrix = true
        else
            this.isStudentMetrix = false

        if (isSummary)
            this.isSummary = true
        else
            this.isSummary = false

        this.delegate = delegate;
        this.charCode = 65;
        this.glossary = glossary ? glossary : {};
        this.categoryId = categoryId ? categoryId : "";
        this.theme = sharedThemecategory.getThemeCategory(this.categoryId);;
        html = this.alterHtmlForCustomParsing(html);
        let xml = html.replace(/(\r\t\n|\n|\t|\r)/gm, "");
        xml = xml.replace(/^(<p>(\s|&nbsp;)*<\/p>)*/, "");
        xml = xml.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, "");
        xml = xml.replace(/&amp;/gm, "&");
        xml = xml.replace(/amp;/gm, "");
        xml = xml.replace(/&amp;/gm, "&");
        xml = xml.replace(/&amp;/gm, "&");
        xml = xml.replace(/&lsquo;/gm, "‘");
        xml = xml.replace(/&rsquo;/gm, "’");
        let res = this.buildJson(`<cwell>${xml}</cwell>`, 0, null);
        //console.log(this.glossary)
        return this.convertJsonToJsx(res, null, isNotesImage, isGlossaryImage);

    }

    parseWithStyles(html, styles, glossary, categoryId, isBold) {

        html = html.replace(/<ul/gm, "<p><ul");
        html = html.replace(/<\/ul>/gm, "</ul></p>")

        html = html.replace(/<div/gm, "<p><div");
        html = html.replace(/<\/div>/gm, "</div></p>")

        if (html.trim().length === 0 || (!html.includes("<p") && !html.includes("<cw-"))) {
            return <div></div>
        }

        this.glossary = glossary ? glossary : {};
        this.categoryId = categoryId ? categoryId : "";
        this.isBold = isBold ? true : false;
        html = this.alterHtmlForCustomParsing(html);
        this.charCode = 65;
        let xml = html.replace(/(\r\t\n|\n|\t|\r)/gm, "");
        xml = xml.replace(/^(<p>(\s|&nbsp;)*<\/p>)*/, " ");
        xml = xml.replace(/(<p>(\s|&nbsp;)*<\/p>)*$/, " ");
        xml = xml.replace(/&amp;/gm, "&");
        xml = xml.replace(/&amp;/gm, "&");
        xml = xml.replace(/&lsquo;/gm, "‘");
        xml = xml.replace(/&rsquo;/gm, "’");
        let res = this.buildJson(`<cwell>${xml}</cwell>`, 0, null);
        return this.convertJsonToJsxWithStyles(res, styles);

    }
}