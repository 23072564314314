import React from "react";
import './assessment-report.scss';
import SharedProfile from "../../../../shared-storage/profile";
import SharedStaticText from "../../../../shared-storage/static-text-store";
import { Constants } from "../../../constants/constants";
import Parser from "../../common/content-parser/content-parser";
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import EventApi from "../../../apis/event";
import Loading from "../../common/loading/loading";
import Path from "../../routes/routes-path";
import { EmailShareButton, EmailIcon } from 'react-share';
import AssessmentApi from "../../../apis/assessment";

export default class DiagnosticAssessmentReport extends React.Component {
    constructor(props) {
        super(props);


        // this.meta = {
        //     "type": "TWO_STEP_EVENT_FINAL",
        //     "activityId": "64c8ea6ae7ac031500da1db9",
        //     "title": "Countingwell Maths Scholarship    ",
        //     "eventId": "64a2ae5b35bdbef651f78837",
        //     "from": "TWO_STEP_EVENT_FINAL",
        //     "scholarshipEligibleStaticText": {},
        //     "eventPayload": {
        //         "eventId": "64a2ae5b35bdbef651f78837",
        //         "event": {
        //             "status": "COMPLETED",
        //             "startTime": "2023-07-27T07:06:00.000Z",
        //             "endTime": "2023-08-19T14:30:00.000Z",
        //             "CBSE_Class 6": {
        //                 "assessmentId": "62383fac7710814efa0115e1"
        //             },
        //             "CBSE_Class 7": {
        //                 "assessmentId": "6238400a7710814efa0115e3"
        //             },
        //             "CBSE_Class 8": {
        //                 "assessmentId": "623862cb7710814efa011631"
        //             },
        //             "CBSE_Class 9": {
        //                 "assessmentId": "62398bf07710814efa01175f"
        //             },
        //             "viewAnswerTime": "2023-08-20T10:30:00.000Z",
        //             "viewLeaderboardTime": "2023-08-24T15:30:00.000Z",
        //             "percentageCompleted": 100,
        //             "activity": {
        //                 "_id": "64c8ea6ae7ac031500da1db9",
        //                 "correct": 0,
        //                 "inCorrect": 2,
        //                 "notAttempted": 0,
        //                 "totalMark": 2,
        //                 "markScored": 0,
        //                 "percentage": 0,
        //                 "predictedScore": -5,
        //                 "timeTaken": 5
        //             },
        //             "gotFeedBack": true
        //         },
        //         "knowMore": [
        //             {
        //                 "question": "When will the results be announced ? When will the results be announced ?",
        //                 "description": "<p>Countingwell Countingwell Maths Scholarship helps you sharpen up your core Maths skills, bridge any learning gaps from your last academic year, and prepare you for the upcoming school year. 3 Winners from each grade will receive cash scholarships ranging from Rs.750-Rs.250 in the form of Amazon Gift Coupons.</p>",
        //                 "media": {
        //                     "url": "https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/3287d4e4-eb5e-474e-9e11-01a85e614d18_Group%20623561.png",
        //                     "type": "IMAGE"
        //                 },
        //                 "steps": [
        //                     {
        //                         "title": "Pay the participation fee.",
        //                         "description": ""
        //                     },
        //                     {
        //                         "title": "When will the results be announced ? When will the results be announced ?",
        //                         "description": "<p>When will the results be announced ? When will the results be announced ?</p>"
        //                     }
        //                 ],
        //                 "renderFullScreenImage": false
        //             },
        //             {
        //                 "question": "",
        //                 "description": "",
        //                 "media": {
        //                     "url": "https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/2de1dd65-a69b-495c-9d3a-c7ace5bfeaee_2.png",
        //                     "type": "IMAGE"
        //                 },
        //                 "renderFullScreenImage": true
        //             },
        //             {
        //                 "question": "",
        //                 "description": "",
        //                 "media": {
        //                     "url": "https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/108152cb-10d0-4e68-be0f-cc80e10247a1_3.png",
        //                     "type": "IMAGE"
        //                 },
        //                 "renderFullScreenImage": true
        //             },
        //             {
        //                 "question": "",
        //                 "description": "",
        //                 "media": {
        //                     "url": "https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/b013c013-851a-40db-9eac-b0bd67e10c63_Countingwell%20Math%20Scholarship%20Config.png",
        //                     "type": "IMAGE"
        //                 },
        //                 "renderFullScreenImage": true
        //             },
        //             {
        //                 "question": "",
        //                 "description": "",
        //                 "media": {
        //                     "url": "https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/4192949f-d988-4d5f-81c4-35fdfad1d553_Frame%20mock%206287W.png",
        //                     "type": "IMAGE"
        //                 },
        //                 "renderFullScreenImage": true,
        //                 "buttonText": "PAY TO REGISTER",
        //                 "actionBased": false,
        //                 "actionUrl": "https://countingwell.com/premium_subscription.html",
        //                 "isPaidEvent": true
        //             }
        //         ],
        //         "headerImage": "https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/9bcab5db-63fd-40f6-aeca-ba31b7a82d83_Frame%203.png",
        //         "reportImage": "https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/9bcab5db-63fd-40f6-aeca-ba31b7a82d83_Frame%203.png",
        //         "help": [
        //             {
        //                 "question": "What this Event is all about ?",
        //                 "description": "<p>Show your love and skill with mathematics and win cash prizes and other exciting prizes.</p>"
        //             },
        //             {
        //                 "question": "Why should you participate in this ?",
        //                 "description": "<p>Countingwell Countingwell Maths Scholarship helps you sharpen up your core Maths skills, bridge any learning gaps from your last academic year, and prepare you for the upcoming school year. 3 Winners from each grade will receive cash scholarships ranging from Rs.750-Rs.250 in the form of Amazon Gift Coupons.</p>"
        //             },
        //             {
        //                 "question": "How are winners selected ?",
        //                 "description": "<p>Scholars attempt the Countingwell Maths Scholarship on the countingwell app. The winners are selected on the basis of highest scores and shortest times to complete the test.</p>"
        //             },
        //             {
        //                 "question": "How can you participate in this Challenge ?",
        //                 "description": "<p>1: Complete this Bridge Course to prepare yourself. Start anytime from 28th June 2023, 7 AM IST.  2: Take the Final Test on 19th August 2023, anytime between 4 – 8 PM IST</p>"
        //             },
        //             {
        //                 "question": "Will I get a participation certificate and How do I download it ?",
        //                 "description": "<p>Yes, You will get participation certificate after completing the final Test. It can be downloaded from the Countingwell Maths Scholarship results screen on Countingwell App.</p>"
        //             },
        //             {
        //                 "question": "When will the results be announced ?",
        //                 "description": "<p>Results will be announced by 24th Aug 2023 05:00 PM, You can visit our website<link href='www.countingwell.com'> www.countingwell.com </link>to check the winner's names.</p>"
        //             }
        //         ],
        //         "staticText": {
        //             "eventCard": {
        //                 "headerText": "Countingwell Maths Scholarship",
        //                 "headerSubText": "",
        //                 "firstPageHeaderText": "FLC",
        //                 "firstPageHeaderSubText": "Countingwell Maths Scholarship    ",
        //                 "readyToParticipateText": "Get ready for India’s biggest Maths Mock Test",
        //                 "eventParticipantsText": "15000 students are interested in this contest",
        //                 "baselineAssessmentLive": "You can now start the Baseline Pre-Assessment",
        //                 "baselineAssessmentStartedText": "1000  students have already started",
        //                 "baselineAssessmentResumeText": "Complete the Pre-assessment to get to the next step.",
        //                 "baselineAssessmentCompletedText": "1000 students have completed this step",
        //                 "startCourseText": "Start the course now to qualify for the final test.",
        //                 "courseStartedStudentsText": "7500 students have already started the course",
        //                 "courseInstructionText": "Complete the course before 1st October 2022 to quailfy for the final test",
        //                 "courseCompletedStudentsText": "6025 students have already qualified",
        //                 "courseCompletedText": "Congratulations! You have qualified for the final test.",
        //                 "eventStartText": "6025 students have qualified for the final test",
        //                 "finalEventLiveText": "Start the final test from 7 AM IST Time on 15th September 2022",
        //                 "finalAssessmentParticipantsText": "6025 students have qualified for the final test",
        //                 "finalAssessmentResumeText": "Complete the Mock test before 7 AM IST Time on 1st April 2023",
        //                 "finalAssessmentStartedText": "6025 students are participating in this test",
        //                 "refresherDeadlineIsOver": "Qualify Course deadline is over, Sorry! You can’t proceed",
        //                 "eventDeadlineOverText": "Oops! You missed the event",
        //                 "baselineDeadlineOver": "Qualify Course deadline is over, Sorry! You can’t proceed",
        //                 "eventFinishedText": "<p><strong>You've finished this quiz. Results will be announced on 24th Aug 2023 05:00 PM.</strong></p>",
        //                 "resultAnnouncedText": "Countingwell Maths Scholarship    Results are announced Click here to view the Results",
        //                 "resultAnnouncedRunnerText": "Congratulations! You are among top <%percentage%> of students in Final Countingwell Maths Scholarship  ",
        //                 "resultAnnouncedWinnerText": "Congratulations! You are the winner of Final Countingwell Maths Scholarship    ",
        //                 "baselineAssessmentTitle": "Let’s quickly assess what you know",
        //                 "scholarshipAssessmentTitle": "Countingwell Maths Scholarship    ",
        //                 "wildCardEntryEventCompletedText": "Countingwell Maths Scholarship    - Wild Card Entry Results will be announced",
        //                 "eventFinishedTextDate": "5:00 PM, 8th July ",
        //                 "claimCashPriceUrl": "https://countingwell.com/school/students/mpl2022/claim/form",
        //                 "claimScholarshipUrl": "https://countingwell.com/",
        //                 "eventRescheduledText": "<p>Final Assessment deadline is over, Sorry! You can’t proceed</p>",
        //                 "eventWildCardFinishedText": "<p><strong>Great job! You have completed the mock test. You can continue revising for the exams with the Exam Prep Kit.</strong></p>",
        //                 "resultAnnouncedRunnerWithCashPrizeText": "Congratulations! You are among top <%percentage%> students in Countingwell Maths Scholarship  ",
        //                 "eventWildCardFinishedTextDate": "5:00 PM, 6th October",
        //                 "wildCardResultAnnouncedText": "The Wildcard results are announced",
        //                 "congratulationsText": "Results are announced. Visit www.countingwell.com to see the results.",
        //                 "wildcardCongratulationsText": "The results for the wildcard entries are in.",
        //                 "itsLiveText": "It's Live",
        //                 "amountButtonText": "Pay Rs.95/- to Take Quiz",
        //                 "resultAnnouncedButtonTitle": "View result",
        //                 "startsInText": "Starts In"
        //             },
        //             "eventLandingScreen": {
        //                 "headerText": "Countingwell Maths Scholarship    ",
        //                 "description": "",
        //                 "baselineAssessmentHeader": "Baseline Pre-Assessment",
        //                 "baselineSubText": "<p>This would take approx. <strong>15 mins</strong></p>",
        //                 "baselineSubText2": "<p>Take this quiz anytime from<strong> 08:30 AM, 23rd Jan </date> </strong></p>",
        //                 "refresherAssessmentheader": "Qualifying Course for Finals",
        //                 "refresherSubText": "This can take around 120-150 minutes in total. You can do this in parts. Spend around 20 minutes daily.",
        //                 "refresherSubText2": "<p>Start after completing the baseline assessment and complete before<strong> 01:29 AM, </strong><strong> 19th Feb to qualify for finals</strong></p>",
        //                 "scholarshipAssessmentHeader": "Final Ramanujan Maths Scholarship",
        //                 "eventSubText": "<p>This would take approx. <strong>45 mins</strong></p>",
        //                 "eventSubText2": "<p>Start anytime between <strong> 19th Feb </strong><strong> 03:30 PM </strong> - <strong> 19th Feb </strong><strong> 08:30 PM </strong></p>",
        //                 "baselineCompletedText": "This step is completed. Take the next step",
        //                 "refresherCompletedText": "Complete the course in order to prepare better for the finals",
        //                 "eventCompletedText": "You have completed the Final Challenge",
        //                 "resultWillAnnounced": [
        //                     {
        //                         "msg": "Thank You for taking up this course, Hope you found this course quite useful.",
        //                         "style": "text"
        //                     }
        //                 ],
        //                 "resultAnnouncedText": [
        //                     {
        //                         "msg": "Certification Assessment Test ",
        //                         "style": "bold"
        //                     },
        //                     {
        //                         "msg": "results are announced ",
        //                         "style": "text"
        //                     },
        //                     {
        //                         "msg": "Click here ",
        //                         "style": "link",
        //                         "url": "https://www.countingwell.com"
        //                     },
        //                     {
        //                         "msg": " to view results.",
        //                         "style": "text"
        //                     }
        //                 ],
        //                 "participationCertificate": "COURSE CERTIFICATE",
        //                 "baselineTimeUpText": "You missed baseline assessment",
        //                 "inviteText": "Invite Friends",
        //                 "courseNotQualifiedText": "You did not complete it on time",
        //                 "finalAssessmentNotQualifiedText": "You have not qualified for the Final Challenge",
        //                 "subscribeCardText": "You did not qualify for the Final Gulf Maths Challenge. Still want to take it? Premium users are not required to complete the course",
        //                 "subscribe": "Get Premium",
        //                 "qualifiedFinalAssessmentDirectly": "As a premium user, you are qualified to take the Final Gulf Maths Challenge directly.",
        //                 "courseTimeUpText": "Course timeline is finished",
        //                 "inviteMessage": "Hey! I am participating in India’s largest scholarship event: the Countingwell Ramanujan Maths Scholarship 🏆 I am excited. You can participate too and win cash scholarships. Let’s do it together 🤝 \n\nTo participate, download the Countingwell Maths Learning app and sign up from here: https://t2m3p.app.link/mmfmJXCBulb",
        //                 "leaderBoardButtonTitle": "LEADERBOARD",
        //                 "participationCertificateButtonTitle": "PARTICIPATION CERTIFICATE",
        //                 "winnersCertificateButtonTitle": "WINNER CERTIFICATE",
        //                 "viewAnswersButtonTitle": "VIEW ANSWERS",
        //                 "examPrepkitDescription": "EXAM PREP KIT is specially designed to help you score more marks in your school exams.",
        //                 "examPrepkitButtonTitle": "IMPROVE EXAM SCORE"
        //             },
        //             "scholarshipEndScreen": {
        //                 "shareButtonText": "SHARE EXCITEMENT",
        //                 "descriptionText": "I am participating in the Countingwell Summer Maths Fest. It is super fun, and I get to learn a new skill too. You can download the Countingwell app and enjoy participate too.",
        //                 "marksScoredText": "Marks Scored",
        //                 "timeTakenText": "Time Taken",
        //                 "correctText": "Correct",
        //                 "inCorrectText": "Incorrect",
        //                 "notAnsweredText": "Not Answered",
        //                 "minutesText": "mins",
        //                 "viewAnswerButtonTitle": "VIEW ANSWERS",
        //                 "viewReportButtonTitle": "VIEW DETAIL REPORT",
        //                 "assessmentCompletedText": "You’re done !!!",
        //                 "scholarshipTestName": "Countingwell Maths Scholarship   ",
        //                 "scholarshipText": "<p><strong>SCHOLARSHIP TEST</strong> results will be announced on 7th May at <strong>5pmth May</strong></p>",
        //                 "resultInfoText": " results will be announced on 25th June. Visit our website www.countingwell.com to view results.",
        //                 "resultAnnouncedText": [
        //                     {
        //                         "msg": "SCHOLARSHIP TEST ",
        //                         "style": "bold"
        //                     },
        //                     {
        //                         "msg": "results are announced ",
        //                         "style": "text"
        //                     },
        //                     {
        //                         "msg": "Click here",
        //                         "style": "link",
        //                         "url": "https://www.countingwell.com"
        //                     },
        //                     {
        //                         "msg": " to view results.",
        //                         "style": "text"
        //                     }
        //                 ],
        //                 "participationCertificate": "PARTICIPATION CERTIFICATE",
        //                 "resultWillAnnounced": [
        //                     {
        //                         "msg": "Results will be announced on 5th Aug 2023 05:00 PM.",
        //                         "style": "text"
        //                     }
        //                 ]
        //             },
        //             "schollarshipStartScreen": {
        //                 "agreeText": "By starting up I agree to the ",
        //                 "durationText": "Duration",
        //                 "instructionText": "Please have pen & paper with you before you start the Assessment",
        //                 "marksText": "Marks",
        //                 "moreProTipsText": "More Pro Tips",
        //                 "noAssessmentsText": "Sorry, No Assessments available right now",
        //                 "questionsText": "Questions",
        //                 "redirectUrl": "https://countingwell.com/cms-terms-and-condition/",
        //                 "startButtonTitle": "START",
        //                 "termsAndConditionsText": "terms and conditions",
        //                 "scheduleText": "Schedule",
        //                 "baselineSchedule": "Take this quiz anytime from 08:30 AM, 23rd Jan ",
        //                 "scholarshipEventSchedule": "Take this Challenge anytime between 03:30 PM, 19th Feb - 03:30 PM, 19th Feb ",
        //                 "eventAssessmentTip": "<p>Remember, there is a time limit for the test. The person who scores the highest in the least amount of time wins.</p>",
        //                 "baselineAssessmentTip": "<p>You have got only <strong><%time%> mins</strong> to finish this Test. Do not get stuck on one question for long time</p>",
        //                 "eventInstructionText": "<p>Remember, there is a time limit for the test. The person who scores the highest in the least amount of time wins.</p>"
        //             },
        //             "baselineEndScreen": {
        //                 "learningGapsFoundText": "We have identified following learning gaps, Continue to learn concepts",
        //                 "learningGapsNotFoundText": "Great Work! You've completed the first step of the Challenge.!!",
        //                 "strengthsText": "We tested you on all these problem-solving skills:",
        //                 "weaknessText": "AREA OF IMPROVEMENT",
        //                 "viewAnswerButtonTitle": "VIEW ANSWERS",
        //                 "continueButtonTitle": "CONTINUE"
        //             },
        //             "scollarShipEligiblePopUp": {
        //                 "headerText": "Congratulations!",
        //                 "description": "<p>Congratulations <%name%>, You are qualified to take the FINAL <strong>FINANCIAL LITERACY CHALLENGE</strong> anytime between <strong> 15th Sep </strong><strong> 07:00 AM </strong> to <strong> 1st Oct </strong><strong> 07:00 PM </strong>. We suggest you to still take up the preparation course to improve your performance in the finals.</p>"
        //             },
        //             "scollarShipEligiblePopUpPremium": {
        //                 "headerText": "Congratulations!",
        //                 "description": "<p>Congratulations <%name%>, Since you are a premium user, You are qualified right away to take the FINAL <strong>GULF MATHS CHALLENGE</strong> between <strong> 19th Feb </strong><strong> 03:30 PM </strong> - <strong> 19th Feb </strong><strong> 08:30 PM </strong>. We suggest you to still take up the preparation course to improve your performance in the finals.</p>"
        //             },
        //             "certificate": {
        //                 "title": "CERTIFICATE OF COMPLETION",
        //                 "subTitle": "MPF"
        //             },
        //             "viewAnswerTimeScreen": {
        //                 "text": "Answers will be available after 04:00 PM, 20th Aug 2023 ",
        //                 "leaderboardText": "Leaderboard will be available after 07:00 AM, 25th Jun 2023 "
        //             },
        //             "genericWorkflowScreen": {
        //                 "title": "Countingwell Maths Scholarship ",
        //                 "progressTitle": "COURSE COMPLETED",
        //                 "noWorkflowAvailable": "No Course Available",
        //                 "showCompletedElosText": "Completed Learning Outcomes",
        //                 "hideCompletedElosText": "Hide Learning Outcomes",
        //                 "qualifiedText": "You are qualified",
        //                 "learningLeftToQualifyText": "<p><strong><%time%> mins</strong>learning left to Qualify</p>"
        //             },
        //             "paidEventLandingScreen": {
        //                 "headerText": "Countingwell Maths Scholarship   ",
        //                 "description": "The Countingwell Maths Scholarship challenges and recognizes young talent in Mathematics. Bridge your learning gap by completing a refresher course on the app as part of the competition, use the learning to compete with thousands of students and win cash scholarships.",
        //                 "commingSoonText": "04 PM 19 Aug - 08 PM 19th Aug",
        //                 "subHeader": "Pay to Take QUIZ",
        //                 "startButton": "PAY TO TAKE QUIZ",
        //                 "inviteText": "Invite Friends",
        //                 "inviteMessage": "I am using Countingwell learn Maths with fun and participate in these amazing competitions. The Countingwell Maths Scholarship is super fun, and I get to learn a new skill too. You can download the Countingwell app and enjoy learning Maths too. Download it from:  \n\niOS App: https://apps.apple.com/in/app/countingwell/id1508540117 \nAndroid App: https://play.google.com/store/apps/details?id=com.cwapp.",
        //                 "url": "https://www.countingwell.com",
        //                 "paidText": "Your payment is received. You can take participation in Countingwell Maths Scholarship Test"
        //             },
        //             "assessmentReport": {
        //                 "buttonTitle": "View FLC Course",
        //                 "baselineAssessmentHeader": "FLC Baseline Assessment Report",
        //                 "baselineAssessmentText": "Baseline assessment Score",
        //                 "finalAssessmentHeader": "Countingwell Maths Scholarship Assessment Report",
        //                 "finishlineAssessmentText": "Finishline assessment Score"
        //             },
        //             "assessmentEndReportPdf": {
        //                 "baselineAssessmentHeader": "Countingwell Maths Scholarship Assessment Report",
        //                 "finalAssessmentHeader": "Countingwell Maths Scholarship Assessment Report"
        //             },
        //             "eventRewardScreen": {
        //                 "selectRewardText": "Choose Your Own Prize",
        //                 "continueButtonText": "CONTINUE",
        //                 "description": "You can pick a prize you want to win. If you are among the top 3 winners of the Gulf Maths Challenge, you will win the prize you have selected for yourself. You can change your option anytime till 18th Feb 2022. The image is for representation purposes only. Actual product may vary slightly in design and color.",
        //                 "selectButtonText": "SELECT",
        //                 "editRewardText": "Edit Reward"
        //             },
        //             "eventResultScreen": {
        //                 "viewMoreButtonText": "VIEW MORE",
        //                 "viewLessButtonText": "VIEW LESS",
        //                 "headerText": "Congratulations <%name%>",
        //                 "certificateButtonTitle": "MY CERTIFICATE",
        //                 "leaderboardButtonTitle": "Check the Winner",
        //                 "reportButtonTitle": "MY PERFORMANCE",
        //                 "topText": "TOP",
        //                 "claimScholarshipButtonText": "CLAIM YOUR DISCOUNT",
        //                 "claimCashPrizeButtonText": "CLAIM YOUR PRIZE",
        //                 "cashPrizeVideoUrl": "",
        //                 "scholarshipVideoUrl": "",
        //                 "shareButtonText": ""
        //             },
        //             "editRewardPopup": {
        //                 "selectRewardText": "Your Selected Reward",
        //                 "editRewardButtonText": "EDIT REWARD"
        //             },
        //             "feedbackScreen": {
        //                 "description": "Before viewing the results & answer sheet, Please fill this quick survey about your product experience",
        //                 "headerText": "Great! You have finished the Countingwell Maths Scholarship   Quiz !",
        //                 "buttonText": "VIEW QUIZ RESULT"
        //             },
        //             "leaderBoardScreen": {
        //                 "studentLeaderboardDisclaimerText": "Ranking is based on Scores & Time taken",
        //                 "globalTitle": "Global",
        //                 "mySchoolTitle": "My School",
        //                 "scoreboardText": "Check the Countingwell Website for Winner List",
        //                 "eventTitle": "Countingwell Maths Scholarship   ",
        //                 "minsText": "Mins",
        //                 "schoolLeaderboardDisclaimerText": "There are no prizes for school level winners",
        //                 "leaderBoardLimit": 1
        //             },
        //             "prepkitVideoScreen": {
        //                 "videoWithPrepkitGuarantee": "https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/54b7267f-d660-49c2-8e8d-ec6c64feba81_CW%20Vid%201.mp4",
        //                 "videoWithouPrepkitGuarantee": "https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/81d7c33c-61e8-4044-b269-69ef59598427_CW%20Vid%202.mp4",
        //                 "buttonTitle": "CONTINUE"
        //             },
        //             "winnersStaticText": {
        //                 "topRankHeader": "<p><strong>Congratulations</strong></p>",
        //                 "cashPriceText": [
        //                     {
        //                         "msg": "Congratulations on participating in the Countingwell Summer Fest. We are happy to offer you an amazing opportunity to take up the Countingwell Foundations of Financial Literacy course with a 40% discount. Join the acclaimed 8-module program delivered live on Zoom for just Rs.1799/- (instead of 2999/-) and develop great personal finance skills.",
        //                         "style": "text"
        //                     }
        //                 ],
        //                 "discountPriceText": [
        //                     {
        //                         "msg": "Congratulations on participating in the Countingwell Summer Fest. We are happy to offer you an amazing opportunity to take up the Countingwell Foundations of Financial Literacy course with a 40% discount. Join the acclaimed 8-module program delivered live on Zoom for just Rs.1799/- (instead of 2999/-) and develop great personal finance skills.",
        //                         "style": "text"
        //                     }
        //                 ],
        //                 "runnersText": "<p>Congratulations on participating in the Countingwell Summer Fest. We are happy to offer you an amazing opportunity to take up the Countingwell Foundations of Financial Literacy course with a 40% discount. Join the acclaimed 8-module program delivered live on Zoom for just Rs.1799/- (instead of 2999/-) and develop great personal finance skills.</p>",
        //                 "runnersWithCashPrizeText": "<p>Congratulations on participating in the Countingwell Summer Fest. We are happy to offer you an amazing opportunity to take up the Countingwell Foundations of Financial Literacy course with a 40% discount. Join the acclaimed 8-module program delivered live on Zoom for just Rs.1799/- (instead of 2999/-) and develop great personal finance skills.</p>",
        //                 "wildCardText": "<p><strong>You have completed the Countingwell Maths Scholarship    </strong></p>",
        //                 "otherRankersText": [
        //                     {
        //                         "msg": "Congratulations on participating in the Countingwell Summer Fest. We are happy to offer you an amazing opportunity to take up the Countingwell Foundations of Financial Literacy course with a 40% discount. Join the acclaimed 8-module program delivered live on Zoom for just Rs.1799/- (instead of 2999/-) and develop great personal finance skills.",
        //                         "style": "text"
        //                     }
        //                 ]
        //             },
        //             "eventQualifiedScreen": {
        //                 "congratulationsText": "Congratulations!",
        //                 "qualifiedText": "You are qualified for the final Countingwell Maths Scholarship ",
        //                 "examStartTime": "<p>Start Final anytime between <strong> 15th Sep </strong><strong> 07:00 AM </strong> to <strong> 1st Oct </strong><strong> 07:00 PM </strong></p>",
        //                 "reviseConceptsText": "Revise Concepts"
        //             },
        //             "socialMediaShareScreen": {
        //                 "shareButtonText": "SHARE",
        //                 "shareDescriptionText": "Share your excitement with your friends and family",
        //                 "shareAchievementDescriptionText": "Great show <%name%> , Share your achievement",
        //                 "shareAchievementMessage": "Great show <%name%> , Share your achievement",
        //                 "shareMessage": "Share your excitement with your friends and family",
        //                 "shareTitle": "RMS"
        //             },
        //             "paidEventPayScreen": {
        //                 "title": "This is a Paid Test",
        //                 "description": "Please go to the home screen and tap on Upgrade to Premium to pay the participation fee.  Tap the close button on the top right corner to go back to the Home Screen and make the payment.",
        //                 "amountSubText": "Price mentioned is inclusive of GST",
        //                 "buttonText": "CONTINUE TO PAY",
        //                 "amountText": "₹ 95",
        //                 "url": "https://t2m3p.app.link/cwell"
        //             }
        //         },
        //         "position": "TOP",
        //         "title": "Countingwell Maths Scholarship",
        //         "rewards": [],
        //         "viewedFinishlineReport": false,
        //         "viewedBaselineReport": false,
        //         "viewedCertificate": false,
        //         "showPrepkitCard": true,
        //         "baselineComletionPercentage": 20,
        //         "showSocialMediaSharing": true,
        //         "wildCardResultAnnounced": false,
        //         "socialMediaImageUrl": "https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/c4c094dd-6711-4c31-af22-7ec21301c07b_Frame%206275.png",
        //         "socialMediaAchievementImageUrl": "https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/7b95a55c-cee2-4a16-82af-243d9d0c06f9_Frame%206278.png",
        //         "customEligibleMessages": {
        //             "beginner": "<p>Congratulations <%name%>, You are qualified to take the FINAL <strong>FINANCIAL LITERACY CHALLENGE</strong> anytime between <strong> <date>2022-09-15T01:30:00Z</date> </strong><strong> <time>2022-09-15T01:30:00Z</time> </strong> to <strong> <date>2022-10-01T13:30:00Z</date> </strong><strong> <time>2022-10-01T13:30:00Z</time> </strong>. We suggest you to still take up the preparation course to improve your performance in the finals.</p>",
        //             "intermediate": "<p>Congratulations <%name%>, You are qualified to take the FINAL <strong>FINANCIAL LITERACY CHALLENGE</strong> anytime between <strong> <date>2022-09-15T01:30:00Z</date> </strong><strong> <time>2022-09-15T01:30:00Z</time> </strong> to <strong> <date>2022-10-01T13:30:00Z</date> </strong><strong> <time>2022-10-01T13:30:00Z</time> </strong>. We suggest you to still take up the preparation course to improve your performance in the finals.</p>",
        //             "advanced": "<p>Congratulations <%name%>, You are qualified to take the FINAL <strong>FINANCIAL LITERACY CHALLENGE</strong> anytime between <strong> <date>2022-09-15T01:30:00Z</date> </strong><strong> <time>2022-09-15T01:30:00Z</time> </strong> to <strong> <date>2022-10-01T13:30:00Z</date> </strong><strong> <time>2022-10-01T13:30:00Z</time> </strong>. We suggest you to still take up the preparation course to improve your performance in the finals.</p>"
        //         },
        //         "isCustomEligibleMessage": false,
        //         "isSingleStepEvent": true,
        //         "note": "Note: You will see a new card for every competiton",
        //         "isPaid": true,
        //         "showTimer": true,
        //         "showStartButton": true,
        //         "secondaryLogo": "https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/4df3a7b9-e530-4d64-9751-4a87aa8c8a2c_Group%20624151%20%281%29.png",
        //         "secondaryImageSize": {
        //             "height": 35,
        //             "width": 35
        //         },
        //         "amountText": "- Rs. 95 Only",
        //         "showKnowMore": false,
        //         "hideReportButton": false,
        //         "hideReportButtonAfterResult": false,
        //         "showMockTest": false,
        //         "showPrepare": false,
        //         "sponserLogo": ""
        //     }
        // }

        this.meta = this.props.meta

        this.parser = new Parser();
        this.userInfo = SharedProfile.getProfile();
        this.staticText = SharedStaticText.getStaticText('AssessmentReport');
        this.eventEndScreenConfig = this.meta && this.meta.eventPayload && this.meta.eventPayload.eventEndScreenConfig;

        if (this.meta && this.meta.eventPayload && this.meta.eventPayload.staticText && this.meta.eventPayload.staticText.assessmentReport) {
            this.state.continueButtonText = this.meta.eventPayload.staticText.assessmentReport.buttonTitle
            this.state.baselineAssessmentText = this.meta.eventPayload.staticText.assessmentReport.baselineAssessmentText
            this.state.finishlineAssessmentText = this.meta.eventPayload.staticText.assessmentReport.finishlineAssessmentText
            this.state.baselineAssessmentHeader = this.meta.eventPayload.staticText.assessmentReport.baselineAssessmentHeader
            this.state.finalAssessmentHeader = this.meta.eventPayload.staticText.assessmentReport.finalAssessmentHeader
        }

        if (this.meta && this.meta.eventPayload && this.meta.eventPayload['baseline'] && this.meta.eventPayload['baseline'].activity && (this.meta.eventPayload['baseline'].activity.percentage || this.meta.eventPayload['baseline'].activity.percentage === 0)) {
            this.state.baselineEventPercentage = this.meta.eventPayload['baseline'].activity.percentage
        }

        if (this.meta.type == Constants.MPL_EVENT && this.meta.mplStaticText && this.meta.mplStaticText.mplAssessmentReport) {
            this.staticText = this.meta.mplStaticText.mplAssessmentReport
        }

        if (this.meta && (this.meta.type == Constants.SCHOLARSHIP_MAY_9_2021) || (this.meta.type == Constants.SCHOLARSHIP_EVENT) || (this.meta.type == Constants.EVENT_BASELINE) || (this.meta.type == Constants.REWARD_EVENT_BASELINE) || (this.meta.type == Constants.REWARD_EVENT_FINAL) || this.meta.type == Constants.TWO_STEP_EVENT_FINAL) {
            this.getScholarshipAssessmentReport()
            return
        }

        // if (this.meta && this.meta.type == Constants.MPL_EVENT) {
        //     this.getMplAssessmentReport()
        //     return
        // }

        this.getAssessmentReport();
    }

    state = {
        loading: true
    }

    getScholarshipAssessmentReport = () => {

        //console.log("position", this.meta.qualifierEventPosition)

        if (!this.meta || !this.meta.activityId)
            return

        let payload = {
            id: this.meta.activityId
        }

        if ((this.state.baselineEventPercentage || this.state.baselineEventPercentage === 0) && (this.meta.type == Constants.SCHOLARSHIP_EVENT || this.meta.type == Constants.REWARD_EVENT_FINAL || this.meta.type == Constants.TWO_STEP_EVENT_FINAL)) {
            payload.baselineAssessmentScore = this.state.baselineEventPercentage
        }

        if (this.meta.type == Constants.SCHOLARSHIP_EVENT || this.meta.type == Constants.REWARD_EVENT_FINAL || this.meta.type == Constants.TWO_STEP_EVENT_FINAL) {
            payload.type = Constants.SCHOLARSHIP_EVENT
            payload.eventId = this.meta.eventId
        }

        if (this.meta.type == Constants.EVENT_BASELINE || this.meta.type == Constants.REWARD_EVENT_BASELINE) {
            payload.type = Constants.EVENT_BASELINE
            payload.eventId = this.meta.eventId
        }



        EventApi.getScholarshipAssessmentReport(payload).then((data) => {
            //console.log(data)
            this.setState({
                report: data,
                skills: data.refresherCourseReport.skills,
                assessmentDetail: data.refresherCourseReport.markDetails,
                url: data.diagnosticReportLink,
                loading: false,
                greetings: data.refresherCourseReport.greetings,
                performanceImprovedText: data.refresherCourseReport.performanceImprovedText
            })

        }).catch(err => {
            if (err) {
                this.setState({ loading: false })
            }
        })

    }

    // getMplAssessmentReport = () => {

    //     if (!this.meta || !this.meta.activityId)
    //         return

    //     let payload = {
    //         id: this.meta.activityId
    //     }

    //     if (this.meta.type == constants.MPL_EVENT) {
    //         payload.type = constants.MPL_EVENT
    //         payload.eventId = this.meta.eventId
    //         payload.eventReportTitle = this.meta.eventReportTitle
    //         payload.subEvent = this.meta.isMplFinalAssessment ? 'FINAL_EVENT' : `QUALIFIER_EVENT_${this.meta.qualifierEventPosition + 1}`
    //     }


    //     APIS.getMplAssessmentReport(payload, (err, data) => {

    //         if (err) {
    //             this.setState({ loading: false })
    //         }

    //         this.setState({ report: data, skills: data.refresherCourseReport.skills, assessmentDetail: data.refresherCourseReport.markDetails, url: data.diagnosticReportLink, loading: false, greetings: data.refresherCourseReport.greetings })

    //     })

    // }


    getAssessmentReport = () => {

        if (!this.meta || !this.meta.activityId)
            return

        let payload = {
            id: this.meta.activityId
        }

        AssessmentApi.getDiagnosticAssessmentReport(payload).then((data) => {
            console.log(data)
            this.setState({
                report: data,
                skills: data.refresherCourseReport.skills,
                assessmentDetail: data.refresherCourseReport.markDetails,
                url: data.diagnosticReportLink, loading: false,
                greetings: data.refresherCourseReport.greetings,
                performanceImprovedText: data.refresherCourseReport.performanceImprovedText
            })

        }).catch(err => {
            if (err) {
                console.log(err)
                this.setState({ loading: false })
            }

        })

    }

    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    close = () => {
        this.props.navigation.goBack();
    }

    onClickLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu')
    }

    onClickShare = (url) => {

        // if (Platform.OS == 'ios') {
        //     this.sharePDFWithIOS(encodeURI(decodeURI(this.state.url)), 'application/pdf')
        // } else {
        //     this.sharePDFWithAndroid(encodeURI(decodeURI(this.state.url)), 'application/pdf')
        // }

    }

    // async onClickPdf() {

    //     this.setState({ fullScreenSpinner: true })

    //     let url = encodeURI(decodeURI(this.state.url))

    //     let dirs = RNFetchBlob.fs.dirs;

    //     let title = new Date().getTime();

    //     let config = {}

    //     if (Platform.OS == 'ios') {

    //         config = {
    //             path: dirs.DocumentDir + `/assessment-report-${title}.pdf`,
    //         }
    //     } else {

    //         try {
    //             const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE);
    //             if (granted !== PermissionsAndroid.RESULTS.GRANTED) {
    //                 this.setState({ fullScreenSpinner: false })
    //                 return
    //             }
    //         } catch (err) {
    //             //console.log(err);
    //             this.setState({ fullScreenSpinner: false })
    //             return
    //         }

    //         config = {
    //             addAndroidDownloads: {
    //                 useDownloadManager: true,
    //                 notification: true,
    //                 mime: 'application/pdf',
    //                 path: `${dirs.DownloadDir}/assessment-report-${title}.pdf`
    //             }
    //         }
    //     }
    //     RNFetchBlob
    //         .config(config)
    //         .fetch('GET', url, {

    //         })
    //         .then((res) => {
    //             this.setState({ fullScreenSpinner: false })
    //             let status = res.info().status;
    //             if (status == 200 && Platform.OS == 'ios') {
    //                 RNFetchBlob.ios.previewDocument(config.path);
    //             }
    //         })
    //         .catch((errorMessage, statusCode) => {
    //             this.setState({ fullScreenSpinner: false })
    //         })
    // }

    sharePDFWithIOS(fileUrl, type) {

        this.setState({ fullScreenSpinner: true })

        // let dirs = RNFetchBlob.fs.dirs
        // let title = new Date().getTime();

        // const configOptions = {
        //     fileCache: true,
        //     path:
        //         dirs.DocumentDir + `/assessment-report-${title}.pdf`
        // };

        // RNFetchBlob.config(configOptions)
        //     .fetch('GET', fileUrl)
        //     .then(async resp => {
        //         let filePath = resp.path();
        //         let options = {
        //             type: type,
        //             url: filePath
        //         };
        //         this.setState({ fullScreenSpinner: false })
        //         await Share.open(options);
        //     })
        //     .catch((err) => {
        //         this.setState({ fullScreenSpinner: false })
        //     })
    }

    sharePDFWithAndroid(fileUrl, type) {

        // this.setState({ fullScreenSpinner: true })
        // let filePath = null;

        // const configOptions = { fileCache: true };

        // RNFetchBlob.config(configOptions)
        //     .fetch('GET', fileUrl)
        //     .then(resp => {
        //         filePath = resp.path();
        //         return resp.readFile('base64');
        //     })
        //     .then(async base64Data => {
        //         this.setState({ fullScreenSpinner: false })
        //         base64Data = `data:${type};base64,` + base64Data;
        //         await Share.open({ url: base64Data });
        //     })
        //     .catch((err) => {
        //         this.setState({ fullScreenSpinner: false })
        //     })

    }

    getSKillIcon = (skill) => {

        switch (skill) {
            case "Knowledge":
                return getImageSource(ImageConstants.KNOWLEDGE)

            case 'Calculation':
                return getImageSource(ImageConstants.CALCULATION)

            case "Comprehension":
                return getImageSource(ImageConstants.COMPREHENSION)

            case "Modeling":
                return getImageSource(ImageConstants.MODELING)

            default:
                return getImageSource(ImageConstants.KNOWLEDGE)
        }

    }

    viewRefresherCourse = () => {

        if (this.meta && this.meta.type == Constants.EVENT_BASELINE) {
            let meta = {
                eventThreshold: this.meta.eventThreshold,
                eventId: this.meta.eventId,
                from: Constants.EVENT_BASELINE,
                refresherCourseId: this.meta.refresherCourseId,
                scholarshipEligibleStaticText: this.meta.scholarshipEligibleStaticText ? this.meta.scholarshipEligibleStaticText : {},
                eventPayload: this.meta && this.meta.eventPayload ? this.meta.eventPayload : {}
            }

            //console.log('>>>>', meta)
            this.props.navigation.navigate('RefresherCourse', { meta })
            return
        }

        if (this.meta && this.meta.type == Constants.REWARD_EVENT_BASELINE) {
            let meta = {
                eventThreshold: this.meta.eventThreshold,
                eventId: this.meta.eventId,
                from: Constants.REWARD_EVENT_BASELINE,
                refresherCourseId: this.meta.refresherCourseId,
                scholarshipEligibleStaticText: this.meta.scholarshipEligibleStaticText ? this.meta.scholarshipEligibleStaticText : {},
                eventPayload: this.meta && this.meta.eventPayload ? this.meta.eventPayload : {}
            }

            //console.log('>>>>', meta)
            this.props.navigation.navigate('RefresherCourse', { meta })
            return
        }
        this.props.navigation.navigate('RefresherCourse')
    }

    renderTitle = () => {
        if (this.meta && this.meta.type == Constants.SCHOLARSHIP_MAY_9_2021)
            return 'Ramanujan Math Scholarship'

        if (this.meta && (this.meta.type == Constants.SCHOLARSHIP_EVENT || this.meta.type == Constants.REWARD_EVENT_FINAL || this.meta.type == Constants.TWO_STEP_EVENT_FINAL))
            return this.state.finalAssessmentHeader ? this.state.finalAssessmentHeader : this.meta.title

        if (this.meta && (this.meta.type == Constants.EVENT_BASELINE) || (this.meta.type == Constants.REWARD_EVENT_BASELINE))
            return this.state.baselineAssessmentHeader ? this.state.baselineAssessmentHeader : this.meta.title

        if (this.meta && this.meta.type == Constants.MPL_EVENT) {
            return this.meta.eventReportTitle ? this.meta.eventReportTitle : this.meta.title
        }

        return this.staticText.title
    }

    goToHomeScreen() {

        if(this.meta && this.meta.from && this.meta.from == Constants.REFRESHER_COURSE) {
            this.onClose()
        }else {
            return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
        }

        
    }

    goToEventList() {
        this.props.goToEventList && this.props.goToEventList()
    }

    onClose = () => {

        this.props.hideViewDetailedReport && this.props.hideViewDetailedReport()

    }



    renderBreadcrumbForKnowMore = () => {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                {
                    this.meta.type == Constants.TWO_STEP_EVENT_FINAL ?
                        <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToEventList()} >{`Events >`}</span>
                        :
                        <></>
                }

                {/* <span className='title-1 pl-2'>{`${this.props.chapterTitle}`}</span> */}
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.onClose()}>{`Assessment Completed`} {`>`}</span>
                <span className='active pl-2'>{`View Detailed Report`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }



    render() {

        //console.log(this.state.skills)

        return (
            <>
                {
                    !this.state.loading ?
                        <div className="view-detailed-report">
                            <div className="">
                                {this.renderBreadcrumbForKnowMore()}
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="title">{this.renderTitle()}</div>
                                </div>
                                <div>
                                    <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                                </div>
                            </div>

                            <div>

                                {
                                    this.meta && this.meta.eventPayload && this.meta.eventPayload.reportImage ?
                                        <div className="text-center">
                                            <img className="event-image" src={this.meta.eventPayload.reportImage} />
                                        </div>

                                        :
                                        <div className="text-center">
                                            <img className="performance-image" src={getImageSource(ImageConstants.PERFORMANCE_BG)} />
                                        </div>
                                }

                                <div className="d-flex justify-content-center mt-4">
                                    {this.eventEndScreenConfig && this.eventEndScreenConfig.hideMarkScoredCard ? <></> : <>
                                        {this.meta && this.meta.eventPayload && this.meta.eventPayload.isSingleStepEvent ? <>
                                            <img className="marks-card-bg" src={getImageSource(ImageConstants.ASSESSMENT_REPORT_MARKS_CARD_BG)} />
                                            <div className="marks-card">

                                                <div className="d-flex flex-row justify-content-around mt-5 ml-3">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <div>
                                                            <img className="exam-pad-icon" src={getImageSource(ImageConstants.CLOCK_ICON_GRAY)} />
                                                        </div>
                                                        <div className="text-center">
                                                            <span className="mark-scored-text">{this.staticText.timeTakenText ? this.staticText.timeTakenText : "Time Taken"}</span>
                                                        </div>
                                                    </div>
                                                    <div className="time-taken">{Math.round(this.state.assessmentDetail.timeTaken / 60)} {this.staticText.minutesText ? this.staticText.minutesText : "mins"}</div>
                                                </div>
                                                <div className="d-flex justify-content-center mt-5">
                                                    {/* <div className="row"> */}
                                                    <div className=" correct-bg p-2 pl-3 pr-3">{this.staticText.correctText ? this.staticText.correctText : "Correct:"} {this.state.assessmentDetail.correct}</div>
                                                    <div className=" incorrect-bg p-2 pl-3 pr-3 ml-5">{this.staticText.inCorrectText ? this.staticText.inCorrectText : "Incorrect:"} {this.state.assessmentDetail.inCorrect}</div>
                                                    <div className=" not-answered-bg p-2 pl-3 pr-3 ml-5">{this.staticText.notAnsweredText ? this.staticText.notAnsweredText : "Not Answered:"} {this.state.assessmentDetail.notAttempted}</div>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </>
                                            :
                                            <>
                                                <img className="marks-card-bg" src={getImageSource(ImageConstants.ASSESSMENT_REPORT_MARKS_CARD_BG)} />
                                                <div className="marks-card">

                                                    <div className="d-flex flex-row justify-content-around mt-5 ml-3">
                                                        <div className="mark-scored">{this.state.assessmentDetail.markScored}/{this.state.assessmentDetail.totalMark}</div>
                                                        <div className="time-taken">{Math.round(this.state.assessmentDetail.timeTaken / 60)} {this.staticText.minutesText ? this.staticText.minutesText : "mins"}</div>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-around mt-4">
                                                        <div className=" d-flex justify-content-center align-items-center">
                                                            <div>
                                                                <img className="exam-pad-icon" src={getImageSource(ImageConstants.EXAM_PAD_ICON_GRAY)} />
                                                            </div>
                                                            <div className="text-center">
                                                                <span className="mark-scored-text">{this.staticText.marksScoredText ? this.staticText.marksScoredText : 'Marks Scored'}</span>
                                                            </div>
                                                        </div>
                                                        <div className=" d-flex justify-content-center align-items-center"><div>
                                                            <img className="exam-pad-icon" src={getImageSource(ImageConstants.CLOCK_ICON_GRAY)} />
                                                        </div>
                                                            <div className="text-center">
                                                                <span className="mark-scored-text">{this.staticText.timeTakenText ? this.staticText.timeTakenText : "Time Taken"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-center mt-5">
                                                        {/* <div className="row"> */}
                                                        <div className=" correct-bg p-2 pl-3 pr-3">{this.staticText.correctText ? this.staticText.correctText : "Correct:"} {this.state.assessmentDetail.correct}</div>
                                                        <div className=" incorrect-bg p-2 pl-3 pr-3 ml-5">{this.staticText.inCorrectText ? this.staticText.inCorrectText : "Incorrect:"} {this.state.assessmentDetail.inCorrect}</div>
                                                        <div className=" not-answered-bg p-2 pl-3 pr-3 ml-5">{this.staticText.notAnsweredText ? this.staticText.notAnsweredText : "Not Answered:"} {this.state.assessmentDetail.notAttempted}</div>
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                    }
                                </div>

                                {this.state.greetings ?
                                    <div className="greetings-card mt-4">
                                        <p className="greeting-text" dangerouslySetInnerHTML={{ __html: this.state.greetings.replace(/\n/g, "<br />") }}>
                                        </p>
                                    </div>
                                    :
                                    <></>}

                                {
                                    this.state.skills && this.state.skills.length ?

                                        <>
                                            <div className="skill-text mt-4 mb-4">{this.staticText.skillsTitle}</div>
                                            <div className="row m-0 p-0 performance-card-wrapper justify-content-center">

                                                {this.state.skills && this.state.skills.map((item, index) =>

                                                    <div className={`col-5 pr-0 pl-0 performance-card position-relative ${item.status == 'CLEARED' ? 'passed-skill-border' : 'failed-skill-border'}`}>

                                                        <img className="card-header-image position-absolute" src={getImageSource(item.status == 'CLEARED' ? ImageConstants.PERFORMANCE_CARD_HEADER : ImageConstants.FAILED_SKILL_HEADER)} />

                                                        <div className="">
                                                            <div className="skill-header mt-3 ml-3 d-flex align-items-center ">
                                                                <div className="mr-2">
                                                                    <img className="skill-image" src={this.getSKillIcon(item.title)} />
                                                                </div>
                                                                <div className="skill-title">
                                                                    {/* <Text style={Styles.skillIndex}>{this.staticText.skillIndexText} {index + 1}</Text> */}
                                                                    {item.title}
                                                                </div>
                                                            </div>
                                                            <div className="skill-message text-center">
                                                                {this.parser.parseWithStyles(`<p>${item.description}</p>`, { p: { fontSize: '14px', fontWeight: '500' }, strong: { fontSize: '14px', fontWeight: '600' } })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>

                                        :
                                        <></>
                                }



                            </div>

                            {
                                this.state.url ?
                                    <div className="d-flex justify-content-center button-wrapper mt-5 mb-5">
                                        <div className="button-text download-pdf-button d-flex justify-content-center align-items-center">
                                            <div className="mr-2">
                                                <img className="download-icon" src={getImageSource(ImageConstants.WHITE_DOWNLOAD_ICON)} />
                                            </div>
                                            <div className=""><a className="link-tag" href={this.state.url} download target="_blank">Download</a></div>
                                        </div>
                                        {/* <div className="button-text share-button d-flex justify-content-center align-items-center">
                                            <div className="mr-2">
                                                <img className="download-icon" src={getImageSource(ImageConstants.SHARE_ICON)} />
                                            </div>
                                            <div> */}
                                        {/* <EmailShareButton body={this.state.url}
                                                    separator=" This is Your Event Assessment Performance Report"
                                                    subject="Event Assessment Performance Report">Share <EmailIcon size={30} round={true} />
                                                </EmailShareButton> */}
                                        {/* <WhatsappShareButton title = "This is Your Event Assessment Performance Report"
                                                separator={"this"}>
                                                    <WhatsappIcon size={30} round = {true} ></WhatsappIcon>
                                                </WhatsappShareButton> */}
                                        {/* </div>
                                        </div> */}
                                    </div>
                                    :
                                    <></>
                            }





                        </div>
                        :

                        <Loading />
                }
                {
                    this.state.fullScreenSpinner ? <div className="full-screen-loading"><Loading /></div> : <></>
                }
            </>

        )
    }


}