import React from "react";
import './event-know-more.scss';
import SharedProfile from "../../../../shared-storage/profile";
import Parser from "../../common/content-parser/custom-parser";
import { getImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import AsyncStore from "../../../../shared-storage/async-store";
import Path from "../../routes/routes-path";

export default class ScholarshipEventKnowMore extends React.Component {
    constructor(props) {
        super(props);
        this.parser = new Parser();

        this.meta = this.props.knowMore

        if (this.meta.knowMore)
            this.state.items = this.meta.knowMore


        this.userInfo = SharedProfile.getProfile();



        // this.landingScreenInstance = LandingScreenShared.getLandingScreenInstance()

        // if (!this.userInfo.isSubscribed && this.meta.from && this.meta.from == constants.TWO_STEP_EVENT)
        //     this.landingScreenInstance.pauseUserAppTime()
    }

    componentDidMount() {
        this.showPagination()
    }

    videoPlayerRefs = {};

    state = {
        items: [
        ],
        activeIndex: 0,
        showPagination: false
    }


    renderPagination = (activeIndex) => {

        return (
            <div className="d-flex justify-content-center circle-wrapper">
                {this.state.items.map((item, index) =>
                    <div className={`circle ${index === this.state.activeIndex ? "active-dot" : ''}`}>
                    </div>
                )}
            </div>
        )
    }

    async onPressContinue(item) {

        if (this.state.activeIndex === this.state.items.length - 1) {
            let doc = await AsyncStore.getKnowMoreRead();

            if (doc) {
                doc = JSON.parse(doc)

                if (!doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${this.meta.eventId ? this.meta.eventId : ''}`]) {

                    doc[`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${this.meta.eventId ? this.meta.eventId : ''}`] = true

                    AsyncStore.setKnowMoreRead(doc)
                }
            } else {
                AsyncStore.setKnowMoreRead({
                    [`${this.userInfo.mobileNumber}_${this.userInfo.userName}_${this.meta.eventId ? this.meta.eventId : ''}`]: true
                });

            }

            if (this.meta && this.meta.isPaidEvent || item.isPaidEvent) {

                // if (this.landingScreenInstance)
                //     this.landingScreenInstance.resumeUserAppTime()

                this.props.delegate.showPaidEventPayScreen && this.props.delegate.showPaidEventPayScreen({ event: this.meta && this.meta.event })
                return

            }

        }





        if (item && item.actionBased && item.actionUrl) {
            window.open(`//${item.actionUrl}`)
        }

        if (this.state.activeIndex !== this.state.items.length - 1) {
            // this.showPagination()
            this.setState({ activeIndex: this.state.activeIndex += 1 })
        }

        // if (this.meta.from && this.meta.from == constants.MPL_EVENT) {
        //     this.props.navigation.navigate("LandingScreen")
        //     return
        // }

        if (this.meta.from && this.meta.from == 'EVENT_CAMPAIGN') {
            this.props.navigation.navigate('EventCampaignScreen', { event: this.meta.eventRes })
            return
        }

        // if (this.meta && this.meta.from && this.meta.from == constants.REWARD_EVENT) {
        //     this.props.navigation.navigate('RewardEventLandingScreen', { meta: { isFromKnowMore: true } })
        //     return
        // }

        // if (this.meta && this.meta.isPaidEvent || item.isPaidEvent) {

        //     if (this.landingScreenInstance)
        //         this.landingScreenInstance.resumeUserAppTime()

        //     this.props.delegate.showPaidEventPayScreen && this.props.delegate.showPaidEventPayScreen({ event: this.meta && this.meta.event })
        //     return

        // }

        // if (this.meta.from && this.meta.from == constants.TWO_STEP_EVENT) {
        //     if (this.landingScreenInstance)
        //         this.landingScreenInstance.resumeUserAppTime()

        //     this.props.navigation.navigate("LandingScreen")
        //     return
        // }

        // this.props.navigation.navigate('ScholarshipScreenV2', { meta: { isFromKnowMore: true } })
    }


    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    onScrollEnd = (e) => {

        let contentOffset = e.nativeEvent.contentOffset;

        let viewSize = e.nativeEvent.layoutMeasurement;

        let pageNum = Math.round(contentOffset.x / viewSize.width);

        this.setState({ activeIndex: pageNum }, () => {
            this.showPagination()
        })
    }

    renderSteps = (steps) => {

        let Styles = {
            stepDescriptionText: {
                fontSize: '15px',
                color: '#455B64'
            },
            stepDescriptionBoldText: {
                fontWeight: '600'
            },
            linkText: {
                fontSize: '15px',
                color: '#0095ff',
                background: `rgba(208, 232, 255, 0.90)`,
                borderRadius : '6px',
                padding : '2px'
            }
        }

        return (steps.map((item, index) => (
            <div className="row align-items-center ml-0 mt-3 p-0" >
                <div className="col-1 step-no-max-width pr-0">
                    <div className="step-cirlce d-flex justify-content-center">
                        <div className="align-self-center">
                            {index + 1}
                        </div>
                    </div>
                </div>
                
                    <div className="col-11 ml-0 step-title d-inline-block" >
                        {item.title ? this.parser.parseWithStyles(item.title, { p: Styles.stepDescriptionText, strong: Styles.stepDescriptionBoldText, link: Styles.linkText }) : <></>}
                        {item.description ? this.parser.parseWithStyles(item.description, { p: Styles.stepDescriptionText, strong: Styles.stepDescriptionBoldText, link: Styles.linkText }) : <></>}
                    </div>
                    {/* <Text style={Styles.stepDescriptionText}>{item.description}</Text> */}
                    {/* <div className="col-11 step-description-wrapper">
                        
                    </div> */}
                

            </div>
        )))
    }

    showPagination = () => {
        this.setState({ showPagination: true }, () => {
            // setTimeout(() => {
            //     this.setState({ showPagination: false })
            // }, 3000)
        })
    }

    goToHomeScreen() {
        return this.props.delegate?.props.history.replace({ pathname: Path.HOME })
    }

    goToEventList() {
        this.props.delegate && this.props.delegate.hideKnowMoreScreens && this.props.delegate.hideKnowMoreScreens()
    }


    renderBreadcrumbForKnowMore = () => {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToEventList()} >{`Events >`}</span>
                <span className='title-1 pl-2'>{`Know More`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }


    render() {

        let Styles = {
            descriptionText: {
                fontSize: '16px',
                color: '#455B64'
            },
            descriptionTextBold: {
                fontWeight: '600'
            },
            linkText: {
                fontSize: '17px',
                color: '#0095ff',
                // backgroundColor : 'border-radius: 6px;
                
            }
        }

        return (

            <div className="know-more-screens">
                <img className="header-image-size" src={getImageSource(ImageConstants.KNOW_MORE_HEADER)}></img>
                {this.renderBreadcrumbForKnowMore()}
                <div className="know-more-content">
                    {
                        this.state.items.map((item, index) => (
                            <>
                                {
                                    this.state.activeIndex === index ?
                                        <>
                                            <div className="min-height">
                                                {!item.renderFullScreenImage ?
                                                    item.media.type == 'VIDEO' ? <div className="video-container">
                                                        <video
                                                            src={item.media.url}
                                                            controls
                                                            delegate={this}
                                                        // style={Styles.video}
                                                        />

                                                    </div> : <img className="image-size" src={item.media.url} />
                                                    :
                                                    item.media.type == 'VIDEO' ? <div className="video-container">
                                                        <video
                                                            src={item.media.url}
                                                            controls
                                                            delegate={this}
                                                        // style={Styles.video}
                                                        />

                                                    </div> :
                                                        <div className="text-center">
                                                            <img className="full-screen-image-size" src={item.webMedia.url} />
                                                        </div>


                                                }

                                                {!item.renderFullScreenImage ?
                                                    <>
                                                        <div className="row m-0 p-0">
                                                            <div className="col-12 question-title  mt-2 mb-2" >{item.question}</div>
                                                            {/* <Text style={Styles.descriptionText}>{item.description}</Text> */}
                                                            <div className="col-11 description-text-wrapper">
                                                                {item.description ? this.parser.parseWithStyles(item.description, { p: Styles.descriptionText, strong: Styles.descriptionTextBold }) : <></>}
                                                            </div>

                                                        </div>
                                                        {item.steps && item.steps.length && this.renderSteps(item.steps)}

                                                    </>
                                                    :
                                                    <>
                                                    <div className="row m-0 p-0">
                                                            <div className="col-12 question-title  mt-2 mb-2" >{item.question}</div>
                                                            {/* <Text style={Styles.descriptionText}>{item.description}</Text> */}
                                                            <div className="col-11 description-text-wrapper">
                                                                {item.description ? this.parser.parseWithStyles(item.description, { p: Styles.descriptionText, strong: Styles.descriptionTextBold }) : <></>}
                                                            </div>

                                                        </div>
                                                        {item.steps && item.steps.length && this.renderSteps(item.steps)}

                                                    </>}
                                            </div>

                                            {
                                                this.state.activeIndex == this.state.items.length - 1 ?
                                                    <div className="d-flex justify-content-center" >
                                                        <div className="continue-button cursor-pointer" onClick={() => this.onPressContinue(item)}>
                                                            {item.buttonText || "LET'S GO"}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="d-flex justify-content-center"  >
                                                        <div className="continue-button cursor-pointer" onClick={() => this.onPressContinue(item)}>
                                                            Continue
                                                        </div>
                                                    </div>
                                            }
                                        </>

                                        :
                                        <></>
                                }
                            </>
                        ))
                    }
                </div>
                <img className="footer-image-size" src={getImageSource(ImageConstants.KNOW_MORE_FOOTER)}></img>
                {this.state.showPagination ? this.renderPagination() : <></>}

            </div>

        )
    }
}