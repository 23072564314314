import React, { Component } from 'react';
import './things-to-remember.scss';
// import CheatSheetHeader from "./things-to-remember-header/things-to-remember-header";
import SharedProfile from '../../../../shared-storage/profile';
import { ImageConstants } from '../../../constants/constants';
import { getImageSource, getThemeImageSource } from '../../../utility/image-source';
import SharedStaticText from '../../../../shared-storage/static-text-store';
import Loading from '../../common/loading/loading';
import ExamPrepKitApi from '../../../apis/exam-prep-kit';
import { CircularProgressbar } from 'react-circular-progressbar';
import ExamPrepKitLearningExperience from '../exam-prep-kit/exam-prep-kit-learning-experience/exam-prep-kit-learning-experience';

export default class ThingsToRemember extends Component {
    constructor(props) {
        super(props);
        // const { navigation } = this.props;
        // this.meta = navigation.getParam('meta');
        this.meta = this.props.metaInfo
        console.log(this.meta)
        this.state = {
            thingsToRememberList: [
                {
                    title: "Formula",
                    icon: getImageSource(ImageConstants.FORMULA),
                    percentage: 0,
                    type: "FORMULA",
                    contentType: 5
                },
                {
                    title: "Property",
                    icon: getImageSource(ImageConstants.PROPERTIES),
                    percentage: 20,
                    type: "PROPERTY",
                    contentType: 3
                },
                {
                    title: "Statement",
                    icon: getImageSource(ImageConstants.STATEMENT),
                    percentage: 30,
                    type: "STATEMENT",
                    contentType: 4
                },
                {
                    title: "Definition",
                    icon: getImageSource(ImageConstants.DEFINITION),
                    percentage: 20,
                    type: "DEFENITION",
                    contentType: 2
                }
            ],
            chapterKey: this.meta.chapterKey,
            loading: true
        };
        this.userInfo = SharedProfile.getProfile();
        this.staticText = SharedStaticText.getStaticText('thingsToRemember');


        // this.props.navigation.addListener('didFocus', (data) => {
        this.getThigsToRememberProgress()
        // });
    }

    goBack = () => {
        this.props.navigation.goBack();
    }


    getThigsToRememberProgress = () => {

        let payload = {
            chapterKey: this.state.chapterKey
        }

        this.setState({ loading: true })

        ExamPrepKitApi.getThingsToRememberProgress(payload).then((res) => {
            console.log(res)
            this.setState({ loading: false })
            if (res) {

                let thingsToRememberList = this.state.thingsToRememberList

                for (let i = 0; i < thingsToRememberList.length; i++) {

                    let item = thingsToRememberList[i]

                    if (item.type == 'FORMULA' && res.formula) {
                        item.percentage = res.formula.percentage
                        item.numberOfContent = res.formula.numberOfContent
                        item.resumeId = res.formula.resumeId
                    }

                    if (item.type == 'PROPERTY' && res.property) {
                        item.percentage = res.property.percentage
                        item.numberOfContent = res.property.numberOfContent
                        item.resumeId = res.property.resumeId
                    }

                    if (item.type == 'STATEMENT' && res.statement) {
                        item.percentage = res.statement.percentage
                        item.numberOfContent = res.statement.numberOfContent
                        item.resumeId = res.statement.resumeId
                    }

                    if (item.type == 'DEFENITION' && res.definition) {
                        item.percentage = res.definition.percentage
                        item.numberOfContent = res.definition.numberOfContent
                        item.resumeId = res.definition.resumeId
                    }
                }

                console.log(thingsToRememberList)
                this.setState({ thingsToRememberList })
            }
        }).catch(err => {
            console.log(err)
            this.setState({ loading: false })
            if (err) {
                return;
            }

        })
    }


    toggleShadow = () => {
        this.setState({ showShadow: !this.state.showShadow })
    }

    onPressAction = (item, contents) => {

        if (!contents.length) {
            return;
        }

        if (item.type == 'FORMULA') {
            this.showExamPrepKitLearningExperience({
                params: {
                    title: this.meta.title,
                    headerTitle: item.title,
                    headerIcon: getImageSource(ImageConstants.FORMULA),
                    contents,
                    chapterKey: this.state.chapterKey,
                    from: item.type,
                    resumeIndex: item.resumeId ? item.resumeId : 0,
                    origin: "THINGS_TO_REMEMBER"
                }
            })
            // this.props.navigation.navigate("ExamPrepKitLearningExperience", {
            //     params: {
            //         title: this.meta.title,
            //         headerTitle: item.title,
            //         headerIcon: getImageSource(ImageConstants.FORMULA),
            //         contents,
            //         chapterKey: this.state.chapterKey,
            //         from: item.type,
            //         resumeIndex: item.resumeId ? item.resumeId : 0,
            //         origin: "THINGS_TO_REMEMBER"
            //     }
            // })
        }

        if (item.type == 'PROPERTY') {
            this.showExamPrepKitLearningExperience({
                params: {
                    title: this.meta.title,
                    headerTitle: item.title,
                    headerIcon: getImageSource(ImageConstants.PROPERTIES),
                    contents,
                    chapterKey: this.state.chapterKey,
                    from: item.type,
                    resumeIndex: item.resumeId ? item.resumeId : 0,
                    origin: "THINGS_TO_REMEMBER"
                }
            })
            // this.props.navigation.navigate("ExamPrepKitLearningExperience", {
            //     params: {
            //         title: this.meta.title,
            //         headerTitle: item.title,
            //         headerIcon: getImageSource(ImageConstants.PROPERTIES),
            //         contents,
            //         chapterKey: this.state.chapterKey,
            //         from: item.type,
            //         resumeIndex: item.resumeId ? item.resumeId : 0,
            //         origin: "THINGS_TO_REMEMBER"
            //     }
            // })
        }

        if (item.type == 'STATEMENT') {

            this.showExamPrepKitLearningExperience({
                params: {
                    title: this.meta.title,
                    headerTitle: item.title,
                    headerIcon: getImageSource(ImageConstants.STATEMENT),
                    contents,
                    chapterKey: this.state.chapterKey,
                    from: item.type,
                    resumeIndex: item.resumeId ? item.resumeId : 0,
                    origin: "THINGS_TO_REMEMBER"
                }
            })

            // this.props.navigation.navigate("ExamPrepKitLearningExperience", {
            //     params: {
            //         title: this.meta.title,
            //         headerTitle: item.title,
            //         headerIcon: getImageSource(ImageConstants.STATEMENT),
            //         contents,
            //         chapterKey: this.state.chapterKey,
            //         from: item.type,
            //         resumeIndex: item.resumeId ? item.resumeId : 0,
            //         origin: "THINGS_TO_REMEMBER"
            //     }
            // })
        }

        if (item.type == 'DEFENITION') {
            this.showExamPrepKitLearningExperience({
                params: {
                    title: this.meta.title,
                    headerTitle: item.title,
                    headerIcon: getImageSource(ImageConstants.DEFINITION),
                    contents,
                    chapterKey: this.state.chapterKey,
                    from: 'DEFINITION',
                    resumeIndex: item.resumeId ? item.resumeId : 0,
                    origin: "THINGS_TO_REMEMBER"
                }
            })
            // this.props.navigation.navigate("ExamPrepKitLearningExperience", {
            //     params: {
            //         title: this.meta.title,
            //         headerTitle: item.title,
            //         headerIcon: getImageSource(ImageConstants.DEFINITION),
            //         contents,
            //         chapterKey: this.state.chapterKey,
            //         from: "DEFINITION",
            //         resumeIndex: item.resumeId ? item.resumeId : 0,
            //         origin: "THINGS_TO_REMEMBER"
            //     }
            // })
        }
    }

    showExamPrepKitLearningExperience(data) {
        this.setState({showExamPrepKitLearningExperience : true, ...data})
    }

    hideExamPrepKitLearningExperience() {
        this.setState({showExamPrepKitLearningExperience : false, params : {}})
    }

    


    getThingsToRememberContent = (item) => {

        let payload = {
            chapterKey: this.state.chapterKey,
            contentType: item.contentType
        }

        this.setState({ loading: true })

        ExamPrepKitApi.getThingsToRememberContent(payload).then((res) => {

            this.setState({ loading: false })
            this.onPressAction(item, res)
        }).catch(err => {
            console.log(err)
            this.setState({ loading: false })

            if (err) {
                return;
            }
        })
    }

    onClickLateralMenu = () => {
        this.props.navigation.navigate('LateralMenu', { meta: { from: 'PREPKIT' } })
    }

    goToHomeScreen() {
        this.props.delegate && this.props.delegate.goToHomeScreen && this.props.delegate.goToHomeScreen()
    }

    goToExamPrepKit() {
        this.props.delegate && this.props.delegate.goToExamPrepKit && this.props.delegate.goToExamPrepKit()
    }

    goToExamPrepKitChapterList() {
        this.props.showPrepKitChapterList && this.props.showPrepKitChapterList()
    }

    goBackToTableOfContents() {
        this.props.delegate && this.props.delegate.hideThingsToRemember && this.props.delegate.hideThingsToRemember()
    }


    renderThingsToRemember = () => {

        let elements = []

        for (let i = 0; i < this.state.thingsToRememberList.length; i++) {

            let item = this.state.thingsToRememberList[i]

            if (item.numberOfContent) {
                elements.push(
                    <div onClick={() => this.getThingsToRememberContent(item)}
                        className='d-flex align-items-center justify-content-between chapter-card pl-0 pb-3 pt-3 cursor-pointer'>
                        <div className='d-flex align-items-center'>
                            <div className='mr-2' style={{ width: '30px', height: '30px' }}>
                                <CircularProgressbar value={item.percentage && item.percentage > 0 ? item.percentage : 0}
                                    strokeWidth={15}
                                />
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='mr-2'>
                                    <img className='chapter-icon' src={item.icon}></img>
                                </div>
                                <div>
                                    <div className='table-of-content-title'>{item.title}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img className='arrow-image-size' src={getImageSource(ImageConstants.BLUE_ARROW_ICON)}></img>
                        </div>
                    </div>
                )
            }
        }

        return elements
    }




    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb mt-2'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goToExamPrepKit()} >{`Prepare`}{` > `}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goBackToTableOfContents()}>{`${this.meta.title}`} {` > `}</span>
                <span className='title-1 pl-2 cursor-pointer' onClick={() => this.goBackToTableOfContents()}>{`${`Table of Contents`}`} {` > `}</span>
                <span className='title-1 pl-2' >{`Things to Remember`}</span>
            </div>
        )
    }



    render() {


        if(this.state.showExamPrepKitLearningExperience) {
            return <ExamPrepKitLearningExperience delegate = {this} metaInfo = {this.state.params} />
        }

        return (
            <>
                {!this.state.loading ?
                    <div className='things-to-remember'>
                        {this.renderBreadcrumbForChapterList()}
                        <div>
                            <img className='header-image-size' src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, 1)} />
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='mr-2'><img className='things-to-remember-icon' src={getImageSource(ImageConstants.THINGS_TO_REMEMBER_ICON)} /></div>
                            <div className='d-flex flex-column'>
                                <div className='header-text'>Things to Remember</div>
                                <div className='chapter-title'>{this.meta.title}</div>
                            </div>
                        </div>
                        <div className='mb-2'>
                            <img className='divider-image-width' src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, 1)} />
                        </div>
                        <div className='ttr-wrapper'>
                            {this.renderThingsToRemember()}
                        </div>
                    </div>
                    : <Loading />
                }
            </>

        )
    }

}

