import React from 'react';
import './header-component.scss';
import { Constants, ImageConstants } from '../../../constants/constants';
import { getImageSource } from '../../../utility/image-source'
import { Link, Redirect } from 'react-router-dom';
import Path from '../../routes/routes-path';
import Loading from '../../common/loading/loading';
// import Service from '../../../apis/service-manager';
// import Urls from '../../../apis/url';
import SessionCache from '../../routes/session-cache';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRetweet } from '@fortawesome/free-solid-svg-icons'
// import RewardApi from '../../../apis/rewards'
import Store from '../../../store/store';
import CommonUtils from '../../../utility/common-utilities';
import SharedProfile from '../../../../shared-storage/profile';
import AuthenticateAPI from '../../../apis/authenticate';

class Header extends React.Component {

    state = {
        isLogout: false,
        isLoading: false,
        userInfo: {}
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let userInfo = SharedProfile.getProfile()
        this.setState({ userInfo })
        // this.getReward()
        Store.setStore("headerComponent", this)
    }

    // getReward(payload = {}) {
    //     RewardApi.getRewardPoints(payload)
    //         .then(response => {
    //             let reward = response.response
    //             this.setState({ reward: reward, isLoading: false })
    //         }).catch(err => {
    //             this.setState({ isLoading: false })
    //         })
    // }

    logout = () => {

        this.props.delegate && this.props.delegate.logout()

        // this.setState({
        //     isLoading: true
        // });

        // AuthenticateAPI.studentLogout().then((res) => {

        //     SharedProfile.setProfile({
        //         isAuthenticated: false
        //     });

        //     this.setState({
        //         isLoading: false,
        //     });

        //     setTimeout(() => {
        //         this.setState({
        //             isLogout: true,
        //         });
        //     }, 200);

        // }).catch(err => {
        //     //console.log(err)
        // })
    }

    goToHomeScreen() {
        return this.props?.delegate?.props?.history?.push({ pathname: Path.HOME })
    }




    render() {

        return (
            <div className={`cw-header row no-gutters header-bg ${this.props.transparent ? '' : ''}`}>
                {this.state.isLoading && <Loading></Loading>}
                {this.state.isLogout && <Redirect to={Path.LOGIN} />}

                <div className="col-4 logo-container">
                    <Link to={Path.HOME}>
                        <img className="logo" src={getImageSource(ImageConstants.CW_LOGO)} />
                    </Link>
                </div>
                <div className="col header-right-container">
                    {/* <Link to={`${Path.PROFILE}?type=reward`}>
                        <div className="d-inline-block reward-wrapper mr-4">
                            <img alt="" src={getImageSource(ImageConstants.GIFTBOX)} className='mr-2' />
                            <span className="reward-text mr-2">My Reward Points</span> <span className="reward-point"> {CommonUtils.convertToFixed(this.state.reward?.availablePoints || 0, 2)}</span>
                        </div>
                    </Link> */}
                    <div className='d-flex align-items-center'>
                        <img className='user-name-icon-size mr-1' src={getImageSource(ImageConstants.USER_NAME_ICON)} />
                        <div className='user-name-text mr-2'>{this.state.userInfo.name}</div>
                    </div>


                    {!this.props.from || this.props.from != 'VERIFY_SCREEN' ? <><div className='school-container'>
                        {/* <div className='text-right'><span className='school-name'>{this.state?.userInfo?.name || ""}</span></div> */}
                        {/* <div className='text-right school-address'>{this.state?.userInfo?.schoolInfo?.address?.branch || ""}, {this.state?.userInfo?.schoolInfo?.address?.city || ""}</div> */}
                        <div className=''>
                            <img className='home-icon cursor-pointer' onClick={() => this.goToHomeScreen()}
                                src={getImageSource(ImageConstants.HOME_ICON)} />
                        </div>
                    </div>
                        {
                            this.state.userInfo?.isSuperAdmin ?
                                <div className='school-container'>
                                    <div className='text-right'>
                                        <Link to={Path.SCHOOLS}>
                                            <FontAwesomeIcon className="cursor-pointer" icon={faRetweet} />
                                        </Link>
                                    </div>
                                </div> : ""
                        }
                        {/* <div className='school-container'>
                            <div className='text-right'><small>UNIQUE SCHOOL CODE :</small> <span className='school-name'>{this.state?.userInfo?.schoolInfo?.schoolCode || ""}</span></div>
                        </div> */}

                        <div className='pr-5'>
                            <img className='bell-icon' src={getImageSource(ImageConstants.NOTIFICATION_BELL_ACTIVE)}></img>
                        </div>
                    </> : ''}
                    {this.props.from && this.props.from == 'VERIFY_SCREEN' ? <span className='pr-2 logout-text'>Logout</span> : ''}

                    <div className='logout-button-wrapper cursor-pointer' onClick={this.logout}>
                        <div className='logout-text'>LOGOUT</div>
                        <div><img className="logout-icon pt-2 pb-2" src={getImageSource(ImageConstants.LOGOUT_ICON_RED)} ></img></div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Header;