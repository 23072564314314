import Axios from 'axios';
import HttpClient from './http-client';
import URL from './url';

export default class CommonApi {


    static getMiscellaneousList(payload) {

        let url = URL.BASE_URL + URL.MISCELLANEOUS_LIST;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }


    static getGlossaryDetails(payload) {

        let url = URL.BASE_URL + URL.GLOSSARY_DETAILS;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }

    static getServerTime(payload) {

        let url = URL.BASE_URL + URL.SERVER_TIME;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }




    static getTermsandConditions(payload) {

        let url = URL.BASE_URL + URL.PUBLIC_CONTENT_DETAILS;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }


    static getClass(payload) {
        let url = URL.BASE_URL + URL.CLASS_LIST;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }


    static getBoard(payload) {

        let url = URL.BASE_URL + URL.BOARD_LIST;

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        if (params && params.length) {
            url = url + "?" + params.join('&');
        }

        return HttpClient.get(url, payload);
    }


    static getUserAppTime(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.GET_TRIAL_USER_APP_TIME + "?" + params.join('&') : URL.GET_TRIAL_USER_APP_TIME;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }


    static updateUserAppTime(payload) {

        return HttpClient.put(URL.BASE_URL + URL.UPDATE_TRIAL_USER_APP_TIME, null, payload);
    }



    static getCountryCode(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.COUNTRY_LIST + "?" + params.join('&') : URL.COUNTRY_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getConfigurationsList(payload) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.CONFIGURATIONS_LIST + "?" + params.join('&') : URL.CONFIGURATIONS_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getThemeList(payload) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.CATEGORY_THEME_LIST + "?" + params.join('&') : URL.CATEGORY_THEME_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }


    static getChapterList(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.CHAPTER_LIST + "?" + params.join('&') : URL.CHAPTER_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }




    static getCountryCode(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.COUNTRY_LIST + "?" + params.join('&') : URL.COUNTRY_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }


    static getMisc(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.MISC + "?" + params.join('&') : URL.MISC;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getSubscriptions(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SUBSCRIPTIONS + "?" + params.join('&') : URL.SUBSCRIPTIONS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getCities(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.CITIES_LIST + "?" + params.join('&') : URL.CITIES_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static addMiscellaneous(payload) {
        return HttpClient.post(URL.BASE_URL + URL.MISC, null, payload);
    }

    static discountDetails(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.GET_DISCOUNT_DETAILS + "?" + params.join('&') : URL.GET_DISCOUNT_DETAILS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static addClaimDetails(payload) {
        return HttpClient.post(URL.BASE_URL + URL.CLAIM_DETAILS, null, payload);
    }

    static getSignedUrl(payload) {
        let fileName = payload.fileName;

        let url = URL.BASE_URL + URL.CLAIMSIGNURL;

        url += `?fileName=${fileName}`;

        return Axios.get(url, payload);
    }

    static uploadFileAwsS3(signedUrl, file, progressCallback) {
        let options = {
            headers: {
                "Content-Type": file.type
            },
            onUploadProgress: progressCallback
        };
        return Axios.put(signedUrl, file, options);
    }

    static getSearchedVideoLearningContent(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.VIDEO_CONTENT_SEARCH + "?" + params.join('&') : URL.VIDEO_CONTENT_SEARCH;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }


    static updateProfile(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_PROFILE, null, payload);
    }


    static sendOtpUpdateProfile(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SEND_OTP_UPDATE_PROFILE, null, payload);
    }

    static getTranferLimit(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.USER_TRANSFER_LIMIT + "?" + params.join('&') : URL.USER_TRANSFER_LIMIT;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }


    static userTransferSendOtp(payload) {
        return HttpClient.post(URL.BASE_URL + URL.USER_TRANSFER_SEND_OTP, null, payload);
    }


    static userTransfer(payload) {
        return HttpClient.post(URL.BASE_URL + URL.USER_TRANSFER, null, payload);
    }

    static getSchoolInformationFromSchoolConnect(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.FETCH_SCHOOL_FROM_SCHOOL_CODE + "?" + params.join('&') : URL.FETCH_SCHOOL_FROM_SCHOOL_CODE;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }


    static schoolConnectConfirm(payload) {
        return HttpClient.put(URL.BASE_URL + URL.SCHOOL_CONNECT_CONFIRM, null, payload);
    }


    static getSchoolSections(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SCHOOL_SECTIONS + "?" + params.join('&') : URL.SCHOOL_SECTIONS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getEventStudentCertificate(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.EVENT_STUDENT_CERTIFICATE + "?" + params.join('&') : URL.EVENT_STUDENT_CERTIFICATE;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }


    static updateInvalidInformation(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_INVALID_INFORMATION, null, payload);
    }


    static insertEventLogs(payload) {
        return HttpClient.post(URL.BASE_URL + URL.EVENT_LOGS, null, payload);
    }


    static getDiagnosticAssessmentStatus(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.DIAGNOSTIC_ASSESSMENT_STATUS + "?" + params.join('&') : URL.DIAGNOSTIC_ASSESSMENT_STATUS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getTranslatedText(payload = {}) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.GET_TRANSLATED_TEXT + "?" + params.join('&') : URL.GET_TRANSLATED_TEXT;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    
    static getWorksheetChapterList(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.WORKSHEET_CHAPTER_LIST + "?" + params.join('&') : URL.WORKSHEET_CHAPTER_LIST;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getStudentPerformanceReport(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.STUDENT_ASSESSMENT_PERFORMANCE_REPORT + "?" + params.join('&') : URL.STUDENT_ASSESSMENT_PERFORMANCE_REPORT;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getOnlyChapters(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.CHAPTER_LIST_ONLY + "?" + params.join('&') : URL.CHAPTER_LIST_ONLY;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    

    static generatePerformancePDF(payload) {
        return HttpClient.post(URL.BASE_URL + URL.GENERATE_PERFORMANCE_PDF, null, payload);
    }
    
    

}