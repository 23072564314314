import ServerConfigs from './server-config'

const Urls = {
    BASE_URL: ServerConfigs.url,

    IS_LOGGEDIN: '/api/student/authentication/isloggedin',
    SEND_OTP_STUDENT_SIGNUP: '/api/student/authentication/signup/step1',
    SIGNUP_STUDENT_VERIFY_OTP : '/api/student/authentication/signup/verifyotp',
    SIGNUP_STUDENT: '/api/student/authentication/signup',
    SIGNUP_STUDENT_USING_PASSCODE : '/api/student/authentication/signup/passcodesignup',
    BOARD_LIST: '/api/board/list',
    CLASS_LIST: '/api/class/list',
    STUDENT_SIGN_UP_UPDATE: '/api/student/authentication/update',
    UPDATE_PROFILE_MORE_INFO: '/api/user/profile/moreinfo',
    DEFAULT_FIRST_WORKOUT: "/api/default/first/workout",

    MULTIPLE_SIGNED_URLS: '/api/data/signUrl/multiple',
    UPDATE_PROFILE: '/api/user/profile',
    SEND_OTP_UPDATE_PROFILE: '/api/user/profile/sendotp',
    USER_TRANSFER_LIMIT: '/api/user/transferlimit',
    USER_TRANSFER_SEND_OTP: '/api/user/transfer/send/otp',
    USER_TRANSFER: '/api/user/transfer',
    FETCH_SCHOOL_FROM_SCHOOL_CODE: '/api/user/schoolconnect/school',
    SCHOOL_CONNECT_CONFIRM: '/api/user/schoolconnect/confirm',
    SCHOOL_SECTIONS: '/api/user/school/sections',
    UPDATE_INVALID_INFORMATION: "/api/user/school/invalid/info",
    EVENT_STUDENT_CERTIFICATE: '/api/user/event/studentcertificate',


    MULTIPLE_TWO_STEP_EVENT_STATUS: '/api/multiple/twostepevent/status',
    SCHOLARSHIP_FEEDBACK: '/api/scholarship/feedback',
    SCHOOL_ASSIGNMENT_FEEDBACK : "/api/assignment/feedback",
    GET_SCHOOL_ASSIGNMENT_FEEDBACK : "/api/assignment/feedback",
    SERVER_TIME: "/api/servertime",
    SCHOLARSHIP_ASSESSMENT_REPORT: '/api/user/scholarship/report',
    HOMEWORK_ASSESSMENT_CONTENTS:'/api/homework/test/contents',

    PK_CHAPTER_LIST: '/api/user/pkchapterlist',
    REFRESHER_COURSE: '/api/user/refreshercourse/status',
    DIAGNOSTIC_ASSESSMENT_STATUS: "/api/user/disgosticassessment",
    DIAGNOSTIC_ASSESSMENT_REPORT: "/api/user/refresher/course/report",
    USER_ASSESSMENT_ACTIVITY : '/api/user/assessment/activity',
    GET_MOCK_TEST_LIST : '/api/user/assessment/mocktest/list',

    REFRESHER_LEARNING: '/api/user/workout/learning/refresher/course',
    GET_ASSESSMENT_CONTENT_REFRESHER_COURSE: '/api/user/assessment/content/refresher/course',
    USER_UPDATE_ACTIVITY_REFRESHER_COURSE: '/api/user/activity/refresher/course',

    SUBSCRIBED: '/api/user/workout/subscribed',
    WORKOUT_PROGRESS: '/api/user/workout/progress',

    SCOLLARSHIP: '/api/user/scollarship/eligible',

    

    MISCELLANEOUS_LIST: '/api/miscellaneous/list',
    PUBLIC_CONTENT_DETAILS: '/api/publiccontent/details',


    MY_ASSESSMENT_INFO: '/api/user/assessment/info',
    MY_ASSESSMENT: '/api/user/assessment',
    CONTENT_LIST: '/api/content/list',
    MY_ASSESSMENT_ANSWER: '/api/user/assessment/answers',
    MY_ASSESSMENT_QUESTION_SET_INFO : '/api/user/assessment/questionsetinfo',

    USER_HOMEWORK_ACTIVITY: '/api/user/homework/activity',
    HOMEWORK_FILTER: '/api/user/homework/filter',
    HOMEWORK_STATUS: '/api/user/homework/status',
    HOMEWORK_STATUS_COMPLETED: '/api/user/homework/status/completed',
    HOMEWORK_ASSESSMENT: '/api/user/homework/assessment',
    HOMEWORK_NODE_ASSESSMENT: '/api/user/homework/nodeassessment',
    STANDARD_HOMEWORK_ANSWER: '/api/user/standard/homework/answers',
    HOMEWORK_DEEP_LINK: "/api/user/homework/deeplink",
    HOMEWORK_ELO_DETAILS: '/api/user/homework/elodetails',
    GET_RE_ENFORCEMENT_ELO_DETAILS: '/api/reenforcement/getEloContent',

    USER_PRACTICE_ACTIVITY: '/api/user/practice/activity',
    PRACTICE_STATUS: '/api/user/practice/status',
    PRACTICE_ASSESSMENT: '/api/user/practice/assessment/content',
    UPDATE_USER_PRACTICE_ACTIVITY:'/api/user/practice/update/acticity',
    ASSESS_PRACTICE: '/api/user/practice/nodeassessment',
    GET_EXAM_CASE_STUDY_PRACTICE_QUESTION : '/api/user/practice/examcasestudy',
    

    STANDARD_HOMEWORK_ASSESSMENT: '/api/user/standard/homework/assessment/content',
    UPDATE_USER_HOMEWORK_ACTIVITY: '/api/user/standard/homework/update/acticity',
    ASSESS_STANDARD_HOMEWORK: '/api/user/standard/homework/nodeassessment',

    VALIDATE_ANSWER: '/api/user/assessment/validate/answer',
    LOOKUP_LIST: '/api/lookup/list',
    GLOSSARY_DETAILS: '/api/glossary/details',



    CONFIGURATIONS_LIST: '/api/configurations/list',
    SEND_OTP: '/api/student/authentication/login/step1',
    LOGIN : "/api/student/authentication/login",
    UPDATE_LOGIN_USER: '/api/student/authentication/device/update',
    VERIFY_DEVICE: '/api/student/authentication/verify/device',
    STUDENT_LOGOUT : '/api/student/authentication/logout',
    STUDENT_CREATE_NEW_PASSCODE : "/api/student/authentication/createnewpasscode",
    LOGIN_WTIH_PASSCODE : "/api/student/authentication/loginwithpasscode",
    UPDATE_PASSCODE_LOGIN_USER: '/api/student/authentication/usingpasscode/device/update',
    CHECK_USER_PASSCODE_SET : '/api/student/authentication/checkuserpasscodeset',
    UPDATE_ID_LOGIN_USER: '/api/student/authentication/usingid/device/update',
    STUDENT_UPDATE_PASSCODE : '/api/user/updatepasscode',

    CHAPTER_LIST : '/api/user/chapterlist/v2',
    CHAPTER_LIST_ONLY : '/api/user/chapterlistonly',
    WORKSHEET_CHAPTER_LIST : '/api/user/worksheetchapterlist',
    GET_ELO_STATUS_LIST: '/api/user/elo/status',
    GET_ELO_STATUS_LIST_V2: '/api/user/elo/status/v3',
    GET_CHAPTER_PROGRESS: '/api/user/chapter/progress',
    GET_ASSESSMENT_CONTENT: '/api/user/assessment/content',
    UPDATE_USER_ACTIVITY: '/api/user/activity',
    ASSESS_NODE: '/api/user/assessment/assess',

    PRACTICE_QUESTIONS: "/api/user/practice/questions",
    CATEGORY_THEME_LIST: '/api/studentPortalThemes/list',
    PREP_KIT_PRACTICE_ACTIVITY: '/api/user/prepkit/practice/activity',
    CREATE_BOOKMARK: '/api/user/bookmarks/create',
    BOOKMARK: '/api/bookmarks',
    COUNTRY_LIST: '/api/country/list',
    GET_TRIAL_USER_APP_TIME : "/api/user/activity/userapptime",
    UPDATE_TRIAL_USER_APP_TIME: '/api/user/activity/update/userapptime',


    CITIES_LIST: '/api/cities/list',

    FETCH_SCHOOL_FROM_SCHOOL_CODE_BEFORE_LOGIN: '/api/user/schoolconnect/school/before/login',

    EVENT_LOGS: '/api/eventLogs',

    ALL_CLASS_CHAPTER_LIST: '/api/user/allclasschapterlist',
    ALL_VIDEOS_LIST: '/api/user/allvideos/list',
    ALL_ELOS_LIST: '/api/user/allelos/list',

    GET_TRANSLATED_TEXT: "/api/user/gettranslatedtext",

    USER_VIDEO_SCHOOL_ASSIGNMENT_ACTIVITY: '/api/user/videoschoolassignment/activity',

    USER_VIDEO_SCHOOL_ASSIGNMENT_ACTIVITY_USING_MOBILE_NUMBER : '/api/user/videoschoolassignment/usingmobilenumber/activity',

    STUDENT_ASSESSMENT_PERFORMANCE_REPORT : '/api/user/classmocktest/report',
    STUDENT_CHAPTER_ASSESSMENT_PERFORMANCE_REPORT : '/api/user/chapterassessment/report',

    UPDATE_PREP_KIT_INTRO : "/api/user/prepkit/intro",
    MULTIPLE_CHAPTER_PROGRESS: '/api/user/prepkit/multiplechapter/progress',
    PREP_KIT_CHAPTER_LIST: '/api/user/prepkit/chapterlist',

    PREP_KIT_PROGRESS_UPDATE: '/api/user/prepkit/progress',
    PREP_KIT_TABLE_OF_CONTENT_PROGRESS: '/api/user/prepkit/tableofcontent/progress',
    PREP_KIT_ACTIVITY: '/api/user/prepkit/resume/activity/update',
    THINGS_TO_REMEMBER_PROGRESS: '/api/user/prepkit/thingstoremember/progress',
    PREP_KIT_CONTENT_ACTIVITY: '/api/user/prepkit/content/activity',
    PREP_KIT_CONTENT_ACTIVITY_APPROACH_TO_PROBLEM_SOLVING: '/api/user/prepkit/content/activity/approachtoproblemsolving',
    PREP_KIT_PRACTICE_ACTIVITY: '/api/user/prepkit/practice/activity',
    GET_TRIAL_USER_PREPKIT_TIME: '/api/user/prepkit/time',
    PREP_KIT_PRACTICE_PROGRESS: '/api/user/prepkit/practice/progress',

    APPROACH_TO_PROBLEM_SOLVING_PROGRESS: '/api/user/prepkit/approachtoproblemsolving/progress',
    EACH_STEP_PROGRESS: '/api/user/prepkit/each/step/progress',

    CHEAT_SHEET: '/api/user/prepkit/cheatsheet',

    THINGS_TO_REMEMBER: '/api/user/thingstoremember',
    PREP_KIT_SOLVED_EXAMPLE: '/api/user/prepkit/solvedexamples',
    PREP_KIT_PRACTICE: '/api/user/prepkit/practice',
    COMMON_ERRORS: '/api/user/prepkit/common/error',
    TIPS_AND_TRICS: '/api/user/prepkit/tipsandtrics',
    APPROACH_TO_PROBLEM_SOLVING: '/api/user/prepkit/approachtoproblemsolving',
    ELO_DETAILS_APPROCH_TO_PROBLEM_SOLVING: '/api/user/prepkit/approachtoproblemsolving/elo/details',
    EXCERCISE: '/api/user/prepkit/excerise',
    NCERT_PRACTICE: '/api/user/prepkit/ncertPractice',
    PREP_KIT_SOLVED_EXAMPLE_2: '/api/user/prepkit/solvedexamples/root',

    WORKOUT_LEARNING: '/api/user/workout/learning',

    GENERATE_PERFORMANCE_PDF: '/api/user/generate/performancepdf',

    // CLAIMSIGNURL: "/api/school/signUrl",
    // COUNTINGWELL_CLASS: '/api/admin/class/list',
    // COUNTINGWELL_BOARD: '/api/admin/board/list',
     
    // CITIES_LIST: '/api/cities/list',
    // SECTION_LIST: '/api/section/list',
    // SCHOOL_LIST: '/api/school/list',
    // SKILLS_LIST: '/api/skills/list',
    // SIGN_UP: '/api/school/authentication/signup',
    // LOGIN: '/api/school/authentication/login',
    // LOGOUT: '/api/school/authentication/logout',
    // FORGOT_PASSWORD: '/api/school/authentication/forgot/password',
    // RESET_PASSWORD: '/api/school/authentication/reset/password',
    // CHANGE_PASSWORD: '/api/school/authentication/updatepassword',
    // PROFILE: '/api/school/user/profile',
    // IS_LOGGEDIN: '/api/school/user/isloggedin',
     
    // COUNTRY_LIST_ALL: '/api/school/country/list',
    // STAFF_TYPE: '/api/school/staff/types',
    // SCHOOL_CLASS: '/api/school/classandboard',
    // SCHOOL_CLASS_PUBLIC: '/api/school/classandboard/public',
    // STAFFS: '/api/school/staff',
    // EVENTS_TO_COORDINATOR: '/api/school/staffs/event',
    // CONFIRM_STAFF_PROFILE: "/api/school/staff/confirmprofile",
    // STAFFS_INVITE: '/api/school/staff/invite',
    // STUDENT_BULK_INVITE: '/api/school/user/invite/bulk',
    // STUDENT_INVITE: '/api/school/user/invite',
    // STAFFS_BULK: '/api/school/staff/bulk',
    // SECTION: '/api/school/section',
    // SECTION_PUBLIC: '/api/school/section/public',
    // SECTION_BULK: '/api/school/section/bulk',
    // SECTION_BULK_TEACHER: '/api/school/section/bulkbyteacher',
    // ASSIGN_HOMEWORK: "/api/school/event/homework",
    // ASSIGN_HOMEWORK_V2: "/api/school/homework/onetab",
    // HOMEWORK_SCHOOL_DETAILS: "/api/school/homework/schooldetails",
    // SEND_HOMEWORK_NOTIFICATION: "/api/school/homework/notify/students",
    // SCHOOL_USERS: '/api/school/schoolusers',
    // SCHOOL_USERS_BULK_SECTION_ASSIGN: "/api/school/schoolusers/section/bulk",
    // STUDENTS_LIST: '/api/school/users',
    // SCHOOL_USERS_BULK: '/api/school/schoolusers/bulk',
    // CHAPTER_LIST: '/api/school/chapterlist',
    // ELO_LIST: "/api/school/elolist",
    // HOMEWORK: "/api/school/homework",
    // HOMEWORK_DETAIL_FOR_LINK: "/api/school/homework/detail/link",
    // CREATE_HOMEWORK_FOR_LINK: "/api/school/homework/create/link",
    // ADAPTIVE_HOMEWORK: "/api/school/homework/adaptive",
    // HOMEWORK_DEEP_LINK: "/api/school/homework/deeplink",
    // HOMEWORK_REMINDER: "/api/school/homework/reminder",
    // WORKFLOW_NODES: "/api/school/workflownodes",
    // SCHOOL: "/api/school",
    // HOMEWORK_DOWNLOAD_REPORT: '/api/school/homework/report/pdf',
    // HOMEWORK_COMPLETION_REPORT: '/api/school/homework/completionreport',
    // HOMEWORK_EVALUATION_REPORT: '/api/school/homework/evaluationreport',
    // HOMEWORK_LEVEL_PROGRESSION_REPORT: '/api/school/homework/level/progression/report',
    // HOMEWORK_STUDENT_DETAIL_REPORT: '/api/school/homework/studentdetailreport',
    // HOMEWORK_WRONG_ANSWERS_REPORT: '/api/school/homework/mostwronganswers',
    // HOMEWORK_ALL_QUESTIONS_REPORT: '/api/school/homework/homeworkallquestionsreport',
    // HOMEWORK_STATUS_WRONG_ANSWERS_REPORT: '/api/school/homework/status/mostwronganswers',
    // HOMEWORK_ELO_REPORT: '/api/school/homework/eloreport',
    // HOMEWORK_ELO_REPORT_STUDENT_LIST: '/api/school/homework/eloreport/studentlist',
    // GET_IMD_REPORT: '/api/school/user/imd/report',
    // GET_IMD_STUDENT_LIST: '/api/school/user/imd/student/list',
    // GET_IMD_STUDENT_EVALUATION_REPORT: '/api/school/user/imd/student/evaluation/report',
    // GET_IMD_NATIONAL_AVERAGE: '/api/school/user/imd/student/national/average',
    // GET_IMD_PERFORMANCE_REPORT: '/api/school/user/imd/event/skill/wise/report',
    // GET_IMD_PERFORMANCE_LIST: '/api/school/user/imd/event/skill/wise/student/list',
    // GET_IMD_WINNERS_LIST: '/api/school/user/imd/winners/list',
    // DOWNLOAD_ALL_PERFORMANCE_REPORTS: '/api/school/imd/download/reports',
    // DOWNLOAD_ALL_CERTIFICATES: '/api/school/imd/download/certificates',
    // DOWNLOAD_CERTIFICATE: '/api/school/imd/download/certificate',
    // DOWNLOAD_TEACHER_CERTIFICATE: '/api/school/teacher/imd/download/certificate',
    // SCHOOL_PDF_REPORT: '/api/school/event/report',
    // ADD_EVENT_TO_SCHOOL: '/api/school/event/add',
    // GET_EVENT_REPORT: '/api/school/user/event/report',
    // GET_EVENT_CONSOLIDATED_REPORT: '/api/school/user/event/report/consolidated',
    // GET_SURVEY_REPORT: '/api/school/survey/report',
    // GET_EVENT_STUDENT_LIST: '/api/school/user/event/student/list',
    // GET_EVENT_STUDENT_EVALUATION_REPORT: '/api/school/user/event/student/evaluation/report',
    // GET_EVENT_NATIONAL_AVERAGE: '/api/school/user/event/student/national/average',
    // GET_EVENT_PERFORMANCE_REPORT: '/api/school/user/event/event/skill/wise/report',
    // GET_EVENT_PERFORMANCE_LIST: '/api/school/user/event/event/skill/wise/student/list',
    // GET_EVENT_AGGREGATE_BY_CLASS: '/api/school/user/event/aggregate/report/class',
    // GET_EVENT_WINNERS_LIST: '/api/school/user/event/winners/list',
    // STAFF_QUICK_SURVEY: '/api/school/staff/quicky/survey',
    // GET_EVENTS: '/api/school/events',
    // GET_EVENTS_LIST: '/api/school/events/list',
    // USER_SCHOOL_APPROVAL_STATUS_UPDATE: '/api/school/user/approval/status',
    // USER_SCHOOL_APPROVAL_BULK_UPDATE: '/api/school/user/approval/status/bulk',
    // BULK_ASSIGN_CLASS_SECTION: '/api/school/user/assign/classsection/bulk',

    // STUDENTS: '/api/users/list',
    // HOMEWORK_DETAIL: '/api/school/homework/details',
    // CHAPTER_HIERARCHY: '/api/school/homework/chapterhierarchy',
    // CHAPTER_HIERARCHY_SEARCH: '/api/school/homework/chapterhierarchy/search',
    // HOMEWORK_ELO_DETAILS: '/api/school/homework/elodetails',

    // GET_COUPONS: '/api/coupon/list',
    // GET_COUPON_DETAILS: "/api/school/coupon/detail",
    // SUBSCRIPTION_PLAN_LIST: '/api/subscriptionplan/list',

    // UPDATE_SCHOOL: '/api/school/update',
    // UPDATE_SCHOOL_CLASS: '/api/school/update/class',
    // UPDATE_SCHOOL_FOR_SUPER_ADMIN: '/api/school/update/super',
    // SCHOOL_LIST_FOR_ADMIN: '/api/school/list/super',

     

    // ALL_STAFF_TYPES: '/api/school/staff/types/all',
    // SCHOOL_TEMP: '/api/school/temp/create',
    // SCHOOL_TEMP_SIGNUP_OTP: '/api/school/temp/otp',
    // SCHOOL_USERS_SIGNUP_OTP: '/api/school/staff/otp',
    // SCHOOL_USERS_SIGNUP: '/api/school/staff/signup',
    // APPROVE_SCHOOL_USER: '/api/school/staff/approve',

    // UPDATE_INVITE: '/api/school/user/update/invite',
    // SEND_INVITATION: '/api/school/user/send/invitation',
    // SEND_PAT_NOTIFICATION: '/api/school/pat/notification',
    // SEND_TEACHER_INVITATIONS: '/api/school/user/send/teacher/invitations',
    // SEND_STUDENT_INVITATIONS: '/api/school/user/send/student/invitations',
    // SEND_EXISTING_STUDENT_INVITATIONS: '/api/school/user/send/existing/student/invitations',

    // MISC: "/api/school/misc",
    // STAFF_SCHOOL_DETAILS: "/api/school/staff/details",

    // TEST: "/api/school/test",
    // TEST_QUESTION_LIST: "/api/school/test/questions",
    // DIAGNOSTIC_QUESTION_LIST: "/api/school/diagnostic/questions",
    // TEST_COMPLETION_REPORT: "/api/school/test/report/completion",
    // TEST_RESULT: '/api/school/test/report/result',
    // TEST_WRONG_ANSWERS_REPORT: "/api/school/test/mostwronganswers",
    // TEST_STATUS_WRONG_ANSWERS_REPORT: '/api/school/test/status/mostwronganswers',
    // TEST_STUDENT_DETAIL_REPORT: "/api/school/test/stduentdetail",
    // TEST_ALL_QUESTIONS_REPORT: "/api/school/test/allquestions",

    // SCHOOL_USERS_UPGRADED: "/api/school/schoolusers/alreadyupgraded",
    // GET_DISCOUNT_DETAILS: "/api/web/users/claim/discount",
    // CLAIM_DETAILS: "/api/school/users/claim/details",

    // USER_ASSESSMENT_ACTIVITY: "/api/school/user/assessment/activity",
    // SCHOOL_CHAPTER_DETAIL_REPORT: "/api/school/chapters/report",
    // SCHOOL_ASSESSMENT_QUESTION_SET: "/api/school/assessment/question/set",
    // SCHOOL_QUESTION_SET_QUESTION: "/api/school/assessment/question/set/question",
    // GET_SCHOOL_QUESTION_SET: "/api/school/assessment/question/set/content",
    // PRACTICE_QUESTION_COMPLEXITY: "/api/school/practicequestioncomplexity",

    // REWARD: "/api/school/reward",
    // REWARD_ACTIVITY: "/api/school/reward/activity",
    // VOUCHERS: "/api/school/vouchers",
    // VOUCHER_PURCHASE_HISROTY: "/api/school/vouchers/history",

    // VIDEO_CONTENT_SEARCH: "/api/school/learningresources/chapter/videolearningcontent/search"

};

export default Urls;
