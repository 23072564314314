import React from 'react';
import { Redirect, Router, Switch } from 'react-router-dom'
import Route from '../home/components/routes/route-wrapper';
import './layout.scss'
import SideBar from './sideBar-v2/sideBar'
import getRoutes from '../routes/routes'
import Header from '../home/components/common/header/header-component'
import RoutePaths from '../../src/home/components/routes/routes-path'
import SessionCache from '../home/components/routes/session-cache';
import SharedProfile from '../shared-storage/profile';
import BasicPlanFeaturesPopup from './basic-plan-features/basic-plan-features';
import CommonApi from '../home/apis/common';
import AsyncStore from '../shared-storage/async-store';
import CwConfirmationModal from '../home/components/common/cw-confirmation-modal/cw-confirmation-modal';
import SetNewPasscode from './set-new-passcode-screen/set-new-passcode';
import CustomToaster from '../home/components/common/custom-toaster/custom-toaster';
import HomeComponent from '../home/components/home-component/home-component';
import MyAssessments from '../home/components/my-assessments/my-assessments';
import FireBaseEvent from '../home/firebase-events/firebase-events'
import { FirebaseEvents, Constants } from '../home/constants/constants';
import AuthenticateAPI from '../home/apis/authenticate';
import Path from '../../src/home/components/routes/routes-path';
import Loading from '../home/components/common/loading/loading';
import LayoutShared from '../home/components/common/layout-shared/layout-shared';



let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}


var eventName;
var visible = true;
var propName = "hidden";
if (propName in document) eventName = "visibilitychange";
else if ((propName = "msHidden") in document) eventName = "msvisibilitychange";
else if ((propName = "mozHidden") in document) eventName = "mozvisibilitychange";
else if ((propName = "webkitHidden") in document) eventName = "webkitvisibilitychange";

// let isVisible = null

export default class Layout extends React.Component {


    state = {
        showTrialFeatures: this.props.location?.state?.showTrialFeatures ? this.props.location?.state?.showTrialFeatures : false,
        showReducedTimePopup: false,
        showSetNewPassCodeToast: false,
        isLogout: false,
        isLoading: false,
        hideSideBar: false,
        isReload: true
    }

    tick = 0
    currentDate = new Date().toDateString().split(" ").join("_")

    static userAppTimeInfo = null
    static currentTick = 0
    static isUserSubscribed = false

    constructor(props) {
        super(props)

        this.userInfo = SharedProfile.getProfile()

        console.log(this.userInfo)

        this.routes = getRoutes(this.userInfo)

        console.log(this.routes)

        this.handleAppStateChange('active')

        LayoutShared.setLayout(this)
    }


    componentDidMount() {
        //  document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
        // window.addEventListener("focus", this.handleVisibilityChange, false);
        // document.addEventListener("blur", this.handleVisibilityChange, false)
        // window.addEventListener("blur", this.handleVisibilityChange, false)
        // window.requestIdleCallback(this.idleCallback, { timeout: 30000 })
        // this.handleAppStateChange("active")
        if (eventName) document.addEventListener(eventName, this.handleChange);

        if ("onfocusin" in document) document.onfocusin = document.onfocusout = this.handleChange; //IE 9
        window.onpageshow = window.onpagehide = window.onfocus = window.onblur = this.handleChange;// Changing tab with alt+tab

        // Initialize state if Page Visibility API is supported
        if (document[propName] !== undefined) this.handleChange({ type: document[propName] ? "blur" : "focus" });


    }

    toggleSidebar = (value, disableRoute) => {
        console.log(value, disableRoute, this.props.history.location.pathname)
        if (disableRoute) {
            let routes = this.routes

            routes.forEach(route => {
                if (disableRoute !== "/student/home") {
                    if (route.path !== disableRoute && disableRoute === this.props.history.location.pathname) {
                        route.disabled = true
                    }
                } else {
                    route.disabled = false
                }
            })

            this.routes = routes
        }


        this.setState({ hideSideBar: value, isReload: false }, () => {
            this.setState({ isReload: true })
        })

    }



    handleAppStateChange = async (nextAppState) => {

        console.log(nextAppState)

        let doc = await AsyncStore.getAppTime()
        let timeUpWarningDoc = await AsyncStore.getTimeUpWarning()

        //console.log(doc, timeUpWarningDoc)

        if (timeUpWarningDoc)
            timeUpWarningDoc = JSON.parse(timeUpWarningDoc)

        this.setState({ doc: doc })

        let reducedTimeInfo = await AsyncStore.getReducedTimePopup();

        let dailyLimitInfo = await AsyncStore.getDailyLimitPopupInfo();

        if (((nextAppState == Constants.APP_STATE_BACKGROUND) || (nextAppState == Constants.APP_STATE_IN_ACTIVE))) {

            this.stopTimer(this.tick, doc, this.currentDate);
            clearInterval(this.timerInterval)

        } else if (nextAppState == Constants.APP_STATE_ACTIVE) {

            // if (HomeworkShared.isHomePageActive()) {
            //     HomeworkShared.setHomeworkPageStatus(false)
            //     return
            // }
            //console.log('inside 1')

            CommonApi.getUserAppTime({}).then((res) => {

                /** Assessment remaining time handling start */
                // let assessmentEngine = AssessmentEngineShared.getAssessmentEngine()

                // if (assessmentEngine && assessmentEngine.handleBackgroundTimer && AssessmentEngineShared.getIsBackgroundFromAssessment()) {
                //     assessmentEngine.handleBackgroundTimer(res.currentServerTime)
                //     AssessmentEngineShared.setIsBackgroundFromAssessment(false)
                // }
                /** Assessment remaining time handling end */
                //console.log(res)

                let user = res;

                Layout.userAppTimeInfo = res

                this.currentDate = `${user && user.appTime && user.appTime ? user.appTime.date : this.currentDate}_${user && user._id ? user._id : ""}`;


                if (timeUpWarningDoc && timeUpWarningDoc[`${this.currentDate}`]) {
                    this.state.timeupWarningShowed = true
                }

                let userInfo = this.userInfo;

                userInfo.remainingDays = user && user.remainingTrialDays ? user.remainingTrialDays : 0

                SharedProfile.setProfile(userInfo);

                // if (this.pushNotificationData) {
                //     this.handleTrialUserNotification(dailyLimitInfo);
                // }


                let dailyLimitInfoParsed = dailyLimitInfo ? JSON.parse(dailyLimitInfo) : ""

                /** DEEP LINK HANDLING BASIC USER */
                // if (!this.userInfo.isSubscribed && (!dailyLimitInfoParsed || !dailyLimitInfoParsed[this.currentDate])) {
                //     this.handleDeepLinks()
                // }

                // if (this.branchParams && this.branchParams && this.branchParams["show"] == Constants.DEEP_LINK_WORKOUT && !this.userInfo.isSubscribed) {
                //     this.getWorkoutStatus(dailyLimitInfoParsed)
                // }

                // if (this.branchParams && this.branchParams && this.branchParams["show"] == Constants.DEEP_LINK_MPL && !this.userInfo.isSubscribed) {
                //     this.getMPLEventStatus(dailyLimitInfoParsed)
                // }

                // if (this.branchParams && this.branchParams && this.branchParams["show"] == Constants.DEEP_LINK_THREE_STEP_EVENT && !this.userInfo.isSubscribed) {
                //     this.getEventStatus(dailyLimitInfoParsed)
                //     this.getRewardEventStatus(dailyLimitInfoParsed)
                //     // this.getTwoStepEventStatus(dailyLimitInfoParsed)
                // }

                // if (this.branchParams && this.branchParams && this.branchParams["show"] == 'MONTHLY_MATHS_MARATHON' && !this.userInfo.isSubscribed) {
                //     this.getEventStatus(dailyLimitInfoParsed)
                //     this.getRewardEventStatus(dailyLimitInfoParsed)
                //     // this.getTwoStepEventStatus(dailyLimitInfoParsed)
                // }

                //console.log("Called 1")

                this.setState({ userTime: user && user.appTime ? user.appTime : {}, user: user }, () => {

                    //console.log("Called")
                    if (doc && doc[this.currentDate]) {
                        this.tick = doc[this.currentDate].time
                        Layout.currentTick = doc[this.currentDate].time
                    }

                    if (this.state.userTime && (this.state.userTime.time > this.tick)) {
                        this.tick = this.state.userTime.time
                        Layout.currentTick = this.state.userTime.time
                    }

                    FireBaseEvent(FirebaseEvents.SESSION_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
                    // Facebook.logCustomEvent(FirebaseEvents.SESSION_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
                    // BranchIO.logCustomEvent(FirebaseEvents.SESSION_START, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });

                    if (!user.isSubscribed && user.remainingTrialDays == 0 && user.showReducedTimePopup) {

                        if (reducedTimeInfo) {
                            reducedTimeInfo = JSON.parse(reducedTimeInfo)

                            if (!reducedTimeInfo[`${user.mobileNumber}_${user.username}`]) {

                                reducedTimeInfo[`${user.mobileNumber}_${user.username}`] = true

                                AsyncStore.setReducedTimePopup(reducedTimeInfo)

                                this.setState({ showReducedTimePopup: true })
                            } else {
                                this.timer(user, doc, nextAppState, dailyLimitInfo)
                            }
                        } else {

                            AsyncStore.setReducedTimePopup({ [`${user.mobileNumber}_${user.username}`]: true })
                            this.setState({ showReducedTimePopup: true })
                        }
                    } else {
                        this.timer(user, doc, nextAppState, dailyLimitInfo)
                    }

                    if (this.learningTime) {
                        clearInterval(this.learningTime)
                    }

                    //console.log(user.nextDayStartsIn)

                    if (!user.isSubscribed && user.nextDayStartsIn) {
                        this.state.nextDayStartsIn = user.nextDayStartsIn
                        this.getTimeReducerForLearningTime()
                    }
                })


            }).catch(err => {
                if (err) {
                    //console.log(err)
                    this.setState({ loading: false })
                    return;
                }
            })
        }
    }

    handleChange = (evt) => {
        //console.log(evt, propName)
        evt = evt || window.event;
        if (visible && (["blur", "focusout", "pagehide"].includes(evt.type))) {
            visible = false;
            console.log("Out...")
            this.handleAppStateChange('background')
        }
        else if (!visible && (["focus", "focusin", "pageshow"].includes(evt.type))) {
            visible = true;
            console.log("In...")
            this.handleAppStateChange("active")
        }
    }


    startTimer = (tick, document, currentDate) => {

        if (document) {

            let doc = JSON.parse(document)

            if (!doc[currentDate]) {

                doc[currentDate] = {
                    date: currentDate,
                    time: tick
                }

                AsyncStore.setAppTime(doc)

            } else {
                doc[currentDate] = {
                    date: currentDate,
                    time: tick
                }
                AsyncStore.setAppTime(doc)
            }
        }
        else {

            let obj = {
                [`${currentDate}`]: {
                    date: currentDate,
                    time: tick
                }
            }

            AsyncStore.setAppTime(obj)
        }

    }

    stopTimer = (tick, document, currentDate, timeExceed = false, loggedOut = false) => {

        console.log(this.state.userTime)

        // let sessionUsage = 0, sessionTime = 0

        let sessionTime = this.tick - this.state.userTime.time

        let sessionUsage = Math.floor(sessionTime / 60) + ":" + (sessionTime % 60)

        // if (this.state.userTime?.time) {
        //     sessionTime = this.tick - this.state.userTime.time
        //     sessionUsage = Math.floor(sessionTime / 60) + ":" + (sessionTime % 60)
        // }

        FireBaseEvent(FirebaseEvents.SESSION_END, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, timeSpent: sessionUsage, plan: this.userInfo.isSubscribed ? Constants.PREMIUM_PLAN : Constants.BASIC_PLAN });
        // Facebook.logCustomEvent(FirebaseEvents.SESSION_END, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, timeSpent: sessionUsage, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });
        // BranchIO.logCustomEvent(FirebaseEvents.SESSION_END, { name: this.userInfo.name, phone: this.userInfo.mobileNumber, countryCode: this.userInfo.countryCode, gender: this.userInfo.gender, class: this.userInfo.class, timeSpent: sessionUsage, plan: this.userInfo.isSubscribed ? constants.PREMIUM_PLAN : constants.BASIC_PLAN });

        if (document) {

            let doc = JSON.parse(document)

            if (!doc[currentDate]) {

                doc[currentDate] = {
                    date: currentDate,
                    time: tick
                }

                AsyncStore.setAppTime(doc)

            } else {
                doc[currentDate] = {
                    date: currentDate,
                    time: tick
                }
                AsyncStore.setAppTime(doc)

            }
        }
        else {

            let obj = {
                [`${currentDate}`]: {
                    date: currentDate,
                    time: tick
                }
            }

            AsyncStore.setAppTime(obj)

        }

        let payload = {
            sessionTime,
            time: tick,
            timeExceed,
            loggedOut
        }

        CommonApi.updateUserAppTime(payload).then((res) => {

        }).catch(err => {
            if (err) {
                //console.log(err)
                return;
            }
        })

    }

    getTimeReducerForLearningTime = () => {
        this.learningTime = setInterval(() => {

            if (this.state.nextDayStartsIn == 0) {
                if (this.learningTime) {
                    clearInterval(this.learningTime)

                    if (this.state.showBlur) {
                        this.setState({ showBlur: false, timeExceed: false, loading: true })
                        this.initAppStateListener()
                    } else {
                        this.state.nextDayStartsIn = 86400 /** 24 hours */
                        this.getTimeReducerForLearningTime()
                    }

                    return
                }
            }

            this.setState({ nextDayStartsIn: this.state.nextDayStartsIn - 1 })

        }, 1000)
    }

    pauseUserAppTime = () => {

        this.pausedTheTimer = true

        if (this.timerInterval)
            clearInterval(this.timerInterval)
    }

    // async resumeUserAppTime() {

    //     this.pausedTheTimer = false

    //     const { currentState } = AppState;
    //     let dailyLimitInfo = await AsyncStore.getDailyLimitPopupInfo();
    //     this.timer(this.state.user, this.state.doc, currentState, dailyLimitInfo);

    // }

    getRemainingAppTime = () => {

        if (this.state.user) {
            return this.state.user.allowedTime - this.tick
        }
    }


    timer = (user, doc, nextAppState, dailyLimitInfo) => {

        if (!user.isSubscribed && this.tick == 0) {
            this.setState({ showStartTimeToaster: true })
        }

        if (!user.isSubscribed && this.tick >= user.allowedTime) {

            if (dailyLimitInfo) {

                dailyLimitInfo = JSON.parse(dailyLimitInfo)

                if (!dailyLimitInfo[this.currentDate]) {

                    dailyLimitInfo[this.currentDate] = true

                    AsyncStore.setDailyLimitPopupInfo(dailyLimitInfo)

                    this.setState({
                        showPopup: true,
                        showSubscriptipnPopup: false,
                        showModal: false,
                        showExploreApp: false,
                        showTrialFeatures: false,
                        showClassUpgraded: false,
                        showUpgradeOption: false,
                        timeExceed: true,
                        showBlur: true,
                        loading: false
                    })

                } else {
                    this.setState({
                        showSubscriptipnPopup: false,
                        showModal: false,
                        showExploreApp: false,
                        showTrialFeatures: false,
                        showClassUpgraded: false,
                        showUpgradeOption: false,
                        timeExceed: true,
                        showBlur: true,
                        loading: false
                    })
                }
            } else {

                AsyncStore.setDailyLimitPopupInfo({ [this.currentDate]: true })

                this.setState({
                    showPopup: true,
                    showSubscriptipnPopup: false,
                    showModal: false,
                    showExploreApp: false,
                    showTrialFeatures: false,
                    showClassUpgraded: false,
                    showUpgradeOption: false,
                    timeExceed: true,
                    showBlur: true
                })
            }

            return

        } else {


            // if (!this.showFirstPage)
            //     this.getOverlayHelpShowed()

            if (this.timerInterval) {
                clearInterval(this.timerInterval)
            }

            // console.log(this.timerInterval)

            this.timerInterval = setInterval(() => {

                // //console.log(this.tick)

                if (!user.isSubscribed && this.tick >= user.allowedTime) {
                    if (dailyLimitInfo) {

                        if (typeof dailyLimitInfo != 'object')
                            dailyLimitInfo = JSON.parse(dailyLimitInfo)

                        if (!dailyLimitInfo[this.currentDate]) {

                            dailyLimitInfo[this.currentDate] = true

                            AsyncStore.setDailyLimitPopupInfo(dailyLimitInfo)

                            if (this.state.timeupWarningPopup) {

                                this.setState({ timeupWarningPopup: false }, () => {
                                    this.setState({
                                        showPopup: true,
                                        showSubscriptipnPopup: false,
                                        showModal: false,
                                        showExploreApp: false,
                                        showTrialFeatures: false,
                                        showClassUpgraded: false,
                                        showUpgradeOption: false,
                                        timeExceed: true,
                                        showBlur: true
                                    })
                                })

                            } else
                                this.setState({
                                    showPopup: true,
                                    showSubscriptipnPopup: false,
                                    showModal: false,
                                    showExploreApp: false,
                                    showTrialFeatures: false,
                                    showClassUpgraded: false,
                                    showUpgradeOption: false,
                                    timeExceed: true,
                                    showBlur: true
                                })

                        }
                    } else {

                        AsyncStore.setDailyLimitPopupInfo({ [this.currentDate]: true })

                        if (this.state.timeupWarningPopup) {

                            this.setState({ timeupWarningPopup: false }, () => {
                                this.setState({
                                    showPopup: true,
                                    showSubscriptipnPopup: false,
                                    showModal: false,
                                    showExploreApp: false,
                                    showTrialFeatures: false,
                                    showClassUpgraded: false,
                                    showUpgradeOption: false,
                                    timeExceed: true,
                                    showBlur: true
                                })
                            })

                        } else
                            this.setState({
                                showPopup: true,
                                showSubscriptipnPopup: false,
                                showModal: false,
                                showExploreApp: false,
                                showTrialFeatures: false,
                                showClassUpgraded: false,
                                showUpgradeOption: false,
                                timeExceed: true,
                                showBlur: true
                            })
                    }

                    this.stopTimer(this.tick, doc, this.currentDate, true);
                    // moengage.logCustomEvent('Daily Limit Exhaust', { 'User Id': this.userInfo.userId })
                    FireBaseEvent('Daily Limit Exhaust', { 'User Id': this.userInfo.userId })
                    // Facebook.logCustomEvent('Daily Limit Exhaust', { 'User Id': this.userInfo.userId })
                    // BranchIO.logCustomEvent('Daily Limit Exhaust', { 'User Id': this.userInfo.userId })

                    clearInterval(this.timerInterval)
                    return

                } else {

                    if (!user.isSubscribed && user.showWarningMessage && !this.state.showBlur && (user.allowedTime - this.tick) < (user.showWarningMessage * 60) && !this.state.timeupWarningShowed) {
                        this.setState({ timeupWarningPopup: true, timeupWarningShowed: true })
                    }

                    this.setState({ loading: false })

                    this.tick += 1;
                    Layout.currentTick += 1;

                    // //console.log("currentTick", this.tick)

                    if (this.tick % 5 == 0 && nextAppState == Constants.APP_STATE_ACTIVE) {
                        this.startTimer(this.tick, doc, this.currentDate);
                    }

                }


            }, 1000)

        }


        // if (this.userInfo && (this.userInfo.showClassUpgraded || this.userInfo.showClassUpgradedBySchool)) {
        //     this.setState({ showClassUpgraded: true })
        //     return
        // }
        // if (this.userInfo && this.userInfo.showUpgradeOption) {
        //     this.setState({ showUpgradeOption: true })
        //     return
        // }

        // if (this.showFirstPage) {

        //     if (this.isPushNotificationNavigation || this.branchParams || this.isBranchNavigation)
        //         return;

        //     this.props.navigation.navigate("AppFirstPage")
        //     this.showFirstPage = false
        // }
    }

    handleVisibilityChange = () => {

        // //console.log(document[hidden])
        // //console.log(document.visibilityState)

        // if (this.state.isVisible && document.visibilityState === 'visible') {
        //     //console.log("second time")
        //     return
        // }

        if (document.visibilityState === 'visible') {
            //console.log("visible")
            this.setState({ isVisible: true })
            // document.title = "Active"
            // this.handleAppStateChange('active')
        } else {
            //console.log("hideen")
            this.setState({ isVisible: false })
            // document.title = "Hidden"
            // this.handleAppStateChange('background')
        }
    }

    // initAppStateAndListen = async () => {

    //     let reducedTimeInfo = await AsyncStore.getReducedTimePopup()

    //     CommonApi.getUserAppTime().then(res => {

    //         //console.log(res)

    //         let user = res

    //         if (!user.isSubscribed && user.remainingTrialDays == 0 && user.showReducedTimePopup) {

    //             if (reducedTimeInfo) {
    //                 reducedTimeInfo = JSON.parse(reducedTimeInfo)

    //                 if (!reducedTimeInfo[`${user.mobileNumber}_${user.username}`]) {

    //                     reducedTimeInfo[`${user.mobileNumber}_${user.username}`] = true

    //                     AsyncStore.setReducedTimePopup(reducedTimeInfo)

    //                     this.setState({ showReducedTimePopup: true })
    //                 } else {
    //                     //console.log('Reduced time limit')
    //                     // this.timer(user, doc, nextAppState, dailyLimitInfo)
    //                 }
    //             } else {
    //                 AsyncStore.setReducedTimePopup({ [`${user.mobileNumber}_${user.username}`]: true })
    //                 this.setState({ showReducedTimePopup: true })
    //             }
    //         } else {
    //             //console.log('Reduced time limit 11')
    //             // this.timer(user, doc, nextAppState, dailyLimitInfo)
    //             // this.showSetPasscodePopup()
    //         }

    //     }).catch(err => {
    //         //console.log(err);
    //     })


    // }


    showSetPasscodePopup() {
        if (!this.user.passcodeInfo) {
            this.setState({ showPasscodeSettingPopup: true })
        }
    }

    logout = async () => {

        let doc = await AsyncStore.getAppTime()
        this.stopTimer(this.tick, doc, this.currentDate, false, true)
        clearInterval(this.timerInterval)
        this.setState({
            isLoading: true
        });

        AuthenticateAPI.studentLogout().then((res) => {

            SharedProfile.setProfile({
                isAuthenticated: false
            });

            this.resetStaticVariables()

            this.setState({
                isLoading: false,
            });

            setTimeout(() => {
                this.setState({
                    isLogout: true,
                });
            }, 200);

        }).catch(err => {
            //console.log(err)
        })
    }

    resetStaticVariables = () => {
        Layout.userAppTimeInfo = null
        Layout.currentTick = 0
    }


    componentDidUnmount() {
        // window.onbeforeunload = null;
    }


    registerRoutes = () => {

        return (this.routes.map(route => {
            return route.subMenus ? route.subMenus.map(s => <Route exact path={s.path} component={s.component} />) :
                route.component ? <Route exact path={route.path} component={route.component} /> : ""

        }))
    }




    hideBasicPlanFeatures() {
        this.setState({ showTrialFeatures: false, showReducedTimePopup: false }, () => {
            this.showSetPasscodePopup()
        })
    }

    closeReducedTimeLimitPopup() {
        this.setState({ showReducedTimePopup: false }, () => {
            this.showSetPasscodePopup()
        })
    }

    closePasscodeSettingPopup() {
        this.setState({ showPasscodeSettingPopup: false })
    }

    goToNewPasscodeScreen() {
        this.setState({ showNewPasscodeScreen: true, showPasscodeSettingPopup: false })
    }

    hideSetNewPasscodeScreen() {
        this.setState({ showNewPasscodeScreen: false, showSetNewPassCodeToast: true }, () => {
            setTimeout(() => {
                this.setState({ showSetNewPassCodeToast: false })
            }, 4000)
        })
    }



    render() {

        let isAuthenticated = SharedProfile.isAuthenticated()
        let isVerified = SharedProfile.isVerified()

        if (this.state.showNewPasscodeScreen) {
            return (
                <SetNewPasscode mobileNumber={this.userInfo.mobileNumber}
                    countryCode={this.userInfo.countryCode}
                    userName={this.userInfo.userName}
                    hideSetNewPasscodeScreen={() => this.hideSetNewPasscodeScreen()} />
            )
        }

        return (
            <div className='layout'>
                {this.state.isLoading && <Loading></Loading>}
                {this.state.isLogout && <Redirect to={Path.LOGIN} />}
                <Header delegate={this} />
                {
                    this.state.isReload ?
                        <div className={`sideBar ${this.state.hideSideBar ? "change-side-bar-width" : ""}`}>
                            <SideBar routes={this.routes} {...this.props} changeSideBarView={this.state.hideSideBar} />
                        </div>
                        : <></>
                }

                <div className={`main-component ${this.state.hideSideBar ? "change-main-component-margin" : ""}`}>
                    <Switch>
                        {this.registerRoutes()}

                        {isAuthenticated && isVerified && <Route exact path='*' component={HomeComponent}>
                            {/* <Redirect to={RoutePaths.HOME}  /> */}
                        </Route>}

                    </Switch>
                </div>
                {/* <div>Test</div> */}
                {/* {
                    this.state.showTrialFeatures === true && !this.userInfo.isSubscribed ?
                        <BasicPlanFeaturesPopup showTrialFeatures={this.state.showTrialFeatures}
                            closeBasicPlanFeatures={() => this.hideBasicPlanFeatures()} />
                        : ''
                } */}
                {/* {
                    this.state.showReducedTimePopup === true && !this.userInfo.isSubscribed ?
                        <CwConfirmationModal showReducedTimePopup={this.state.showReducedTimePopup}
                            closeReducedTimeLimitPopup={() => this.closeReducedTimeLimitPopup()} /> : ''
                } */}
                {/* {
                    this.state.showPasscodeSettingPopup === true ?
                        <CwConfirmationModal showPasscodeSettingPopup={this.state.showPasscodeSettingPopup}
                            closePasscodeSettingPopup={() => this.closePasscodeSettingPopup()}
                            delegate={this} /> : ''
                } */}
                {
                    this.state.showSetNewPassCodeToast ?
                        <div className="custom-toast-for-new-passcode">
                            <CustomToaster toasterMessage="New Passcode Set" showImage={true} />
                        </div> : ''
                }

            </div >
        )
    }
}