import React from 'react';
import './App.scss';
import Routes from './home/components/routes/routes';
import SharedThemeCategory from './shared-storage/category-theme'
import { API_CONSTANTS } from './config/constants';
import CommonApi from './home/apis/common';
import { getStaticText } from '../src/home/utility/static-text-modal';
import SharedStaticText from '../src/shared-storage/static-text-store';
import AuthKeys from './shared-storage/auth-keys';
import OnBoardingSharedStorage from './shared-storage/onboarding'
import SharedProfile from './shared-storage/profile';
// import test from './test'

class App extends React.Component {


  constructor(props) {
    super(props);
    this.getConfigurations();
  }


  componentDidMount() {
    this.setSharedThemeCategory()
    if(window.electron) {
      console.log('inside electron,')
    }
  }

  setSharedThemeCategory() {
    CommonApi.getThemeList({ limit: 100 }).then((data) => {

      //console.log(data.response)
        if (data && data.response) {
            let response = {};

            for (let i = 0; i < data.response.length; i++) {

                let theme = data.response[i];
                response[theme.categoryThemeId] = theme;
            }
            SharedThemeCategory.setThemeCategory(response)
        }
    }).catch(err => {
      //console.log(err)
    })
}

getConfigurations = () => {

  //this.toggleLoading(true)
  let payload = {
    // configurationType: "STATIC_TEXT"
    limit: 100
  };

  CommonApi.getConfigurationsList(payload).then((data) => {
    // if (err) {
    //   this.toggleLoading(false)
    // }

    if (data && data.response && data.response.length) {

      for (let i = 0; i < data.response.length; i++) {

        let item = data.response[i]

        if (item.configurationType == 'STATIC_TEXT') {
          let contructedData = getStaticText(data.response[i].screens);
          //console.log(contructedData)
          SharedStaticText.setStaticText(contructedData);
        }

        if (item.configurationType == 'AUTH_KEYS') {
          AuthKeys.setAuthKeys(item)
        }

        if (item && item.configurationType == 'ONBOARDING_FLOW' && item.config) {
          OnBoardingSharedStorage.set('config', item && item.config)
        }
      }
    }

   // this.toggleLoading(false)
  }).catch(err => {
    //console.log(err)
    // this.toggleLoading(false)
  })
}



  render() {
    return (
      <Routes></Routes>
    );
  }


}


export default App;
