import React from "react";
import './multi-step-questions.scss';
import SharedStaticText from "../../../../shared-storage/static-text-store";
import Question from "../question/question";
import QuestionTitle from "../question/question-title/question-title";
import QuestionImage from "../cw-question-image/cw-question-image";
import { Constants } from "../../../constants/constants";
import CommonUtils from "../../../utility/common-utilities";
import SharedThemeCategory from "../../../../shared-storage/category-theme";
import { getImageSource, getThemeImageSource } from "../../../utility/image-source";
import { ImageConstants } from "../../../constants/constants";
import AssessmentFooter from "../assessment-footer/assessment-footer";
import SummarySoultions from "../summary-solution/summary-solution";
import ReactTooltip from "react-tooltip";



export default class MultiStep extends React.Component {

    state = {
        index: 0,
        content: {},
        scrollViewHeight: 0,
        showSolvedExamples: false,
        showSolution: true
    };


    staticText = {};
    constructor(props) {
        super(props);

        this.staticText = SharedStaticText.getStaticText('multiStepQuestion');
        this.correctAnswerStaticText = SharedStaticText.getStaticText('correctAnswerPopUpScreen');
        this.inCorrectAnswerStaticText = SharedStaticText.getStaticText('wrongAnswerPopUpScreen');

    }

    componentDidMount() {
        // this.questionContentToRender = this.props.content.content.steps[this.props.stepIndex ? this.props.stepIndex : this.state.index];
        // this.questionTypeToRender =
        //     this.questionContentToRender.content ?
        //         this.questionContentToRender.content.type : null;
    }

    getQuesComponent() {

        let keys = Object.keys(this.props.content);

        if (!keys.length)
            return <></>;

        let delegate = this.props.delegate ? this.props.delegate : null;
        // let answer = this.props.answer && this.props.answer.steps ? this.props.answer.steps[this.props.stepIndex] : null;
        let answer = this.props.answer ? this.props.answer : null;
        let content = this.props.content.content.steps[this.props.stepIndex ? this.props.stepIndex : this.state.index];
        content._id = this.props.content._id;
        content.unique = CommonUtils.getGUID();

        return (
            <Question content={content}
                delegate={delegate}
                answer={answer} ref={Ref => this.questionComponent = Ref}
                stepIndexs={this.props.stepIndex ? this.props.stepIndex : this.state.index}
                isMulti={true} totalSteps={this.props.totalSteps}
                showHint={this.props.showHint}
                mulipStepQuestion={this.props.content}
                isReadOnly={this.props.isReadOnly}
                categoryThemeId={this.props.categoryThemeId}
                isFromMyAssessment={this.props.isFromMyAssessment}
                origin={this.props.origin}
                isFromMyHomework={this.props.isFromMyHomework}
                attachments={this.props.attachments}
                isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer}
            />
        )
    }

    getQuesComponentForReadyOnly(index) {

        let delegate = this.props.delegate ? this.props.delegate : null;
        let answer = this.props.answer ? this.props.answer : null;
        let userAnswer = this.props.userAnswer ? this.props.userAnswer : null;
        let content = this.props.content.content.steps[index];
        content._id = this.props.content._id;
        content.unique = CommonUtils.getGUID();

        let isCorrect = answer && answer.steps[index] && answer.steps[index].status == 'CORRECT' ? true : false

        return (
            <div className="question-container">

                <div className="part-wrapper row mb-4">
                    {/* <div className="col-1 part-number">
                        <div>
                            <span className="d-inline-block multi-step-question-part-text">{`${CommonUtils.getAlphabetForIndex(index)}`}</span>
                        </div> */}

                    {/* {isCorrect ? <img className="tick-icon-in-multi-step ml-2" src={getImageSource(ImageConstants.CORRECT_ANSWER_ICON)} /> : <img className="tick-icon-in-multi-step ml-2" src={getImageSource(ImageConstants.INCORRECT_ANSWER_ICON)} />} */}
                    {/* </div> */}

                    <div className="col mt-1">
                        <Question content={content}
                            mulipStepQuestion={this.props.content}
                            // isMultiStep={true}
                            isMulti={true}
                            isReadOnly={this.props.isReadOnly}
                            answer={answer} ref={Ref => this.questionComponent = Ref}
                            categoryThemeId={this.props.categoryThemeId}
                            status={this.props.status}
                            origin={this.props.origin}
                            isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer}
                            userAnswerStatus={this.props.userAnswerStatus}
                            userAnswer={userAnswer && userAnswer.steps && userAnswer.steps.length ? userAnswer.steps[index] : null}
                            delegate={delegate}
                        />
                    </div>

                </div>




                {/* {this.props.content && this.props.content.content && this.props.content.content.steps && this.props.content.content.steps.length - 1 > index ?
                    <div className="question-separator mt-3 mb-3 d-flex flex-row justify-content-center align-items-center">
                        <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                        </div>
                    </div>
                    :
                    <></>
                } */}
            </div>
        )
    }

    openPreview = (url, type) => {
        this.setState({ previewUrl: url, imagePreview: true, previewType: type })
    }

    removeAttachment = (item) => {

        this.props.delegate && this.props.delegate.removeAttachment(item)

    }


    getAnswer = () => {
        return this.questionComponent.getAnswer();
    }

    setHeight = (height) => {
        this.setState({ scrollViewHeight: height });
        this._scrollView.scrollTo({ x: 0, y: 0, animated: true });
    }

    showSolvedExamples() {
        this.setState({ showSolvedExamples: true });
        this.props.delegate && this.props.delegate.hideSolvedExamples && this.props.delegate.hideSolvedExamples();
    }

    resetStepAns = () => {
        this.questionComponent.resetAns();
    }

    next = () => {
        //console.log('next from multi-step-question')
        this.setState({ showSolvedExamples: false }, () => {
            this.props.delegate && this.props.delegate.next && this.props.delegate.next();
        });
    }


    toggleViewSolution = () => {
        // if(this.state.status === "CORRECT") {
        //     return;
        // }
        // this.setState({ showSolution: !this.state.showSolution })
    }


    renderMultiStepQuestionInfo = (question, answer) => {

        if (question.content.isMultiStep) {

            let elements = []
            let isOverallWrong = false

            for (let i = 0; i < question.content.steps.length; i++) {

                let isCorrect = answer && answer.steps[i] && answer.steps[i].status == 'CORRECT' ? true : false

                if (!isCorrect)
                    isOverallWrong = true

                elements.push(<div key={question._id + i} >
                    <div className="part-wrapper row mb-3 align-items-center">
                        <div className="col-1">
                            <span className="multi-step-question-part-text">{CommonUtils.getAlphabetForIndex(i)}</span>
                        </div>
                        <div className="col-1 ml-1">
                            {isCorrect ? <img className="tick-icon-in-multi-step " src={getImageSource(ImageConstants.CORRECT_ANSWER_ICON)} /> : <img className="tick-icon-in-multi-step" src={getImageSource(ImageConstants.INCORRECT_ANSWER_ICON)} />}
                        </div>
                    </div>
                </div>
                )
            }

            return (
                <div className={`multi-step-question-info-container ${isOverallWrong ? "" : 'correct-bg-color'}`}>
                    <div className="multi-step-question-info-title mb-2">This question has got {question.content.steps.length} Parts:</div>
                    {elements}

                    {isOverallWrong ?
                        <div className="all-questions-wrong">
                            <div className="danger-text">Overall Incorrect <img className="tick-icon-in-multi-step ml-3" src={getImageSource(ImageConstants.INCORRECT_ANSWER_ICON)} /></div>
                        </div> :
                        <div className="correct-text">Overall Correct <img className="tick-icon-in-multi-step ml-3" src={getImageSource(ImageConstants.CORRECT_ANSWER_ICON)} /> </div>}
                </div>
            )
        } else return <></>
    }

    render() {

        // setCategoryThemeStyles(this.props.categoryThemeId)
        this.theme = SharedThemeCategory.getAllCategories(this.props.categoryThemeId)

        let content = this.props.content;
        let questionContentToRender = this.props.content.content.steps[this.props.stepIndex ? this.props.stepIndex : this.state.index];
        let questionTypeToRender =
            questionContentToRender.content ?
                questionContentToRender.content.type : null;

        //console.log(questionContentToRender)

        return (
            <div>

                {
                    !this.state.showSolvedExamples ? <>

                        {
                            !this.props.isReadOnly ?

                                <>
                                    {
                                        Constants.SEQUENCE == questionTypeToRender ||
                                            Constants.MATCHING == questionTypeToRender ||
                                            Constants.GROUPING == questionTypeToRender ? // If Sequencing question will take care of rendering multistep question

                                            <div style={{}}>

                                                {this.getQuesComponent()}

                                            </div> :
                                            <div className="multi-step-questions" style={{}} onMouseEnter={ReactTooltip.rebuild}>
                                                <div className="min-height">
                                                    <QuestionTitle 
                                                    title={content.content.title} 
                                                    queNo={this.props.quesNo ? this.props.quesNo : null} 
                                                    categoryThemeId={this.props.categoryThemeId} 
                                                    delegate={this.props.delegate} 
                                                    hideBackground={this.props.hideBackground}
                                                    isFromPracticeCaseStudy = {this.props.isFromPracticeCaseStudy}
                                                    practiceId = {this.props.practiceId} />

                                                    <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />

                                                    <div className="d-flex align-items-center mt-4">
                                                        <img className="bulb-image-size" src={getImageSource(ImageConstants.BULB_IMAGE)} />
                                                        <div className="instruction-text ml-3">
                                                            {/* {constants.MULTI_SELECT_INSTRUCTION_TEXT} */}
                                                            {this.props.isFromMyAssessment ? this.staticText.assessmentMultistepInstruction : this.staticText.instruction}
                                                        </div>
                                                    </div>


                                                    <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                                        <div className={`${(this.props.isFromMyAssessment) ? 'divider-wrapper' : ''}`}>
                                                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                                        </div>
                                                        {
                                                            this.props.isFromMyAssessment ?
                                                                <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                                                    style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }} data-tip={`This question carries ${content.content.mark} Marks`}>
                                                                    <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                                                    <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{content.content.mark}</span>
                                                                </div> : <></>
                                                        }
                                                    </div>


                                                    <div className="step-question-container" style={{}}>
                                                        {this.getQuesComponent()}
                                                    </div>

                                                    {<AssessmentFooter delegate={this.props.delegate} categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>}



                                                </div>

                                            </div>

                                    }

                                </>
                                :
                                <>
                                    {
                                        Constants.SEQUENCE == questionTypeToRender ||
                                            Constants.MATCHING == questionTypeToRender ||
                                            Constants.GROUPING == questionTypeToRender ? // If Sequencing question will take care of rendering multistep question

                                            <div style={{}}>

                                                {/* {this.getQuesComponent()} */}
                                                {
                                                    content.content && content.content.steps ?

                                                        this.getQuesComponentForReadyOnly(content.content.steps.length - 1)
                                                        // <></>
                                                        :
                                                        <></>

                                                }

                                            </div>
                                            :
                                            <div className="multi-step-questions" style={{}} >
                                                <div className="min-height">

                                                    <>
                                                        <div className="d-flex flex-row align-items-center justify-content-center mb-3">
                                                            {
                                                                this.props.userAnswerStatus ?
                                                                    <div className="correct-answer-bg d-flex">
                                                                        <div className="">
                                                                            <img className="image-size" src={getImageSource(ImageConstants.WHITE_TICK_ICON_NEW)} />
                                                                        </div>
                                                                        <div className="ml-2">
                                                                            <div className="name-header-correct">{"Correct"}</div>
                                                                            {/* <div className="sub-title">{this.correctAnswerStaticText.subTitle}</div> */}
                                                                        </div>
                                                                    </div>

                                                                    :
                                                                    <div className="incorrect-answer-bg d-flex">
                                                                        <div className="">
                                                                            <img className="image-size" src={getImageSource(ImageConstants.WHITE_CROSS_ICON)} />
                                                                        </div>
                                                                        <div className="ml-1">
                                                                            <div className="name-header text-center">{"Incorrect"}</div>
                                                                            {/* <div className="sub-title">{this.inCorrectAnswerStaticText.subTitle}</div> */}
                                                                        </div>
                                                                    </div>

                                                            }

                                                        </div>
                                                        {/* <div className="question-separator mt-3 d-flex flex-row justify-content-center align-items-center">
                                                            <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                                                <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                                            </div>
                                                        </div> */}
                                                    </>

                                                    <QuestionTitle title={content.content.title} queNo={this.props.quesNo ? this.props.quesNo : null} categoryThemeId={this.props.categoryThemeId} delegate={this.props.delegate} hideBackground={this.props.hideBackground} />

                                                    <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />

                                                    {/* <div>
                                                        {
                                                            this.renderMultiStepQuestionInfo(content, this.props.userAnswer)
                                                        }
                                                    </div> */}



                                                    {/* <div className="d-flex align-items-center mt-4">
                                                        <img className="bulb-image-size" src={getImageSource(ImageConstants.BULB_IMAGE)} />
                                                        <div className="instruction-text ml-3">
                                                            
                                                            {this.props.isFromMyAssessment ? this.staticText.assessmentMultistepInstruction : this.staticText.instruction}
                                                        </div>
                                                    </div> */}

                                                    <div className="question-separator mt-3 mb-3 d-flex flex-row justify-content-center align-items-center">
                                                        <div className={`${(this.props.isFromMyAssessment) ? 'divider-wrapper' : ''}`}>
                                                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                                        </div>
                                                        {
                                                            this.props.isFromMyAssessment ?
                                                                <div className="marks-container d-inline-flex justify-content-center align-items-center"
                                                                    style={{ borderColor: this.theme ? `${this.theme['themeColor']}` : '' }} >
                                                                    <img className="marks-image-size" src={getThemeImageSource(ImageConstants.EXAM_PAD_THEME_BASED, this.props.categoryThemeId)} />
                                                                    <span className="mark-text" style={{ color: this.theme ? `${this.theme['themeColor']}` : '' }}>{content.content.mark}</span>
                                                                </div> : <></>
                                                        }
                                                    </div>

                                                    <div className="step-questions-container">

                                                        <div>
                                                            {/* {
                                                                content.content && content.content.steps ?

                                                                    content.content.steps.map((stepContent, i) => this.getQuesComponentForReadyOnly(i))
                                                                    :
                                                                    <></>

                                                            } */}
                                                            {/* {
                                                                content.content && content.content.steps ?

                                                                    this.getQuesComponentForReadyOnly(content.content.steps.length - 1)
                                                                    // <></>
                                                                    :
                                                                    <></>

                                                            } */}



                                                        </div>
                                                    </div>

                                                    {/* <div className="mb-3">
                                                        <div className="d-flex row-width justify-content-center align-content-center">
                                                            <div className="col view-button text-center"
                                                                style={{ backgroundColor: `#6B52C8`, color: '#FFFFFF' }}>Solution</div>
                                                        </div>
                                                    </div> */}

                                                    {/* {
                                                        this.props.isReadOnly && !this.props.isMultiStep && */}
                                                    {/* <div className="d-flex mb-4">
                                                        <div className="d-flex row-width-for-solution justify-content-center align-items-center"
                                                            style={{ backgroundColor: "#F0ECFF" }}>
                                                            <div className="col view-button-for-solution text-center"
                                                                style={{ color: `${this.theme['themeColor']}` }} >Solution</div>
                                                        </div>
                                                    </div> */}

                                                    <div className="d-flex ml-3">
                                                        <div className="d-flex view-answer-button-for-solution justify-content-center align-items-center cursor-pointer"
                                                            style={{
                                                                backgroundColor: `${this.state.showSolution ? "#E1D9FF" : "#E6E8E8"}`,
                                                                color: `${this.state.showSolution ? "" : "#979C9E"}`, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"
                                                            }} onClick={this.toggleViewSolution}>
                                                            {
                                                                this.state.showSolution ? 'Solution' : 'Solution'
                                                            }
                                                            {/* <span>View Answer</span> */}
                                                            {/* <img className="arrow-size" src={getThemeImageSource(this.state.showSolution ? ImageConstants.THEME_UP_ARROW : ImageConstants.THEME_DOWN_ARROW, this.props.categoryThemeId)} /> */}
                                                        </div>
                                                    </div>

                                                    <div className="solution-answer-border mb-4"></div>

                                                    {/* } */}
                                                    {
                                                        this.state.showSolution ? <SummarySoultions forQuestion={true} solutionSteps={this.props.content.content.solutionSteps} categoryId={this.props.categoryThemeId}></SummarySoultions>

                                                            : <></>
                                                    }



                                                    {/* <div className="question-separator mt-3 mb-3 d-flex flex-row justify-content-center align-items-center">
                                                        <div className={`${(this.props.isFromMyAssessment && !this.props.isMultiStep) ? 'divider-wrapper' : ''}`}>
                                                            <img className="question-divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.props.categoryThemeId)} />
                                                        </div>
                                                    </div> */}




                                                    {/* <div className="step-question-container" style={{}}>
                                                        {this.getQuesComponent()}
                                                    </div> */}

                                                    {<AssessmentFooter userAnswerStatus={this.props.userAnswerStatus} isFromViewCorrectAnswer={this.props.isFromViewCorrectAnswer}
                                                        isReadOnly={this.props.isReadOnly}
                                                        delegate={this.props.delegate}
                                                        categoryThemeId={this.props.categoryThemeId}></AssessmentFooter>}





                                                </div>

                                            </div>

                                    }


                                </>
                        }




                        {/* {this.props.showSolvedExamplesModal && <SolvedExamplesAssessmentModal delegate={this} showModal={this.props.showSolvedExamplesModal} categoryThemeId={this.props.categoryThemeId} />} */}


                        {/* </> : <SolvedExamplesAssessment content={content} delegate={this} categoryThemeId={this.props.categoryThemeId} />} */}
                    </> : <>Show Solved Example</>}
                <ReactTooltip className="border arrow" arrowColor="#C4C4C4" ref={el => this.tooltip = el}
                    multiline={true}
                    place='right'
                    type='light' effect='solid'
                    event="mouseover mouseenter"
                    eventOff="mouseleave mouseout scroll mousewheel blur" />
            </div>
        );
    }
}