import React from "react";
import './class-details.scss';
import PillButtonSelect from "../../common/pill-button-select/pill-button-select";
import AsyncStore from "../../../../shared-storage/async-store";
import AuthenticateAPI from "../../../apis/authenticate";
import { Constants, FirebaseEvents } from "../../../constants/constants";
import SharedProfile from "../../../../shared-storage/profile";
import SharedStaticText from "../../../../shared-storage/static-text-store";
import CommonApi from "../../../apis/common";
import FirebaseEvent from '../../../firebase-events/firebase-events';

class ClassDetails extends React.Component {


    state = {
        selectedBoard: 'CBSE',
        selectedClass: 'Class 6'
    }

    constructor(props) {
        super(props)
        this.staticText = SharedStaticText.getStaticText('newClassDetailsScreen');
        this.userInfo = SharedProfile.getProfile();


        if (this.props.showSchoolDetails && this.props.schoolDetails && this.props.schoolDetails.boards && this.props.schoolDetails.classes) {

            let classes = []
            let boards = []

            for (let i = 0; i < this.props.schoolDetails.boards.length; i++) {
                this.props.schoolDetails.boards[i]['title'] = this.props.schoolDetails.boards[i].name
                boards.push(this.props.schoolDetails.boards[i])
            }

            for (let i = 0; i < this.props.schoolDetails.classes.length; i++) {
                this.props.schoolDetails.classes[i]['title'] = this.props.schoolDetails.classes[i].name
                classes.push(this.props.schoolDetails.classes[i])
            }

            if (boards.length == 1) {
                this.state.selectedBoard = boards[0].title
                this.state.singleBoard = true
            } else {
                this.state.activeBoardList = boards
            }

            this.state.schoolClasses = classes


            this.getClass(true)

        } else {
            this.getClass()
            this.getBoard();
        }
    }


    componentDidMount() {
        //console.log(this.state.activeBoardList)
        if (this.props.showSchoolDetails) {
            this.getSchoolSections()
        }
    }

    getBoard() {

        let payload = {
            limit: 100,
            sortType: 'asc',
            sortKey: 'order',
            display: "SHOW"
        }

        CommonApi.getBoard(payload).then((data) => {
            //console.log(data)

            if (data) {
                let res = data.response;
                let activeBoardList = [];
                let inActiveBoardList = [];
                for (let i = 0; i < res.length; i++) {


                    if (res[i].active === true || !res[i].hasOwnProperty("active")) {
                        activeBoardList.push(res[i])
                    }

                    else if (res[i].active === false) {
                        inActiveBoardList.push(res[i])

                    }

                }
                this.setState({ boardList: res, activeBoardList: activeBoardList, inActiveBoardList: inActiveBoardList }, () => {
                    // this.toggleLoading(false)
                })
            }
        }).catch(err => {
            //console.log(err)
            if (err) {
                // this.toggleLoading(false)
                return
            };
        })
    }

    getClass(flag) {
        //this.toggleLoading(true)

        let payload = {
            limit: 100,
            sortType: 'asc',
            sortKey: 'order',
            display: "SHOW"
        }

        let schoolClasses = []

        CommonApi.getClass(payload).then((data) => {
            //console.log(data)

            if (data) {
                let res = data.response;
                let activeClassList = [];
                let inActiveClassList = [];
                for (let i = 0; i < res.length; i++) {

                    if (res[i].active === true || !res[i].hasOwnProperty("active")) {
                        activeClassList.push(res[i])
                    }
                    else if (res[i].active === false) {
                        inActiveClassList.push(res[i].title)
                    }

                }
                if (flag) {
                    schoolClasses = res.filter(o1 => this.state.schoolClasses.some(o2 => o1.title === o2.title))

                } else {
                    schoolClasses = res
                }

                this.setState({ classList: schoolClasses, activeClassList: activeClassList, inActiveClassList: inActiveClassList })
            }
        }).catch(err => {
            if (err) { return };
        })
    }



    onPressContinue = async () => {

        let payload = {
            class: this.state.selectedClass,
            section: '',
            board: this.state.selectedBoard
        }

        //console.log(payload)

        if (this.props.showSchoolDetails && this.state.sections && this.state.sections.length) {

            let activeSec = this.state.sections.filter(item => item.active)

            if (!activeSec.length) {
                return
            }
            payload["section"] = activeSec[0].section
            payload["sectionId"] = activeSec[0]._id
        }

        if (this.props.schoolDetails.code) {
            payload.schoolId = this.props.schoolDetails.code;
        }
        // this.toggleLoading(true)
        AuthenticateAPI.signupStudentUpdate(payload).then((res) => {
            //console.log(res)
            if (this.props.showSchoolDetails) {
                this.confirmSchool()
            }else {
                this.verifyDevice()
            }
            // this.toggleLoading(false)
            
        }).catch(err => {
            //console.log(err)
            if (err) {
                // this.toggleLoading(false)
                if (err.response && err.response.data && err.response.data.code == 1600 || err.response.data.code == 1601) {
                    this.setState({ invalidOtp: true })
                }
                if (err.response && err.response.data && err.response.data.message == "Invaid schoolId") {
                    this.setState({ invalidSchoolCode: true })
                } return;
            }
        })

    }

    confirmSchool = (value) => {

        if (!this.props.schoolDetails.code) {
            return
        }

        let payload = {
            schoolCode: this.props.schoolDetails.code
        }

        if (this.props.showSchoolDetails && !this.state.sections.length) {
            payload["validateSection"] = true
        }

        CommonApi.schoolConnectConfirm(payload).then((res) => {


            // SharedDeepLink.setDeepLink(null)

            // this.setState({ showSchoolConnectPopUp: false })

            this.userInfo['schoolName'] = this.props.schoolDetails.name;
            this.userInfo['schoolLocality'] = this.props.schoolDetails.locality;
            this.userInfo['schoolVerificationCode'] = this.props.schoolDetails.code
            this.userInfo['schoolCode'] = this.props.schoolDetails.code

            if (res && res.section)
                this.userInfo['section'] = res.section

            // moengage.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, "School Updated": this.state.schoolName, "School Locality": this.state.locality })
            // FireBaseEvent.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, "School Updated": this.state.schoolName, "School Locality": this.state.locality })
            // Facebook.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, "School Updated": this.state.schoolName, "School Locality": this.state.locality })
            // BranchIO.logCustomEvent('My Profile', { 'User Id': this.userInfo.userId, "School Updated": this.state.schoolName, "School Locality": this.state.locality })


            SharedProfile.setProfile(this.userInfo)
            // //console.log(this.userInfo)
            // this.props.delegate && this.props.delegate.hideSchoolConfirmation && this.props.delegate.hideSchoolConfirmation(true)
            this.verifyDevice()
            
        }).catch(err => {
            if (err) {
                // this.props.delegate && this.props.delegate.hideSchoolConfirmation && this.props.delegate.hideSchoolConfirmation(true)
                return;
            }

        })

    }




    async verifyDevice() {

        let uniqueKey = await AsyncStore.getUniqueKey();

        let doc = await AsyncStore.getWelcomePopupInfo();

        let showTrialFeatures = false

        let payload = {
            uniqueKey: uniqueKey,
            deviceId: "STUDENT_PORTAL_DEVICE_ID",
            requestFromStudentPortal: Constants.REQUEST_FROM_STUDENT_PORTAL
        };

        // this.toggleLoading(true)

        AuthenticateAPI.verifyDevice(payload).then(async (res) => {

            //console.log(res)
            // SessionStore.sharedInstance().setSession(res.session);
            SharedProfile.setProfile(res.data.profile);

            if (doc) {
                doc = JSON.parse(doc)

                if (!doc[`${res.data.profile.mobileNumber}_${res.data.profile.userName}`]) {

                    doc[`${res.data.profile.mobileNumber}_${res.data.profile.userName}`] = true

                    AsyncStore.setWelcomePopupInfo(doc)

                    showTrialFeatures = true
                }
            } else {
                AsyncStore.setWelcomePopupInfo({ [`${res.data.profile.mobileNumber}_${res.data.profile.userName}`]: true })
                showTrialFeatures = true
            }

            FirebaseEvent(FirebaseEvents.REGISTER, { name: res.data.profile.nickName, phone: res.data.profile.mobileNumber, board: res.data.profile.alternateBoard ? res.data.profile.alternateBoard : res.data.profile.board, class: res.data.profile.class, gender: res.data.profile.gender })

            // FirebaseEvent.logCustomEvent(FirebaseEvents.REGISTER, { name: res.profile.nickName, phone: res.profile.mobileNumber, board: res.profile.alternateBoard ? res.profile.alternateBoard : res.profile.board, class: res.profile.class, gender: res.profile.gender })
            // Facebook.logCustomEvent(FirebaseEvents.REGISTER, { name: res.profile.nickName, phone: res.profile.mobileNumber, board: res.profile.alternateBoard ? res.profile.alternateBoard : res.profile.board, class: res.profile.class, gender: res.profile.gender })
            // BranchIO.logCustomEvent(FirebaseEvents.REGISTER, { name: res.profile.nickName, phone: res.profile.mobileNumber, board: res.profile.alternateBoard ? res.profile.alternateBoard : res.profile.board, class: res.profile.class, gender: res.profile.gender })
            // // Moengage.logCustomEvent('REGISTER', res.profile, true)
            // moengage.logCustomEvent('Login', { 'User Id': res.profile.userId, "First Time": 'Yes' }, res.profile, true)



            // this.toggleLoading(false)
            // const schoolCode = await AsyncStore.getSchoolCode();
            // //console.log(schoolCode);
            // if (schoolCode) {
            //     const code = JSON.parse(schoolCode);
            //     if (code.value && res.profile !== code.value) {
            //         this.props.navigation.navigate('SchoolVerify', { meta: { showTrialFeatures: showTrialFeatures, showFirstPage: true } });
            //         return;
            //     }
            // }

            // if (res && res.profile && res.profile.workflowId) {
            //     this.props.navigation.navigate('OptionScreen');
            // } else {

            // SharedDeepLink.setDeepLink(null)

            this.props.onPressContinue && this.props.onPressContinue()
            // if (!res.profile.schoolName || !res.profile.schoolLocality) {
            //     this.props.navigation.navigate('AddYourSchoolV2', { meta: { from: 'LOGIN', data: { showTrialFeatures: showTrialFeatures } } })
            // } else
            // this.props.navigation.navigate('LandingScreen', { meta: { showTrialFeatures: showTrialFeatures, showFirstPage: true } });
            // }


        }).catch(err => {
            if (err) {
                // this.toggleLoading(false)
                return;
            }
        })
    }


    onSelectPillButton(value, item, key) {
        //console.log(item)
        if (key === 'board') {
            this.setState({ selectedBoard: value, note: item.note ? item.note : '' }, () => {
                this.getSchoolSections()
            })
        }
        if (key === 'class') {
            this.setState({ selectedClass: value }, () => {
                this.getSchoolSections()
            })
        }
    }

    getSchoolSections = () => {

        //console.log(this.state.selectedBoard, this.state.selectedClass, this.props.schoolDetails)

        if (!this.props.showSchoolDetails) {
            return
        }

        if (!this.state.selectedBoard || !this.state.selectedClass) {
            return
        }

        let payload = {
            schoolCode: this.props.schoolDetails.code,
            board: this.state.selectedBoard,
            class: this.state.selectedClass
        }

        //console.log(payload)

        CommonApi.getSchoolSections(payload).then((data) => {
            this.setState({ loading: false })
            //console.log(data)
            if (data.length) {
                this.setState({ sections: data, selectedSection: '' })
            } else {
                this.setState({ sections: [], selectedSection: '' })
            }
        }).catch(err => {
            if (err) {
                this.setState({ sections: [], loading: false })
                return
            }

        })
    }

    onPressSection = (index) => {

        let sections = this.state.sections

        for (let i = 0; i < sections.length; i++) {
            sections[i].active = false
        }

        sections[index].active = true
        this.setState({ sections, showSaveActive: true, selectedSection: sections[index] })
    }






    getButtons = () => {
        return (
            !this.state.selectedClass ?
                <div className="text-center continue-button continue-button-disabled">
                    Continue
                </div>
                :
                <div className="text-center continue-button cursor-pointer" onClick={() => this.onPressContinue()}>
                    Continue
                </div>
        )

    }



    render() {
        return (
            <div>
                {
                    this.state.activeBoardList && this.state.activeBoardList.length ?
                        <div className="heading">My Board & Class</div> : <div className="heading">My Class</div>
                }

                <div className="class-details-wrapper">
                    {
                        this.state.activeBoardList && this.state.activeBoardList.length ?
                            <>
                                <div className="cw-input-label-1 mt-4">{this.staticText.boardTitle}</div>
                                <div className="mt-3">
                                    <PillButtonSelect
                                        onSelect={(value, item) =>
                                            this.onSelectPillButton(value, item, 'board')
                                        }
                                        type={'boardPillButton'}
                                        options={this.state.activeBoardList}
                                        value={this.state.selectedBoard}
                                    />
                                </div>
                            </>
                            :
                            <></>
                    }

                    <div className="cw-input-label-1 mt-3">{this.staticText.classTitle}</div>
                    <div className="academic-text mt-2"> In which class will you be in the coming academic year ?</div>
                    <div className="mt-3">
                        <PillButtonSelect
                            onSelect={(value, item) =>
                                this.onSelectPillButton(value, item, 'class')
                            }
                            type={'classPillButton'}
                            options={this.state.activeClassList}
                            value={this.state.selectedClass}
                        />
                    </div>
                    {
                        this.state.note ? <div className="note-text mt-3">{this.state.note}</div> : ''
                    }

                    {this.state.sections && this.state.sections.length ?
                        <div >
                            <div className="cw-input-label-1 mt-3">{this.staticText && this.staticText.sectionsText ? this.staticText.sectionsText : 'SECTIONS'}</div>
                            <div className="mt-3 d-flex sections-list">
                                {this.state.sections.map((section, index) =>
                                    <div className={`cursor-pointer section-wrapper ${section.active ? "active-section-bg" : ''}`} onClick={() => this.onPressSection(index)}>
                                        <div>{section.section}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        :
                        <></>
                    }


                </div>
                <div className="d-flex justify-content-center">
                    {/* <div className="text-center back-button cursor-pointer mr-5" onClick={() => this.goBackToSignupScreen()}>Back</div> */}
                    {this.getButtons()}
                </div>
            </div>
        )
    }

}


export default ClassDetails;