import React from "react";
import './practice-more-questions.scss'
import ChapterList from "../../../common/chapter-list/chapter-list";
import { Constants, ImageConstants } from '../../../../constants/constants';
import { getImageSource, getThemeImageSource } from '../../../../utility/image-source';
import PracticeQuestionsApi from '../../../../apis/practice-questions'
import CommonUtils from '../../../../utility/common-utilities'
import PracticeQuestions from "../practice-questions/practice-questions";
// import ChapterM2PracticeQuestions from './chapter-m2-practice-questions/practice-questions';
// import { Modal } from "bootstrap";
import { Modal, ModalBody } from "reactstrap";
import SharedThemeCategory from "../../../../../shared-storage/category-theme";
// import SessionCache from "../routes/session-cache";
import SharedStaticText from "../../../../../shared-storage/static-text-store";
import SharedProfile from "../../../../../shared-storage/profile";
import Path from "../../../routes/routes-path";
import HomeworkApi from "../../../../apis/homework";
import FirebaseEvent from '../../../../firebase-events/firebase-events';
import LayoutShared from "../../../common/layout-shared/layout-shared";
import MCQQuestionSetList from "../../mcq-questionset-list/mcq-questionset-list";
import PracticeApi from "../../../../apis/practice";
import PracticeExperience from '../../../practice-experience/practice-experience';
import PracticeCompletionScreen from "../../practice-completion-screen/practice-completion-screen";
import CaseStudyQuestionSetList from "../../case-study-question-set-list/case-study-question-set-list";
import STRQuestionSetList from "../../str-questionset-list/str-questionset-list";


class PracticeMoreQuestions extends React.Component {

    state = {
        searchText: '',
        reload: false,
        questions: {},
        complexities: [],
        complexitySelected: 'Low',
        fullScreenLoading: true,
        showTakeChallengePopup: false,
        showPracticedCheckBox: true,
        fromSchoolAssignmentCompletionScreen: false,
        contents: [],
        showDescriptionQuestions: false,

        // showPracticeCompletionScreen : true
    }

    constructor(props) {
        super(props);

        console.log('inside practice')

        this.userInfo = SharedProfile.getProfile()
        this.layoutShared = LayoutShared.getLayout()

        this.takeChallengePopupStaticText = SharedStaticText.getStaticText('takeChallengePopup');
        // this.getPracticeQuestions({});

        if (this.props.location && this.props.location.state && this.props.location.state.showPracticeQuestionsFromCompletionScreen) {
            let practicePayload = this.props.location.state.practiceQuestionsPayload
            this.state.chapterKey = practicePayload.chapterKey
            this.state.categoryId = practicePayload.categoryId
            this.state.conceptKeys = practicePayload.conceptKeys
            this.state.eloIds = practicePayload.eloIds
            this.state.chapterTitle = practicePayload.chapterTitle
            this.state.chapterImageUrl = practicePayload.chapterImageUrl
            this.state.fromSchoolAssignmentCompletionScreen = this.props.location.state.showPracticeQuestionsFromCompletionScreen
        }

        if (this.props.location && this.props.location.state && this.props.location.state && this.props.location && this.props.location.state && this.props.location.state.practiceQuestionsPayload
            && !this.props.location.state.practiceQuestionsPayload.eloIds.length) {
            //console.log(this.props.location.state.practiceQuestionsPayload)
            let practicePayload = this.props.location.state.practiceQuestionsPayload
            this.state.selectedChapter = {
                key: practicePayload.chapterKey, categoryId: practicePayload.categoryId,
                title: practicePayload.chapterTitle, chapterImage: practicePayload.chapterImageUrl
            }

            // this.getPracticeQuestions()


            // this.selectedChapter({
            //             key: practicePayload.chapterKey, categoryId: practicePayload.categoryId,
            //             title: practicePayload.chapterTitle, chapterImage: practicePayload.chapterImageUrl
            //         })
        }

        // //console.log(this.props.location.state)

    }

    page = {};
    totalPages = {};

    componentDidMount() {
        if (this.state.fromSchoolAssignmentCompletionScreen && this.state.eloIds.length) {
            this.selectedChapter({
                key: this.state.chapterKey, categoryId: this.state.categoryId,
                title: this.state.chapterTitle, chapterImage: this.state.chapterImageUrl
            })
            this.getEloDetails()
        }

        this.layoutShared.toggleSidebar(true)

        let chapterInfo = this.props.history.location.state?.data
        if (chapterInfo && chapterInfo.calledFrom === 'EXAM_PREP_KIT') {

            console.log(chapterInfo)
            let chapter = {
                key: chapterInfo.chapterKey,
                title: chapterInfo.chapterTitle,
                categoryId: chapterInfo.categoryThemeId,
                chapterImage: chapterInfo.imageUrl

            }
            this.selectedChapter(chapter)
        }


        // else {
        //     this.selectedChapter({
        //         key: this.state.chapterKey, categoryId: this.state.categoryId,
        //         title: this.state.chapterTitle, chapterImage: this.state.chapterImageUrl
        //     })
        // }
    }

    getEloDetails = (elos, index) => {

        let payload = {
            chapter: this.state.chapterKey,
            concepts: this.state.conceptKeys,
            elos: this.state.eloIds
        }

        if (!payload.chapter) return

        HomeworkApi.getHomeworkEloDetails(payload)
            .then(response => {
                //console.log(response)
                if (this.state.isAdaptive && elos?.length) {
                    let categories = this.state.categories
                    categories[index].questionCount = response.questionCount
                    categories[index].approximateTime = response.questionCount
                    this.setState({
                        categories,
                        contents: response.content,
                        isLoading: false
                    })
                } else {

                    this.setState({
                        questionCount: response.questionCount,
                        contents: response.content,
                        approximateTime: response.questionCount, //ask by ravi
                        isLoading: false
                    })
                }

            }).catch(err => {
            })
    }



    setSearchText(event, key) {
        this.setState({ [key]: event.target.value }, () => {
            if (!this.state.searchText) {
                this.getSearchedChapterList()
            }
        })
    }

    getSearchedChapterList() {
        this.setState({ reload: true }, () => {
            this.setState({ reload: false })
        })
    }


    goToHomeScreen() {
        return this.props.history.replace({ pathname: Path.HOME })
    }


    goToBrowseChapterSignleChapter = (chapter, index, logEvent) => {
        console.log(chapter)

        let categoryThemeId = chapter.categoryId

        if (chapter.isFinished) {
            // moengage.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": CommonUtils.getCategoryTitle(categoryThemeId), "Completed in Class": CommonUtils.getBooleanValue(true), "Chapter Status": 'Completed' })
            // FireBaseEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": chapter.title, "Chapter Category": CommonUtils.getCategoryTitle(categoryThemeId), "Completed in Class": CommonUtils.getBooleanValue(true), "Chapter Status": 'Completed' })
            // Facebook.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Completed' })
            // BranchIO.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Completed' })
        }
        else if (!logEvent) {
            // moengage.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': title, "Chapter Status": 'In Progress', "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted) })
            // FireBaseEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': chapter.title, "Chapter Status": 'In Progress', "Chapter Category": CommonUtils.getCategoryTitle(categoryThemeId), "Completed in Class": CommonUtils.getBooleanValue(true) })
            // Facebook.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': title, "Chapter Status": 'In Progress', "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted) })
            // BranchIO.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, 'Chapter Name': title, "Chapter Status": 'In Progress', "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted) })
        } else {
            // moengage.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Start' })
            // FireBaseEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": chapter.title, "Chapter Category": CommonUtils.getCategoryTitle(categoryThemeId), "Completed in Class": CommonUtils.getBooleanValue(true), "Chapter Status": 'Start' })
            // Facebook.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Start' })
            // BranchIO.logCustomEvent('Chapter', { "User Id": this.userInfo.userId, "Chapter Name": title, "Chapter Category": getCategoryTitle(categoryThemeId), "Completed in Class": getBooleanValue(isChapterNotCompleted), "Chapter Status": 'Start' })
        }

        let eloListMeta = {
            chapterKey: chapter.key,
            title: chapter.title,
            isChapterNotCompleted: true,
            categoryThemeId: chapter.categoryId,
            masterDataId: this.state.masterDataId,
            lockedForTrial: chapter.lockedForTrial,
            trialChapters: this.chaptersOpenForTrial,
            chapterImageUrl: chapter.chapterImage,
            calledFrom: 'HOME_COMPONENT',
            selectedChapterIndex: index,
        }


        this.props.history && this.props.history.push(Path.BROWSE_CHAPTER, { data: eloListMeta })

        // this.setState({
        //     showEloList: true,
        //     selectedChapterIndex: index,
        //     eloListMeta: {
        //         chapterKey: chapter.key,
        //         title: chapter.title,
        //         isChapterNotCompleted: true,
        //         categoryThemeId: chapter.categoryId,
        //         masterDataId: this.state.masterDataId,
        //         lockedForTrial: chapter.lockedForTrial,
        //         trialChapters: this.chaptersOpenForTrial,
        //         chapterImageUrl: chapter.chapterImage
        //     }
        // })
        // this.props.navigation.navigate('EloList', { meta: { chapterKey: chapter.key, title: chapter.title, isChapterNotCompleted : true, categoryThemeId: chapter.categoryId, masterDataId: this.state.masterDataId, lockedForTrial: chapter.lockedForTrial, trialChapters: this.chaptersOpenForTrial } })
        // this.props.navigation.navigate('ChapterNavigation', { meta: { chapter: chapter } })
    }




    renderBreadcrumbForChapterList = () => {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()} >{`Home >`}</span>
                <span className='title-1 pl-2'>{`Practice`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    renderBreadcrumbForPracticeQuestions() {
        return (
            <div className='breadcrumb'>
                <span className="home cursor-pointer" onClick={() => this.goToHomeScreen()}>{`Home >`}</span>
                <span className='title-1 cursor-pointer pl-2' onClick={() => this.goToPracticeQuestionsChapterList()}>{`Practice >`}</span>
                <span className='title-1 pl-2'>{`${this.state.selectedChapter?.title}`}</span>
                {/* <span className='pl-2'>{this.props.selectedItem.chapters ? this.getChapterNames(this.selectedItem?.chapterName) : this.props.selectedItem.chapter} {`>`}</span>
                    <span className='active pl-2'>{this.getDate(this.selectedItem.createdDate)}</span> */}
            </div>
        )
    }

    goToPracticeQuestionsChapterList() {
        this.setState({
            selectedChapter: '', categoryThemeId: '',
            complexitySelected: 'Low', reload: false,
            fromSchoolAssignmentCompletionScreen: false, questions: {}, complexities: [], fullScreenLoading: true,
            showDescriptionQuestions: false,
            showPracticeExperience: false
        })
        this.page = {}
        this.totalPages = {}
        this.hideMCQQuestionSetList()
        this.hideSTRQuestionSetList()
        this.hideCaseStudyQuestionSetList()
    }


    selectedChapter(chapter) {
        ////console.log(chapter)

        //console.log(this.state.fromSchoolAssignmentCompletionScreen)
        if (this.state.fromSchoolAssignmentCompletionScreen && this.state.eloIds) {
            // //console.log(chapter)
            FirebaseEvent('Practice', { 'User Id': this.userInfo.userId, Chapter: chapter.title, "Practice From": "School Assignment" })
            this.setState({ selectedChapter: chapter, categoryThemeId: chapter.categoryId }, () => {
                // this.getPracticeQuestions()
            })
        } else {
            FirebaseEvent('Practice', { 'User Id': this.userInfo.userId, Chapter: chapter.title, "Practice From": "Chapter Practice" })
            this.setState({ selectedChapter: chapter, categoryThemeId: chapter.categoryId, showPracticeExperience: false }, () => {
                this.getPracticeQuestions()
                this.hideCaseStudyQuestionSetList()
            })
        }

    }



    getPracticeQuestions(payload, complexity, append, fullScreenLoading) {

        //console.log('called')

        let reqPayload = {
            // masterDataId: this.meta.masterDataId,
            chapterKey: this.state.selectedChapter.key,
            ...payload
        }


        if (complexity) {
            reqPayload['complexity'] = complexity;
        }

        if (this.state.showPracticedCheckBox)
            reqPayload.isPracticed = true


        // if (fullScreenLoading)
        // this.setState({ fullScreenLoading: true })



        PracticeQuestionsApi.getPracticeQuestions(reqPayload).then(res => {
            //console.log(res)

            if (append) {

                let questions = this.state.questions;

                questions[res.complexitySelected] = questions[res.complexitySelected].concat(res.contents);


                this.setState({ questions: questions, complexitySelected: res.complexitySelected, complexities: res.complexities })

                this.totalPages[res.complexitySelected] = res.totalPages;
                this.page[res.complexitySelected] = !this.page[res.complexitySelected] ? 1 : this.page[res.complexitySelected] + 1

            }

            else {
                let questions = this.state.questions;

                questions[res.complexitySelected] = res.contents;


                this.setState({ questions: questions, complexitySelected: res.complexitySelected, complexities: res.complexities }, () => {

                })

                this.totalPages[res.complexitySelected] = res.totalPages;
                this.page[res.complexitySelected] = !this.page[res.complexitySelected] ? 1 : this.page[res.complexitySelected] + 1

                let complexitySelectedIndex = null

                for (let i = 0; i < res.complexities.length; i++) {

                    let complexity = res.complexities[i]

                    if (res.complexitySelected == complexity) {
                        complexitySelectedIndex = i
                    }
                }

                let practicedCount = 0

                for (let i = 0; i < res.contents.length; i++) {

                    let question = res.contents[i]

                    if (question.practiced) {
                        practicedCount += 1
                    }
                }

                if (res && res.complexities && res.complexities.length) {
                    if (practicedCount == res.contents.length) {

                        if (complexitySelectedIndex == res.complexities.length - 1) {

                            let remainingTime = this.getRemainingTime()
                            ////console.log('remainingTime....', remainingTime)

                            // this.setState({showTakeChallengePopup : true})

                            // if (this.userInfo.isSubscribed) {
                            //     this.setState({ showTakeChallengePopup: true })
                            // } else if (remainingTime >= (this.takeChallengePopupStaticText && this.takeChallengePopupStaticText.minAppTime ? this.takeChallengePopupStaticText.minAppTime : 15)) {
                            //     this.setState({ showTakeChallengePopup: true })
                            // }
                            if (this.userInfo.isSubscribed) {
                                this.setState({ showTakeChallengePopup: true })
                            } else if (remainingTime >= (this.takeChallengePopupStaticText && this.takeChallengePopupStaticText.minAppTime ? this.takeChallengePopupStaticText.minAppTime : 15)) {
                                this.setState({ showTakeChallengePopup: true })
                            }
                        }
                        else {
                            let complexitySelected = res.complexities[complexitySelectedIndex + 1]

                            this.setState({ questions: questions, complexitySelected: res.complexitySelected, complexities: res.complexities })
                            this.setState({ complexitySelected, fullScreenLoading: true })

                            this.getPracticeQuestions({}, complexitySelected, false, true)
                        }
                    }
                }
            }

        }).catch(err => {
            ////console.log(err)
        })
        // this.getGlossaryContent();
    }


    selectComplexity = (complexity) => {
        ////console.log(complexity)

        if (complexity === 'Low') {
            this.setState({ complexitySelected: complexity, contentsToRender: this.state.lowComplexityContents, selectedIndex: '' }, () => {

                if (!this.page[this.state.complexitySelected]) {
                    this.togglefullScreenLoading(true);
                    this.getPracticeQuestions({}, complexity)
                } else {
                    this.togglefullScreenLoading(true);
                }

            })


        }

        if (complexity === 'Medium') {
            this.setState({ complexitySelected: complexity, contentsToRender: this.state.lowComplexityContents, selectedIndex: '' }, () => {
                if (!this.page[this.state.complexitySelected]) {
                    this.togglefullScreenLoading(true);
                    this.getPracticeQuestions({}, complexity);
                } else {
                    this.togglefullScreenLoading(true);
                }
            })
        }

        if (complexity === 'High') {
            this.setState({ complexitySelected: complexity, contentsToRender: this.state.lowComplexityContents, selectedIndex: '' }, () => {
                if (!this.page[this.state.complexitySelected]) {
                    this.togglefullScreenLoading(true);
                    this.getPracticeQuestions({}, complexity)
                } else {
                    this.togglefullScreenLoading(true);
                }
            })
        }

        if (complexity === 'HOTS') {
            this.setState({ complexitySelected: complexity, contentsToRender: this.state.lowComplexityContents, selectedIndex: '' }, () => {
                if (!this.page[this.state.complexitySelected]) {
                    this.togglefullScreenLoading(true);
                    this.getPracticeQuestions({}, complexity)
                } else {
                    this.togglefullScreenLoading(true);
                }
            })
        }

    }


    togglefullScreenLoading() {
        this.setState({ fullScreenLoading: false }, () => {
            this.setState({ fullScreenLoading: true })
        })
    }

    hideChallengePopup() {
        this.setState({ showTakeChallengePopup: false })
    }


    getSelectedIndex(index) {
        // //console.log(index)
        this.setState({ selectedIndex: index })
    }



    onPressIHavePracticed = (index) => {

        //console.log('pressed..................')

        let question = this.state.questions;
        //console.log(question)
        let content = question[this.state.complexitySelected]
        let contentCopy = JSON.parse(JSON.stringify(content[index]))
        if (contentCopy.practiced)
            return;

        contentCopy.practiced = true;
        this.updatePrepKitActivity(contentCopy._id)
        content[index] = contentCopy
        question[this.state.complexitySelected] = content
        //    content = this.removeItemFromArrary(content, index)
        //    content.push(contentCopy)
        // //console.log(question)
        // //console.log(question)

        //content[index] = contentCopy
        this.setState({ question })

    }

    removeItemFromArrary = (arr, index) => {
        arr.splice(index, 1);
        return arr;
    }

    updatePrepKitActivity = (contentId) => {

        let reqPayload = {
            chapterKey: this.state.selectedChapter.key,
            complexity: this.state.complexitySelected,
            practiceType: "CHAPTERS",
            contentId
        }
        PracticeQuestionsApi.prepkitPracticeActivity(reqPayload).then((res) => {

            //console.log(res)

            if (res && res.data && res.data.allQuestionsPracticed) {
                let remainingTime = this.getRemainingTime()
                //console.log('remainingTime....', remainingTime)

                this.setState({ showTakeChallengePopup: true })

                // if (this.userInfo.isSubscribed) {
                //     this.setState({ showTakeChallengePopup: true })
                // } else if (remainingTime >= (this.takeChallengePopupStaticText && this.takeChallengePopupStaticText.minAppTime ? this.takeChallengePopupStaticText.minAppTime : 15)) {
                //     this.setState({ showTakeChallengePopup: true })
                // }
            }
            // moengage.logCustomEvent('Practice', { 'User Id': this.userInfo.userId, Chapter: this.meta.title, 'Question Category': this.state.complexitySelected, 'Question Attempted': 'Yes' })
            FirebaseEvent('Practice', { 'User Id': this.userInfo.userId, Chapter: this.state.selectedChapter.title, 'Question Category': this.state.complexitySelected, 'Question Attempted': 'Yes', "Practice From": "Chapter Practice" })
            // Facebook.logCustomEvent('Practice', { 'User Id': this.userInfo.userId, Chapter: this.meta.title, 'Question Category': this.state.complexitySelected, 'Question Attempted': 'Yes' })
            // BranchIO.logCustomEvent('Practice', { 'User Id': this.userInfo.userId, Chapter: this.meta.title, 'Question Category': this.state.complexitySelected, 'Question Attempted': 'Yes' })

        }).catch(err => {
            //console.log(err)
        })

    }

    getRemainingTime = () => {
        if (this.landingScreenInstance) {
            this.remainingTime = this.landingScreenInstance.getRemainingAppTime()
            return this.remainingTime ? Math.ceil(this.remainingTime / 60) : 0
        }
        return 0
    }

    onClickBookMark = (contentObj, index) => {


        let payload = {
            chapterKey: this.state.selectedChapter.key,
            contents: contentObj,
            isPracticeQuestion: true
        }

        PracticeQuestionsApi.createBookMark(payload).then((res) => {


            let question = this.state.questions;

            let content = question[this.state.complexitySelected]

            content[index]["isBookMarked"] = true;
            content[index]["bookMarkId"] = res.bookMarkId;

            question[this.state.complexitySelected] = content;

            this.setState({ questions: question })
            // moengage.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.meta.title, Type: 'Practice Questions' })
            FirebaseEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.state.selectedChapter.title, Type: 'Practice Questions' })
            // Facebook.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.meta.title, Type: 'Practice Questions' })
            // BranchIO.logCustomEvent('Bookmark', { 'User Id': this.userInfo.userId, Chapter: this.meta.title, Type: 'Practice Questions' })


        }).catch(err => {
            //console.log(err)
        });

    }

    onClickBookMarkActive = (id, index) => {

        let payload = { _id: id }

        PracticeQuestionsApi.deleteBookMark(payload).then((res) => {

            let question = this.state.questions;

            let content = question[this.state.complexitySelected]

            content[index]["isBookMarked"] = false;
            content[index]["bookMarkId"] = "";

            question[this.state.complexitySelected] = content;

            this.setState({ questions: question })

        }).catch(err => {
            //console.log(err)
        });

    }


    showMCQQuestionSetList() {
        this.setState({ showMCQQuestionSetList: true })
    }

    hideMCQQuestionSetList() {
        this.setState({ showMCQQuestionSetList: false })
    }

    showSTRQuestionSetList() {
        this.setState({ showSTRQuestionSetList: true })
    }

    hideSTRQuestionSetList() {
        this.setState({ showSTRQuestionSetList: false })
    }


    showCaseStudyQuestionSetList() {
        this.setState({ showCaseStudyQuestionSetList: true })
    }

    hideCaseStudyQuestionSetList() {
        this.setState({ showCaseStudyQuestionSetList: false })
    }





    takeChallengePopup() {
        return (
            <Modal className="cw-custom-modal-center" isOpen={true} size={'lg'}  >
                <div className="pt-4 my-auto text-right">

                    <img className="challenge-header" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_HEADER, this.state.categoryThemeId)}></img>
                    <img className='close-icon cursor-pointer' src={getImageSource(ImageConstants.CROSS)} onClick={() => this.hideChallengePopup()} />
                </div>
                <ModalBody>
                    <div className="pratice-container">
                        <div className="text-center name-header">{CommonUtils.simpleTemplateEngine(this.takeChallengePopupStaticText.title, { name: this.userInfo.name, chapter: this.state.selectedChapter.title })}</div>
                        <div className="text-center mt-2"> <img className="image-width" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_MALE_IMAGE, this.state.categoryThemeId)}></img></div>
                        <div className="text-center challege-yourself">{this.takeChallengePopupStaticText.subTitle}</div>
                        <div className="d-flex align-items-center justify-content-center mt-2">
                            <div className="take-challenge  text-center align-self-center pt-3" style={{ backgroundColor: this.theme['themeColor'] }}>
                                {this.takeChallengePopupStaticText.buttonTitle ? this.takeChallengePopupStaticText.buttonTitle : 'OK, GOT IT'}
                            </div></div>
                        <div className="position">
                            <img className="challenge-footer" src={getThemeImageSource(ImageConstants.TAKE_CHALLENGE_POPUP_FOOTER, this.state.categoryThemeId)}></img>
                        </div>
                    </div>
                </ModalBody>


            </Modal>
        )
    }


    getPracticeList() {
        return (
            <div>
                <div className="mcq-activity-card mt-4">
                    {/* <img className="case-study-bg" src={getImageSource(ImageConstants.ACTIVITY_BG)} /> */}
                    <div className="d-flex justify-content-between align-items-center cursor-pointer p-3" onClick={() => this.showMCQQuestionSetList()}>
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                <img className="book-with-apple" src={getImageSource(ImageConstants.MCQ_ICON)} />
                            </div>
                            <div className="heading">{`Multiple Choice Questions (MCQ’s)`} </div>
                        </div>
                        <div>
                            <img className="arrow-size-for-case-study" src={getImageSource(this.state.showChapterActivityList ? ImageConstants.RIGHT_ARROW : ImageConstants.RIGHT_ARROW)} />
                        </div>
                    </div>
                </div>
                <div className="mcq-activity-card mt-4" style={{ background: '#FFF4C7' }}>
                    {/* <img className="case-study-bg" src={getImageSource(ImageConstants.ACTIVITY_BG)} /> */}
                    <div className="d-flex justify-content-between align-items-center cursor-pointer p-3" onClick={() => this.showSTRQuestionSetList()}>
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                <img className="book-with-apple" src={getImageSource(ImageConstants.SRQ_ICON)} />
                            </div>
                            <div className="heading">{`Structured Response Questions`} </div>
                        </div>
                        <div>
                            <img className="arrow-size-for-case-study" src={getImageSource(this.state.showChapterActivityList ? ImageConstants.RIGHT_ARROW : ImageConstants.RIGHT_ARROW)} />
                        </div>
                    </div>
                </div>
                <div className="mcq-activity-card mt-4" style={{ background: '#DCFCFF' }}>
                    {/* <img className="case-study-bg" src={getImageSource(ImageConstants.ACTIVITY_BG)} /> */}
                    <div className="d-flex justify-content-between align-items-center cursor-pointer p-3" onClick={() => this.showCaseStudyQuestionSetList()}>
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                <img className="book-with-apple" src={getImageSource(ImageConstants.CASE_STUDY_ICON)} />
                            </div>
                            <div className="heading">{`Case Study Based Questions`} </div>
                        </div>
                        <div>
                            <img className="arrow-size-for-case-study" src={getImageSource(this.state.showChapterActivityList ? ImageConstants.RIGHT_ARROW : ImageConstants.RIGHT_ARROW)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    getPracticeStatus = (item) => {
        console.log('>>>>::', item)
        let payload = {
            practiceId: item.practiceId
        }
        // this.toggleFullScreenLoading(true)

        PracticeApi.getPracticeStatus(payload).then((res) => {
            let concepts = []
            console.log(res)

            if (item.conceptsCovered && item.conceptsCovered.length) {
                for (let i = 0; i < item.conceptsCovered.length; i++) {
                    concepts.push(item.conceptsCovered[i].title)
                }
            }


            if (res && res.notAttepmted && res.notAttepmted.length) {

                // console.log(res)


                // FireBaseEvent(Constants.SCHOOL_ASSIGNMENT, { 'User Id': this.userInfo.userId, 'School': this.userInfo.schoolName, Techer: item.createdBy, 'Class': this.userInfo.class, 'Assignment Type': item.type.replace('_', " "), 'Chapter Key': item.chapterKey ? item.chapterKey : '', 'Chapter': item.chapterTitle, 'Concepts': concepts.toString(), 'Status': this.getHomeworkStatusText(item.status), 'Learning Started': "No" })
                // this.layoutShared.toggleSidebar(true, this.props.history.location.pathname)
                this.setState({
                    showPracticeExperience: true, showMCQQuestionSetList: false, showSTRQuestionSetList: false,
                    params: {
                        practiceId: item.practiceId,
                        nodeId: res.notAttepmted[0].id,
                        nodes: res.notAttepmted,
                        title: item.chapterTitle,
                        concepts: res.concepts,
                        categoryThemeId: 1,
                        conceptsCovered: concepts,
                        practice: item,
                        chapterImageUrl: item.chapterImageUrl
                    }
                })

                // this.props.navigation.navigate("StandardHomeworkExperience", {
                //     params: {
                //         homeworkId: item.homeworkId,
                //         nodeId: res.notAttepmted[0].id,
                //         nodes: res.notAttepmted,
                //         title: item.chapterTitle,
                //         concepts: res.concepts,
                //         categoryThemeId: item.categoryId,
                //         conceptsCovered: concepts,
                //         homework: item
                //     }
                // })

            } else {
                // this.setState({ showHomeworkConceptListScreen: true, homeworkMetaInfo: { details: res, conceptsCovered: concepts, homework: item, homeworkId: item.homeworkId, title: item.chapterTitle, categoryThemeId: 1, chapterImageUrl: item.chapterImageUrl } })
            }


        }).catch(err => {
            if (err) {
                console.log(err)
                return
            }
        })
    }


    showDescriptionQuestions() {
        this.hideSTRQuestionSetList()
        this.setState({ showDescriptionQuestions: true })
    }

    renderPracticeQuestions() {
        return (
            <div>
                {
                    this.state.fullScreenLoading ?
                        <>
                            <div className="choose-complexity" >Choose Complexity</div>
                            {this.state.questions[this.state.complexitySelected] && this.state.questions[this.state.complexitySelected].length ?
                                <div>
                                    <div className="row mt-4 row-max-width justify-content-center align-content-center">
                                        {this.state.complexities.length ? this.state.complexities.map((complexity, index) => (
                                            <>
                                                <div className={`col text-center complexity pt-2  
                                                    
                                                    ${this.state.complexitySelected === 'Low' ? "border-top-left-radius" : ''}
                                                    ${this.state.complexitySelected === 'HOTS' ? "border-top-right-radius" : ''}`}
                                                    onClick={() => this.selectComplexity(complexity)}
                                                    style={{
                                                        backgroundColor: `${this.state.complexitySelected === complexity ? this.theme[`themeColor`] : ''}`
                                                        , color: `${this.state.complexitySelected === complexity ? '#FFFFFF' : ''}`
                                                    }}

                                                >
                                                    {complexity}
                                                </div>
                                                {this.state.complexities.length - 1 == index ? "" : <span className="verticleLine"></span>}
                                            </>
                                        )) : ''}
                                    </div>

                                    <div className="mt-4">
                                        {this.state.questions[this.state.complexitySelected].map((content, index) => (
                                            // <div>{content.question.title}</div>
                                            <div className={`qustion-wrapper `}>

                                                <PracticeQuestions content={content} index={index} getSelectedIndex={index => this.getSelectedIndex(index)}
                                                    categoryThemeId={this.state.categoryThemeId} delegate={this} showInactiveBookmark={content.isBookMarked ? false : true} showBookMark={content.isBookMarked ? true : false} />
                                                <div className="row mt-3 practice-wrapper align-items-center" >
                                                    <div className={`col-1 pr-0 box-wrapper`} >
                                                        <div className="react-box cursor-pointer"
                                                            style={{ backgroundColor: `${content.practiced ? this.theme['themeColor'] : ''}` }}
                                                            onClick={() => this.onPressIHavePracticed(index)}>
                                                            <img className="react-image-width" src={getImageSource(ImageConstants.WHITE_TICK)} />
                                                        </div>

                                                    </div>
                                                    <div className="col pl-0">
                                                        <span className="practiced-question">I have practiced this question</span>
                                                    </div>
                                                </div>

                                                <img className="question-divider-image-width mt-4" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_DIVIDER, this.state.categoryThemeId)}></img>
                                            </div>


                                        ))}
                                    </div>
                                </div> : <div>No Practice Questions Found</div>
                            }

                            {this.state.showTakeChallengePopup && this.takeChallengePopup()}

                        </>
                        :
                        <></>
                }
            </div>
        )
    }




    render() {


        if (this.state.showMCQQuestionSetList) {
            return <MCQQuestionSetList
                selectedChapter={this.state.selectedChapter}
                chapterTitle={this.state.selectedChapter.title}
                back={() => this.hideMCQQuestionSetList()}
                goToHomeScreen={() => this.goToHomeScreen()}
                goToPracticeQuestionsChapterList={() => this.goToPracticeQuestionsChapterList()}
                delegate={this} />
        }

        if (this.state.showSTRQuestionSetList) {
            return <STRQuestionSetList
                selectedChapter={this.state.selectedChapter}
                chapterTitle={this.state.selectedChapter.title}
                back={() => this.hideSTRQuestionSetList()}
                goToHomeScreen={() => this.goToHomeScreen()}
                goToPracticeQuestionsChapterList={() => this.goToPracticeQuestionsChapterList()}
                delegate={this} />
        }

        if (this.state.showPracticeExperience) {
            return <PracticeExperience params={this.state.params} delegate={this} />
        }

        if (this.state.showCaseStudyQuestionSetList) {
            return <CaseStudyQuestionSetList
                selectedChapter={this.state.selectedChapter}
                chapterTitle={this.state.selectedChapter.title}
                back={() => this.hideCaseStudyQuestionSetList()}
                goToHomeScreen={() => this.goToHomeScreen()}
                goToPracticeQuestionsChapterList={() => this.goToPracticeQuestionsChapterList()}
                delegate={this}
                onSelectChapter={(chapter) => this.selectedChapter(chapter)}
            />
        }


        // if (this.state.showPracticeCompletionScreen) {
        //     return <PracticeCompletionScreen data={{
        //         "concepts": [
        //             {
        //                 "title": "The Fundamental Theorem of Arithmetic",
        //                 "key": "17200705377333305"
        //             },
        //             {
        //                 "title": "Revisting Irrational Numbers",
        //                 "key": "17200705500931233"
        //             }
        //         ],
        //         "chapterTitle": "Real Numbers",
        //         "practiceId": "17200704731054394_668e24ddb117d636eb2de9c9_M1",
        //         "cleared": [],
        //         "notCleared": [
        //             {
        //                 "id": "66863190c5b4fe2e33842934",
        //                 "title": "The Fundamental Theorem of Arithmetic"
        //             },
        //             {
        //                 "id": "6686319ec5b4fe2e3384293d",
        //                 "title": "Revisting Irrational Numbers"
        //             }
        //         ],
        //         "progress": {
        //             "value": 0,
        //             "type": "%"
        //         }
        //     }} practice={{
        //         "_id": "668fb9f2609c5c9545bea3c9",
        //         "userId": "668c8d99d77964a6ba122ce7",
        //         "transferKey": "668c8d99d77964a6ba122ce7_1720487325828",
        //         "schoolId": "SCX-121",
        //         "board": "CBSE",
        //         "class": "Class 10",
        //         "masterDataId": "668549eb68b8f80eb2ba178c",
        //         "workflowId": "66863328c5b4fe2e33842993",
        //         "practiceId": "17200704731054394_668e24ddb117d636eb2de9c9_M1",
        //         "chapterKey": "17200704731054394",
        //         "startTime": "2024-07-12T06:13:03.860Z",
        //         "finishTime": "2024-07-12T12:25:32.384Z",
        //         "section": null,
        //         "sectionId": "",
        //         "type": "MCQ",
        //         "status": "START",
        //         "approximateTime": 25,
        //         "approximateQuestions": 10,
        //         "countingwellCreatedTimestamp": "2024-07-11T10:54:42.313Z",
        //         "countingwellLastUpdateTimestamp": "2024-07-12T12:25:32.385Z",
        //         "correct": 1,
        //         "inCorrect": 9,
        //         "marks": 10,
        //         "chapterTitle": "Real Numbers",
        //         "categoryId": ""
        //     }} />
        // }

        this.theme = SharedThemeCategory.getThemeCategory(this.state.categoryThemeId)

        //console.log(this.state.questions, this.state.complexitySelected)

        return (
            <div className="practice">
                {
                    !this.state.selectedChapter ?
                        <div className="chapter-list">
                            <div className="text-right">
                                <img className="image-size" src={getImageSource(ImageConstants.HEADER_BG_IMAGE)}></img>
                            </div>
                            <div className="header-title-margin-top">
                                {this.renderBreadcrumbForChapterList()}
                                <div className="d-flex justify-content-between">
                                    <div className="title">Practice</div>
                                    <div className="text-right mr-5 ">
                                        <img className="search-icon-width"
                                            src={getImageSource(ImageConstants.CHAPTER_SEARCH_ICON)}
                                            onClick={() => this.getSearchedChapterList()}></img>
                                        <input
                                            className="input-box"
                                            type="text"
                                            value={this.state.searchText}
                                            onChange={e => this.setSearchText(e, 'searchText')}
                                            placeholder='Search Chapter'>
                                        </input>
                                    </div>
                                </div>
                                <div>
                                    <img className="divider-image-width" src={getImageSource(ImageConstants.TITLE_DIVIDER)}></img>
                                </div>
                            </div>
                            {!this.state.reload ? <ChapterList type={'PRACTICE'}
                                searchedChapterName={this.state.searchText} onSelectChapter={(chapter) => this.selectedChapter(chapter)} /> : ''}
                        </div>
                        :
                        <div className="practice-question-list">
                            <div className="text-right">
                                <img className="image-size" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER, this.state.categoryThemeId)}></img>
                            </div>
                            <div className="header-for-practice-question">
                                {this.renderBreadcrumbForPracticeQuestions()}
                                <div className="d-flex align-items-center">
                                    <img className="chapter-icon mr-1" src={this.state.selectedChapter.chapterImage ? this.state.selectedChapter.chapterImage : getImageSource(ImageConstants.CHAPTER_ICN)} />
                                    <span className="chapter-title">{this.state.selectedChapter.title}</span>
                                </div>

                                {
                                    this.state.showDescriptionQuestions ? <div className="sub-header-text mt-2">Practice Questions</div> : <></>
                                }
                            </div>
                            <div>
                                <img className="divider-image-width" src={getThemeImageSource(ImageConstants.PRACTICE_QUESTION_HEADER_DIVIDER, this.state.categoryThemeId)}></img>
                            </div>

                            {
                                !this.state.showDescriptionQuestions ?
                                    this.getPracticeList()
                                    :
                                    this.renderPracticeQuestions()
                            }
                        </div>
                }
            </div>
        )
    }


}

export default PracticeMoreQuestions;